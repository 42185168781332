.privacy-page {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
        min-height: calc(100% - 296px);
        padding-bottom: 2rem;
        align-items: flex-start;
    }
    .privacy-img {
        .backarrowdiv {
            max-width: 32px;
            margin: 0;
        }
        p {
            display: none;
        }
        ion-img {
            max-width: 345px;
            margin: 0 auto;
            display: block;
            margin-bottom: 10px;
            @media (max-width: 991px) {
                max-width: 224px;
                margin-bottom: 0;
            }
        }
        @media (max-width: 767px) {
            margin-bottom: 1rem;
        }
    }
    .privacy-content {
        max-width: 330px;
        --ion-color-base: #FE5000 !important;
        --background-activated: #FE5000 !important;
        --background-focused: #FE5000 !important;
        @media (max-width: 992px) {
            margin-left: auto;
            margin-right: auto;
        }
        .sc-ion-label-md-h {
            --ion-color-base: #FE5000 !important;
            --background-activated: #FE5000 !important;
            --background-focused: #FE5000 !important;
            color:  #FE5000 !important;;
        }

        ion-label {
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 38px;
            color: var(--greenlight);
            margin: 0px auto;
            display: block;
            text-align: center;
            @media (max-width: 767px) {
                font-size: 30px;
                line-height: 35px;
                margin-bottom: 0.5rem;
            }
            span {
                color: var(--bluecolor);
            }
            @media (max-width: 767px) {
                max-width: unset;
                text-align: center;
            }
        }
        ion-text {
            font-weight: 400;
            font-size: 17px;
            line-height: 158.69%;
            color: var(--darkgray);

            margin: 0px auto 20px;
            max-width: 370px;
            text-align: center;

            @media (max-width: 767px) {
                max-width: unset;
                text-align: center;
                // margin-bottom: 1.5rem;
            }
            a {
                text-decoration: none;
                color: #FE5000;
            }
            strong {
                color: var(--dark-green);
                font-weight: 700;
            }
            span {
                color: var(--greenlight);
                font-weight: 700;
            }
        }
        .welcome-buttons {
            ion-row {
                @media (max-width: 767px) {
                    justify-content: center;
                }
            }
            ion-button {
                margin-right: 6px;
            }
        }
        .orange-btn {
            background-color: #FE5000;
            --ion-color-base: #FE5000 !important;
            --background-activated: #FE5000 !important;
            --background-focused: #FE5000 !important;
            ion-icon {
                min-width: 22px;
                max-width: 22px;
                margin-left: 10px;
            }
        }
    }
    .position_cls {
        cursor: pointer;
        @media (min-width: 768px) {
            position: absolute;
            top: 0;
        }
    }
}

.termscondition {
    min-height: calc(100vh - 162px);
    .backarrowdiv {
        max-width: 32px;
        cursor: pointer;
    }
    .mainText {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #FE5000;
        margin-bottom: 1rem;
        @media only screen and (max-width: 991px) {
            font-weight: 700;
            font-size: 24px;
            line-height: normal;
            margin-bottom: 1rem;
            margin-top: 0.5rem;
        }
        @media only screen and (max-width: 375px) {
            font-size: 26px;
        }

        .iconleft {
            max-width: 25px;
            min-width: 25px;
        }
    }
    .middle-content {
        max-height: calc(100vh - 348px);

        overflow-y: auto;
        position: absolute;
        width: 100%;
        left: 0;
        top: 62px;
        padding-right: 1rem;
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        @media only screen and (max-width: 1100px) {
            max-height: calc(100vh - 372px);
        }

        @media only screen and (max-width: 991px) {
            top: auto;
            padding-right: 0;
            max-height: 100%;
            height: 100%;
            position: relative;
            overflow: visible;
        }

        .innterPolicyContext {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.015em;
            color: rgba(0, 0, 0, 0.7);
            @media only screen and (max-width: 991px) {
                display: block;
                padding-bottom: 9rem;
            }
            span {
                font-weight: 700;
                color: var(--dark-green);
                strong {
                    color: var(--greenlight);
                }
            }

            p {
                margin-bottom: 1rem;
                font-size: 16px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.7);
            }
            a {
                color: var(--greenlight);
            }
        }
    }

    .last-secbutton {
        @media only screen and (max-width: 991px) {
            box-shadow: 20px -10px 16px rgba(41, 38, 93, 0.2);
            position: fixed;
            bottom: 0;
            left: 0;
            background: #fff;
            padding: 1rem 1rem;
        }
    }

    .bottom-button {
        max-width: 300px;
        margin-left: auto;
        margin-top: 50%;
        display: block;
        @media only screen and (max-width: 1300px) {
            margin-top: 30%;
        }
        @media only screen and (max-width: 991px) {
            margin: 0 auto;
            width: 100%;

            right: 0;
        }
        @media only screen and (max-width: 576px) {
            max-width: 95%;
        }
        .orange-btn {
            background-color: #FE5000;
            --ion-color-base: #FE5000 !important;
            --background-activated: #FE5000 !important;
            --background-focused: #FE5000 !important;
        }
    }
    .termtext {
        // background: #f3f5f0;
        border-radius: 20px;
        padding: 2.5rem 1.5rem 2.5rem 2.5rem;
        @media (max-width: 991px) {
            background-color: #fff;
            padding: 0;
        }
        .middle-content {
            position: unset;
            // height: 500px !important;
            overflow-y: auto;
            // max-height: 500px;
            display: block;
            margin-top: 10px;
        }
    }
    .bottom-button {
        margin-left: auto;
        margin-top: unset;
        margin-right: auto;
    }
}


.login-footer{
    background: #F6F1EE;
}
.button-color{
    background: transparent;
    color:var(--ion-color-base);
    width: 100%;
    // height: 100%;
    line-height: 1;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    text-decoration: inherit;
    text-indent: inherit;
    text-overflow: inherit;
    text-transform: inherit;
    text-align: inherit;
    white-space: inherit;
    min-height: 45px;
    min-width: 190px;
}