

.login-header{
  background: #F6F1EE;
}



.login-content {
  @media only screen and (max-width: 767px) {
    // --background: linear-gradient(112.07deg, #33c3f0 -21.86%, #7d54b9 76.6%);
  }
  .mobiletransparat {
    background: rgba(255, 255, 255, 0.3);
    // content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .login-header {
    .logo {
      margin-right: 20px;
    }
  }
  .middlecontent {
    // background: url("./assets/login-background.png") center center no-repeat;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 162px);
    overflow-y: auto;
    padding:2rem 0;
   
    @media only screen and (min-width: 1400px) {
      align-items: center;
      display: flex;
    }
    .container{
      max-width: 977px!important;
    }
    @media only screen and (max-width: 767px) {
      background: none;
      position: relative;
      height: calc(100vh - 100px);padding: 2rem 0;
    }
    form {
      display: block;    max-width: 100%; margin: 0 auto;
    }
    .loginimg {
      position: absolute;
      height: calc(100vh - 173px);
      top: 0;
      bottom: 0;
      /* left: 0; */
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .login-footer {
    // background-color: #ffffff;
    // background: #ffffff;
    background-color: #F6F1EE;
    @media only screen and (max-width: 767px) {
      background-color: transparent;
      position: relative;
    }
    .footer-label {
      font-weight: 700;
      font-size: 12px;
      line-height: 126.69%;

      letter-spacing: 0.035em;
      color: #999998;
      mix-blend-mode: multiply;
      font-family: 'Roboto', sans-serif;
      @media only screen and (max-width: 767px) {
        // color: #fff;
        text-transform: uppercase;
      }
    }
    .innerfooter {
      @media only screen and (max-width: 767px) {
        justify-content: space-between;
        padding: 0 1rem;
      }
    }
  }
  .leftcontent {
    max-width: 400px;
    @media only screen and (max-width: 767px) {
    max-width: 100%;
    }
    .mobilelogo {
      .header-logo-login-mobile {
        max-width: 160px;
        margin: 0 auto 1rem;
      }
    }
    .headingotop {
      font-weight: 800;
      font-size: 40px;
      line-height: 40px;
      // letter-spacing: 0.005em;
      // color: #ffffff;
      display: block;
      clear: both;
      // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 20px;

      background: #FFAD00;
background: -webkit-linear-gradient(to right, #FFAD00 0%, #FE5000 100%);
background: -moz-linear-gradient(to right, #FFAD00 0%, #FE5000 100%);
background: linear-gradient(to right, #FFAD00 0%, #FE5000 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;

      
      @media only screen and (max-width: 767px) {
        text-align: center;
        font-size: 32px;
        text-shadow: none;
        font-weight: 700;
      }
    }
    .subtitle {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      display: block;
      clear: both;
      color: #392A24;
      margin-bottom: 20px;
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    .description {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      display: block;
      clear: both;
      color: #392A24;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
        // color: #fff;
        text-align: center;
        padding: 0 1rem;
      }
    }
  }
  .rightcontent {
    padding-top: 2rem;
    @media only screen and (max-width: 767px) {
      padding: 1rem 1.5rem 0 1.5rem;
    }
    @media only screen and (min-width: 992px) {
      padding-top: 1.5rem;
    }
    .middleform {
      background-color: transparent !important;
      .listinput {
        margin-bottom: 1.5rem;
        background-color: transparent;
        display: block;
        .login-label {
          font-size: 14px;
          line-height: normal;
          color: #392A24;
          display: block;
          margin-bottom: 10px;
          padding-left: 12px;
          font-weight: 700;
          font-family: 'Roboto', sans-serif;
        }
        .login-input {
          background: #ffffff;
          // border: 1px solid #087aaf;
          // border: 1px solid transparent;
          // border-image: linear-gradient(to right, #FFAD00, #FE5000);


          background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(to right, #FFAD00, #FE5000) border-box;
    
          border: 1px solid transparent;
         


          // border-image-slice: 1;
          box-sizing: border-box;
         border-radius: 10px !important;
          -webkit-border-radius: 10px;
          display: block;
          padding: 0.9rem !important;
          width: 100%;
          height: 45px;
          outline: none;
          // font-family: Open Sans;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
         
          line-height: 20px;
          align-items: center;
          color: #5f5f5f !important;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
          color-scheme: none;
        }
        // input[type="date"]::-webkit-inner-spin-button,
        // input[type="date"]::-webkit-calendar-picker-indicator {
        //   display: none;
        //   -webkit-appearance: none;
        // }
        .custom-error-label {
          color: red;
          font-size: 13px;
        }
      }
      .login-btn {
        margin-top: 2rem !important;

        .round {
          // border: 1px solid white !important;
          // --border-width: 1 !important;
          // --border-color: red!important;

          // --border-style: solid !important;
          border-radius: 10px !important;
        }
        --background: #FFAD00;
        --ion-color-base: -webkit-linear-gradient(to right, #FFAD00 0%, #FE5000 100%) !important;
        --background-activated: -webkit-linear-gradient(to right, #FFAD00 0%, #FE5000 100%) !important;
        --background-focused: -webkit-linear-gradient(to right, #FFAD00 0%, #FE5000 100%) !important;

        --border-width: 0px !important;
        // --border-color: white !important;
        // --border-style: solid !important;
        --color: white;
        // --background: #33c3f0;
        --box-shadow: none;
        margin-top: 14px;
        text-transform: none;
        --border-radius: 10px !important;
        width: 100%;
        // height: 40px;
        font-size: 16px;
        font-weight: 400;
        height: 50px;
       letter-spacing: 0;
        --background: -webkit-linear-gradient(to right, #FFAD00 0%, #FE5000 100%);
        --background: -moz-linear-gradient(to right, #FFAD00 0%, #FE5000 100%);
        --background: linear-gradient(to right, #FFAD00 0%, #FE5000 100%);


        &:hover {
          //   --background:  #33c3f0;
          //   --ion-color-base:  #33c3f0 !important;
          //   --background-activated:  #33c3f0 !important;
          //   --background-focused:  #33c3f0 !important;
          //   --border-color: transparent !important;
          //   --color: white;
        }
        // background: #33c3f0;
        // --border: 1px solid #ffffff;
        // box-sizing: border-box;
        // --border-radius:10px !important;

        //
        // padding: 20px 0px;
        // font-weight: 600;
        // font-size: 16px;
        // --color: #ffffff;
        // width: 100%;
        // --height: auto;
        // --background:#33c3f0 !important;
      }
    }
  }
}
