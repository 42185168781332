.incomplete-call-main {
    max-width: 400px;
    margin: 0 auto;
    border-radius: 9px;
    color: #135B9E;
    text-align: center;

    .heading-text {
        font-weight: 900;
        font-size: 18px;
        line-height: 21px;
        margin-top: 50px;
    }

    .small-text {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        margin-top: 10px;
    }

    .rejoin-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        color: black;
    }

    .img-div {
        background: #dde7f1;
        padding: 60px 25px 25px;
        position: relative;
        max-width: 400px;
        margin: 0 auto;
        border-radius: 9px;
        margin-top: 120px;
    }

    .img-div .box-img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -100px;
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }

    .img-div .box-img img {
        width: 100%;
    }

    .cancle-btn {
        color: #C06666;
        margin-top: 30px;
        cursor: pointer;
    }

    .voltar-btn {
        color: #fff;
        background-color: #135B9E;
        border-color: #135B9E;
        border-radius: 30px;
        padding: 10px 60px;
        margin-top: 25px;
    }
}

.waitingFinal{
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    min-height: calc(100vh - 172px) !important;
    p {
        font-weight: 500;
    font-size: 22px;
    color: #423C3D;
    }
    img{
        height: 70px;
    }
}

