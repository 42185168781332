.settingsTerms {
    .head-dls {
        font-size: 26px;
        line-height: normal;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
        font-weight: 600;
        color: var(--darkblue);
        margin-bottom: 1rem;
        display: block;
    }
    .middle-content {
        .innterPolicyContext {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.015em;
            color: rgba(0, 0, 0, 0.7);
            display: block;
            padding-bottom: 9rem;
            p {
                margin-bottom: 1rem;
                font-size: 16px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.7);
                a {
                    color: var(--lightblue);
                }
            }
        }
    }
    .bottom-btns {
        box-shadow: 20px -10px 16px rgba(41, 38, 93, 0.2);
        position: fixed;
        bottom: 0;
        left: 0;
        background: #fff;
        padding: 1rem 1rem;
        .bottom-button {
            max-width: 320px;
            margin: 0 auto;
        }
    }

    .revok-text {
        --color: #F9423A !important;
        --border-color: #F9423A !important;
    }

    &.none-margin{
        .react-pdf__Document{
            margin-bottom: 0;
        }
    }

}

