.footerdiv {
  background-color: #F6F1EE;
  .socialicons {
    ion-img {
      margin: 0 15px;
      cursor: pointer;
    }
    .logoans {
      margin-right: 15px;
    }
  }
  .textright{
    color: #999998;
    margin-left: 1rem;
    font-size: 12px;
     font-family: 'Roboto', sans-serif;
     font-weight: 500;
  }
  .logotext{
    color: #999998;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;

  }
}
