.support-container {
  overflow: auto;
  height: calc(100vh - 183px);
  background: #ffffff;
  padding: 1.5em 0;
  @media (max-width: 767px) {
    height: auto;
  }
}
.counter-div {
  display: flex;
  justify-content: center;
}

.counter-text {
  color: #696b73;
  font-size: 20px;
}

.awaiting-container {
  .small-title-div {
    margin-bottom: 40px;
    .content-box-sm {
      .symptoms-sub-title {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #392a24;
      }
      .symptoms-sub-text {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        color: #fe5000;max-width: 283px;
        @media (max-width: 767px) {
color: #392A24; max-width: 100%;
        }
      }
    }
    .consultas-text {
      height: 20px;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 20px;
      color: #231f20;
      margin-bottom: 5px;
    }
    .seu-text {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 40px;
      color: #ff8574;
    }
    .small-title {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #f7941d;
      margin-top: 35px;
      margin-bottom: 6px;
    }
    .small-title-inner {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #000000;
    }
  }
.sub-text{
  &.sub-text-green{
    font-weight: bold;
    @media (max-width: 767px) {
      color: #FE5000; 
    }
  }
  font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
       letter-spacing: -0.01em;
        color: #392A24;
        @media (max-width: 767px) {
          color: #000; 
        }
       
}


  .content-progressbar {
    // width: auto;
    // height: 160px;
    // position: absolute;
    // margin-left: 120px;
  }
  .call-cancel-btn {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #C06666;
    cursor: pointer;
    border: 1px solid #C06666;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: center;
    height: 50px;
    line-height: 47px;
    max-width: 320px;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
  .call-btn-view {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 250px);
  }
  .call-btn {
    --ion-color-base: #f7941d !important;
    --background-activated: #f7941d !important;
    --background-focused: #f7941d !important;
    --border-color: transparent !important;
    --border-width: 0;
    --color: white;
    --background: #f7941d;
    --box-shadow: none;
    --border-radius: 37px;
    height: 44px;
    width: 250px;
    text-transform: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
}
.sub-top-remove {
  top: 0% !important;
  left: 5%;
  max-width: 700px !important;
}

.mainwrapper {
  display: flex;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 200px;

    .update-profile-view {
      padding-left: 16px;
      padding-bottom: 30px;
      @media screen and (max-width: 575px) {
        padding-bottom: 50px;
      }
      @media screen and (max-width: 414px) {
        padding-bottom: 180px;
      }
    }
  }
  .rightcontent {
    // flex: 2;
    // padding-left: 170px;
    padding-top: 80px;
    padding-bottom: 200px;
    @media screen and (max-width: 767px) {
      // padding-left: 44px;
      padding-top: 50px;

      .awaiting-container {
        margin-bottom: 150px;
        .small-title-div {
          margin-bottom: 62px;
          .consultas-text {
            height: 20px;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 20px;
            color: #231f20;
            margin-bottom: 5px;
          }
          .seu-text {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 40px;
            color: #ff8574;
          }
          .small-title-mobile {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 100%;
            letter-spacing: -0.01em;
            color: #f7941d;
            margin-top: 35px;
            margin-bottom: 6px;
          }
          .small-title-innner-mobile {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 100%;
            letter-spacing: -0.01em;
            color: #000000;
          }
        }
        .call-btn-view {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 250px);
        }
        .call-btn {
          --ion-color-base: #f7941d !important;
          --background-activated: #f7941d !important;
          --background-focused: #f7941d !important;
          --border-color: transparent !important;
          --border-width: 0;
          --color: white;
          --background: #f7941d;
          --box-shadow: none;
          --border-radius: 37px;
          height: 44px;
          width: 250px;
          text-transform: none;
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ffffff;
        }
        .content-progressbar {
          margin-left: 0px;
          position: absolute;
          /* top: 50%; */
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, 0%);
        }
        .call-cancel-btn {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 132%;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ce0017;

          display: flex;
          position: absolute;
          justify-content: center;
          margin-left: 0px;
          position: absolute;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, 0%);
        }
      }
    }
  }
}
.symptoms-patient-name.page-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #FE5000;
}
.back-header{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FE5000;
  position: relative;
  margin-bottom: 20px;
  .back-icon{
    position: absolute;
    left: 10px;
  }
}

.staff-video-off{
  background: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}