.custom-input {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  display: block;
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 0;

    .passwordIcon {
      position: absolute;
      right: 15px;
      z-index: 11;
      top: 10px;
      cursor: pointer;
      max-width: 18px;
      min-width: 18px;
    }

    .item-native {
      padding: 0;
    }
    ion-input,
    .custom-mask {
      border-radius: 12px;
      --padding-start: 14px !important;
      background-color: var(--lightgray);
      --border-radius: 12px;
      --padding-end: 14px !important;
      padding: 13px;
      min-height: 45px;
      border: none;
      // min-width: 300px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: var(--darkgray);
      input {
        min-height: 45px;
      }
      &::placeholder {
        color: #bfbfbf;
      }
      &:focus {
        outline: none;
      }
      &.login-input {
        --padding-start: 14px;
        --padding-end: 14px;
      }
    }
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: var(--textgray);
    margin-left: 14px;
    display: block;
    margin-top: 5px;
  }
  .validation-error-msg {
    color: var(--redcolor);
    font-size: 13px;
    margin-left: 15px;
  }
  .profileerror {
    margin-left: 5px !important;
  }
  .maskedInput-border {
    border: 1px solid #009DDB !important;
  }
}
.bottomlabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: var(--textgray);
  margin-left: 14px;
  display: block;
  margin-top: 5px;
}

// input::placeholder {
//   color: #009DDB !important;
// }
