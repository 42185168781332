.mobileheader {
  background-color: #FFFFFF;
  background: #FFFFFF;
  .logo {
  }
  .toptilte {
    color: #5f5f5f;
    font-weight: 600;
    font-size: 14px;
    padding-left: 12px;
    line-height: normal;
  }
  .seconddiv {
    background: #FE5000;
    .usrename {
      color: #fff;
      font-weight: 700;
      font-size: 15px;
      word-break: break-all;
    }
    .righticons {
      ion-img {
        margin-left: 0.5rem;
      }
    }
  }
}
.settingicon{
  img{
    object-fit: contain;
  }
  
}
