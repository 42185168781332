.pdf-view {
    text-align: center;
    justify-content: center;
    max-width: 613px;
    padding-left: 4rem;

    @media screen and (max-width: 767px) {
padding-left: 0;
margin: 0 auto;
    }
    .top-heading {
        font-weight: 700;
        font-size: 20px;
        color: var(--darkblue);
        margin-bottom: 1rem;
    }
    .orange-btn {
        margin-left: auto;
        margin-right: auto;
        ion-icon {
            margin-left: 8px;
        }
    }
    .react-pdf__Document {
        padding: 0 1rem;
        width: 100%;
        max-width: 550px;
        margin: 0 auto 2rem;

        @media screen and (max-width: 767px) {
margin-bottom: 11rem;
        }
        .react-pdf__Page {
            border: 1px solid #cfcfcf;
            border-radius: 2px;
            .react-pdf__Page__canvas {
                width: 100% !important;
                height: auto !important;
            }
            .react-pdf__Page__textContent {
                width: 100% !important;
                height: auto !important;
                display: none;
            }
        }
    }
    .nutritional-plan-layout-view {
        width: 100%;
        padding: 0 1rem;

        max-width: 550px;
        margin: 0 auto 0;
        .fixedheight {
            border: 1px solid #cfcfcf;
            border-radius: 2px;
        }
    }
}
