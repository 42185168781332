.home-comp {
  .hydrated {
    --keyboard-offset: "0px" !important;
  }
}

.homewrapper {
  overflow: auto;
  display: flex;
  height: calc(100vh - 183px);
  align-items: center;
  background-color: #ffffff;
  background: #ffffff;
  // @media screen and (min-width: 1024px) {
  // padding-left: 160px;
  // flex-direction: column;
  // flex-wrap: wrap;
  // }
  &.mainScreenImage {
    // background: url('./assets/images/home-frame.svg') center center no-repeat; background-size: cover;
    height: calc(100vh - 183px);
    overflow-x: hidden;
    @media (max-width: 1024px) {
      background: #fff;
      height: auto;
    }
    .sub-content-multi-speciality,
    .sub-content {
      background: none;
      max-width: 100%;
      padding: 0;
      .user-support-page {
        .symptoms-img {
          height: calc(100vh - 183px);
          object-fit: cover;
          display: block;
        }
        .rightpart {
          padding: 3rem 4rem;
          @media (max-width: 767px) {
            padding: 2rem;
          }
          .patient-name {
            color: #fe5000;
            font-size: 16px;
            font-weight: 700;
            font-family: Roboto;
            margin-bottom: 2rem;
          }
          .innermainbox {
            border: 1px solid #c7c8cc;
            box-sizing: border-box;
            border-radius: 12px;
            padding: 25px;
            max-width: 325px;
            margin: 0 auto;
            .listdiv-multi {
              max-width: 100%;
              width: 100%;
              @media (max-width: 767px) {
                margin-top: 0;
              }
            }
            .list-div-multispeciality,
            .listdiv-multi {
              width: 100% !important;
              margin-top: 0;
              position: relative;
              .small-heading {
                font-size: 24px;
                color: #231f20;
                font-weight: 400;
                letter-spacing: 0px;
                font-family: Inter;
                line-height: 32px;
                margin-bottom: 1.6rem;
                min-height: 88px;
                text-align: left;
                @media (max-width: 767px) {
                  min-height: auto;
                }
                b {
                  font-weight: 400;
                  color: #231f20;
                  font-family: Inter;
                }
              }
              .mainboxdropdown{
                position: absolute !important;
                width: 100%;
                left: 0;    z-index: 9;
                .custom-search-select{
                  padding: 0;     width: 93%;
                  @media (max-width: 767px) {
                    width: 96%;
                  }
                  .custom-search-select__value{
                    input{
                      padding: 6px 30px;
                    }
                  }
                  .custom-search-select__options{
                    padding: 0;
                    .custom-search-select__option{
                      padding: 12px 17px; color: #392A24; font-size: 16px;
                    font-weight: 400;font-family: Roboto;
                    }
                  }
                }
                .search-icon{
                  @media (max-width: 992px) {
                    left:12px; 
                  }
                 @media (max-width: 767px) {
                    left: 15px;
                  }
                  @media (max-width: 480px) {
                    left: 6px;
                  }
                }
              }
            }
            .dropDownbtn {
              width: 100%;
              &.openDrop {
                .down-arrow {
                  transform: rotate(180deg);
                }
              }
            }
            .pronto-atend-view {
              width: 100% !important;
              padding: 0;
              .pronto-atend-header-txt {
                margin-bottom: 0.5rem;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                color: #231f20;
                font-family: "Inter", sans-serif;
              }
              .pronto-atend-sub-txt {
                color: #231f20;
                font-size: 16px;
                line-height: 24px;
                font-family: Inter;
                margin-bottom: 1.2rem;
                min-height: 71px;
                @media (max-width: 767px) {
                  min-height: auto;
                }
              }
            }
            .pronto-btn-view {
              text-align: center;
              .pronto-atend-btn {
                width: 100%;
                --ion-color-base: #fe5000 !important;
                --background-activated: #fff !important;
                --background-focused: #fff !important;
                --border-color: #fe5000 !important;
                --border-width: 1px;
                --border-style: solid;
                --color: #fe5000;
                --background: #fff;
                --border-radius: 10px;
                color: #fe5000;
                height: 50px;

                letter-spacing: 0px;
              }
            }
          }
          .bottomtextdiv {
            color: #5f5f5f;
            font-size: 14px;
            font-weight: 400;
            font-family: Roboto;
            max-width: 302px;
            margin: 0 auto;
          }
        }
      }
      .user-support-page {
        &.wl-ampla-saude {
          .back-header {
            @media (max-width: 1024px) {
              display: flex;
              justify-content: center;
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              color: #fe5000;
              padding-top: 1.3rem;
              position: relative;
              .back-icon {
                width: 24px;
                position: absolute;
                left: 22px;
                top: 18px;
              }
            }
          }
          .auto-h-container {
            min-height: auto;
            .width-90 {
              padding: 1rem 4rem;
              @media (max-width: 767px) {
                padding: 1rem;
              }
              .symptoms-patient-name {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                color: #fe5000;
              }
              .content-box {
                .symptoms-sub-title {
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 18px;
                  color: #fe5000;
                  margin-bottom: 0.5rem;
                }
                .symptoms-sub-text {
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 18px;
                  line-height: 23px;
                  color: #392a24;
                  margin-bottom: 1rem;
                }
                .symptoms-main-list {
                  margin: 0 0 2rem 0;
                  padding: 0 0 0 25px;
                  li {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 35px;
                    letter-spacing: -0.015em;
                    color: #392a24;
                    list-style: none;
                    &::before {
                      content: "\2022";
                      color: #ffad00;
                      font-weight: bold;
                      display: inline-block;
                      width: 1em;
                      margin-left: -1em;
                    }
                  }
                }
                .symptoms-call {
                  .symptoms-call-btn {
                    --ion-color-base: #fe5000 !important;
                    --background-activated: #fe5000 !important;
                    --background-focused: #fe5000 !important;
                    --border-color: transparent !important;
                    --border-width: 0;
                    --color: white;
                    --background: #fe5000;
                    height: 50px;
                    --border-radius: 10px;
                    --box-shadow: none;
                    text-transform: none;
                    max-width: 326px;
                    @media (max-width: 767px) {
                      max-width: 100%;
                    }
                  }
                }
                .symptoms-cancel {
                  margin-top: 15px;
                  .symptoms-cancel-btn {
                    text-transform: none;
                    font-weight: 500;
                    max-width: 326px;
                    font-family: Inter;
                    --ion-color-base: transparent !important;
                    --background-activated: transparent !important;
                    --background-focused: transparent !important;
                    --border-color: transparent !important;
                    --background: transparent;
                    --box-shadow: none;
                    font-style: normal;
                    letter-spacing: 0px;
                    // font-weight: 500;
                    font-size: 14px;
                    color: #fe5000;
                    @media (max-width: 767px) {
                      max-width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
    @media (min-height: 768px) {
      // height: 770px;
      overflow: auto;
    }
  }
  .sub-content {
    // max-width: 450;
    max-width: 600px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    left: 0;
    top: 1% !important;
    margin: 0 auto;
    max-width: 1250px !important;
    @media (max-width: 1025px) {
      max-width: auto;
      position: unset;
      top: 0;
      max-width: 450px;
    }
  }
  .rightcontent {
    flex: 2;
    padding-left: 148px;
    padding-top: 80px;
  }
  // .user-support-page{
  //   padding: 0 !important;
  // }
}
