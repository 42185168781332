.availacao {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;

  color: #ffad00;
}
.review-title-sub-header-txt {
  .symptoms-sub-text {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    letter-spacing: -0.01em;
    color: #392a24;
    @media (max-width: 767px) {
      font-size: 25px;
    }
    .text-dark-green {
      color: #fe5000;
    }
  }
}
.review-screen-img-mobile {
  @media (max-width: 1024px) {
    max-width: 170px;
    margin: 0 auto;
  }
}
.reviewbox-bottom {
  .custom-card {
    background: #ffffff;

    border: 1px solid #c7c8cc;
    box-sizing: border-box;
    border-radius: 12px;
    .card-body {
      .review-text-area-view {
        background: #f4f5f7;
        border-radius: 12px;
        z-index: 0;
        border: 0;
        padding: 18px;
        margin-top: 0;
        --placeholder-opacity: 1;
        textarea {
          padding: 0;
          color: #767676;
        }
      }
    }
    .rateDiv {
      border-bottom: #f2f4f5 solid 1px;
      padding: 8px 20px;
      text-align: center;
      .react-rater {
        width: 100%;
        .react-rater-star {
          width: auto;
          margin-right: 5px;
          &:hover {
            color: #eaa901;
          }
          &.is-active {
            color: #eaa901;
          }
        }
      }
    }
  }
  .bottombuttons {
    .edit-grid-view {
      padding: 0;
      .footer-btn-view {
        .cancel-border-btn {
          --ion-color-base: transparent !important;
          --background-activated: transparent !important;
          --background-focused: transparent !important;
          --border-color: transparent !important;
          --border-width: 0;
          --color: white;
          --background: transparent;
          font-weight: normal;
          font-size: 16px;
          color: #c06666;
          cursor: pointer;
          border: 1px solid #c06666;
          box-sizing: border-box;
          border-radius: 10px;
          text-align: center;
          height: 50px;
          --box-shadow: none;
          text-transform: none;
          margin: 0 0.5rem 0 0;
          @media (max-width: 767px) {
            margin: 0 0.2rem 0 0;
          }
        }
        .rating-active-btn {
          // background: #FE5000;
          --border-radius: 10px;
          --ion-color-base: #fe5000 !important;
          --background-activated: #fe5000 !important;
          --background-focused: #fe5000 !important;
          --border-color: transparent !important;
          --border-width: 0;
          --color: white;
          --background: #fe5000;
          --box-shadow: none;
          height: 50px;
          text-transform: none;
          font-size: 16px;
          font-weight: 400;
          margin: 0 0 0 0.5rem;
          @media (max-width: 767px) {
            margin: 0 0 0 0.2rem;
          }
        }
      }
    }
  }
}
