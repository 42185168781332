@mixin customscroll {
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #efefef;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #BDBDBD;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #BDBDBD;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 600px) {
  .hide-on-desktop,
  * [aria-labelledby="hide-on-desktop"] {
    display: none;
    max-height: 0;
    overflow: hidden;
  }
}
.medicine-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 479px) {
    width: 100%;
  }
  .medicne-list-content {
    overflow-y: auto;
    height: calc(100vh - 389px);
    width: 100%;
    margin: 20px 0 0 0;
    @include customscroll();
    background: transparent;

    @media (max-device-width: 1024px) {
      height: calc(100vh - 389px);
    }
    @media (max-device-width: 640px) {
      height: calc(100vh - 670px);
    }
    @media (max-device-width: 540px) {
      height: calc(100vh - 308px);
    }
    @media (max-device-width: 414px) {
      height: calc(100vh - 312px);
    }
    @media (max-device-width: 375px) {
      height: calc(100vh - 304px);
    }
    @media (max-device-width: 360px) {
      height: calc(100vh - 312px);
    }
    @media (max-device-width: 320px) {
      height: calc(100vh - 314px);
    }
    @media (max-device-width: 280px) {
      height: calc(100vh - 314px);
    }
    // @media (max-width: 479px) {
      
    //   width: 100%;
    //   margin: 20px auto 0 auto;
    //   height: calc(100vh - 375px);
    //   padding-right: 0;
    // }
    // margin-bottom: 100px;
    .medicine-card-antigas-content {
      margin-top: 0;
      padding: 16px;
      border: 1px solid #F6F1EE;
      box-shadow: none;
      margin-bottom: 18px;
      border-radius: 12px;
      background: #F6F1EE;
      .medicine-content-box-antigas {
        display: flex;
        width: 100%;

        .medicine-box-antigas {
          width: 100%;
          margin: 0px 0px 0 20px;
          .medicine-pedidos-action-content {
            display: flex;
            justify-content: space-between;
            margin-top: 18px;
            margin-bottom: 2px;
          }
        }
        .date-label-antigas {
          color: rgba(117, 117, 117, 1);
        }
        .medicine-detail-content-antigas {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0;
          font-size: 12px;

          .detail-validas-date-txt {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 10px;
            color: #757575;
          }

          .detail-label-antigas {
            color: #828282;
            margin-left: 3px;
            font-size: 14px;
          }
        }
        .middle-antigas {
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 124%;
          color: #262626;
        }
        .lastsize-antigas {
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 124%;
          color: #828282;
          margin-top: 6px;
        }
      }
      .hr-content-antigas {
        background-color: #bdbdbd;
        opacity: 1;
        margin: 14px 0;
      }
      .footerbott-antigas {
        padding: 10px 0 0px 0;
      }
      .action-content-antigas {
        display: flex;
        justify-content: space-between;
        .action-label-antigas {
          color: #a212a0;
        }
      }
    }

    .medicine-card-content {
      padding: 20px;
      box-shadow: none;
      border: 1px solid #F6F1EE;

      box-sizing: border-box;
      margin-bottom: 18px;
      border-radius: 10px;
      background: #F6F1EE;
      .medicine-content-box {
        display: flex;
        width: 100%;

        .medicine-box {
          width: 100%;
          margin: 0px 0px 0 15px;
        }
        .medicine-detail-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0px;
          font-size: 12px;

          .detail-validas-date-txt {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 10px;
            color: #757575;
          }

          .detail-label {
            font-family: "Source Sans Pro";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #FE5000;
            margin-left: 3px;
          }
        }
        .middle {
          color: #262626;
          font-size: 18px;
          font-family: 'Source Sans Pro', sans-serif;
          ion-label{
            font-family: 'Source Sans Pro', sans-serif;
          }
        }
        .lastsize {
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 124%;
          color: #FE5000;
          margin-top: 4px;
          margin-bottom: 10px;
        }
      }
      .hr-content {
        background-color: #bdbdbd;
        opacity: 1;
        margin: 14px 0;
      }
      .footerbott {
        padding: 0;
      }
      .action-content {
        display: flex;
        justify-content: space-between;
        .action-label {
          font-family: "Source Sans Pro";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #FE5000;
          text-decoration: none;
          .sub-icon {
            padding-right: 5px;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
.medicine-container {
  display: flex;
  justify-content: center;

  .medicne-no-data-list-content {
    overflow-y: auto;
    height: calc(100vh - 397px);
    padding-right: 1rem;
    @include customscroll();
    margin-top: 44px;
    @media (max-width: 479px) {
      height: calc(100vh - 536px) !important;
    }
    // margin-bottom: 100px;
    .medicine-card-content {
      padding: 20px;
      border: #9edfff solid 1px;
      box-shadow: none;
      margin-bottom: 1rem;
      border-radius: 12px;
      .medicine-content-box {
        display: flex;
        width: 100%;

        .medicine-box {
          width: 100%;
          margin: 0px 0px 0 15px;
        }
        .medicine-detail-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          font-size: 12px;

          .detail-validas-date-txt {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 10px;
            color: #757575;
          }

          .detail-label {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #f7941d;
          }
        }
        .middle {
          color: #000000;
          font-size: 16px;
        }
        .lastsize {
          color: #FE5000;
          font-size: 14px;
          margin-top: 16px;
          margin-bottom: 10px;
        }
      }
      .hr-content {
        background-color: #bdbdbd;
        opacity: 1;
        margin: 14px 0;
      }
      .footerbott {
        padding: 10px 0 0px 0;
      }
      .action-content {
        display: flex;
        justify-content: space-between;
        .action-label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #3e3e40;
          text-decoration: none;
          .sub-icon {
            padding-right: 5px;
            padding-left: 5px;
          }
        }
      }
    }
  }
}

.medicine-mainwrapper {
  display: flex;
  background-color: #ffffff;
  background: #ffffff;
  height: calc(100vh - 183px);
  width: 100%;
  overflow-y: auto;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0px;
    padding-top: 0px;
  }

  .rightcontent {
    padding-left: 0;
    padding-top: 2px;
    padding-bottom: 0 !important;
    width: 100%;
    .header-content {
      width: 100%;

      margin: 16px 0;
      line-height: normal;
      display: block;
      text-align: left;
      .medicinetopsubtitle {
        color: #FE5000;
        font-size: 16px;
        font-weight: bold;
        display: block;
        margin-bottom: 1rem;
      }
      .medicine-title {
        font-size: 30px;
        color: #FFAD00;
        font-weight: 400;
      }
      .medicine-main-title-arrow-left {
        width: 24px;
        height: 24px;
        float: left;
      }
      .medicine-topsubtitle-mobile {
        color: #FE5000;
        font-size: 16px;
        font-weight: bold;
        display: block;
        margin-bottom: 1rem;
        text-align: center;
        justify-content: center;
      }
    }
    .medicine-layoutview {
      max-width: 600px;   
      //  min-height: 540px;
      // margin: 0 auto;
      @media (min-width: 768px) {
        margin-left: 2rem;
      }
      .nodata-container{
        height: calc(100vh - 389px);
        width: 100%;
        margin: 20px 0 0 0;
        flex-direction: row;
        justify-content: center;
        align-items: start;
      }

      .title-img-view {
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 9px 28px 0px;
        /*  filter: invert(18%) sepia(44%) saturate(3971%) hue-rotate(297deg)
        brightness(89%) contrast(91%); */
      }
      .medicine-title {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 20px;
        color: #231f20;
        margin: 0 0 30px 0;
        text-align: center;
      
      }
      .header-tab-view {
        // --indicator-color: red;
        ion-segment{
          color: red;
        }
        ion-segment-button {
          --indicator-box-shadow: transparent!important;
          --background-checked: var(--ion-color-success);
          --background: white;
          --indicator-color : transparent!important;
          --padding-start: 0px;
          --padding-end: 0px;
          --margin-end:0px;
          --margin-start:0px;
          --border-radius: 0px;
          background: white !important;
          padding: 0.2rem;
          border-bottom: 1px solid #BDBDBD;
          &.segment-button-checked {
              --indicator-color: #FFAD00;
              border-bottom: 3px solid #FFAD00;
              ion-label {
                  color: #FFAD00;
                  font-size: 16px;
                  font-weight: 400;
                  text-transform: capitalize;
                }
          }
        --indicator-color: rgba(51, 195, 240, 1);
        margin: 0;
        --border-color: #FFFFFF;
      }
      ion-label {
        color: #999998;
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
      //   border-bottom: 2px solid;
        margin: 0 20px;
        letter-spacing: 0;
        font-family: 'Source Sans Pro', sans-serif;
      
        }
      }
    }
  }
}
.medicine-visuals {
  display: flex;
  align-items: center;
}
.enviar-btn {
  margin-left: -10px;
}
