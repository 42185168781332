.settings-screen {
    min-height: calc(100vh - 183px);
    .bottom-buttons {
        @media only screen and (max-width: 991px) {
            max-width: 400px;
            margin: 0 auto;
        }
    }
    .main-heading {
        @media only screen and (max-width: 1300px) {
            div {
                &:first-child {
                    font-size: 18px;
                }
            }
        }
    }
    .col-left {
        .arrow-leftdls {
            @media only screen and (max-width: 991px) {
                display: none !important;
            }
        }
        .bottom-buttons {
            @media only screen and (max-width: 1300px) {
                padding-left: 0;
            }
        }
        ion-list {
            margin-top: 1rem;
            @media only screen and (max-width: 1300px) {
                margin-top: 0;
                padding-left: 0;
            }
            ion-item {
                --padding-end: 0;
                --padding-start: 0;
                --border-color: var(--lightblue);
                line-height: 62px;
                padding-right: 0;
                --inner-padding-end: 0;
                cursor: pointer;
                @media only screen and (max-width: 1200px) {
                    line-height: 30px;
                }

                @media only screen and (max-width: 991px) {
                    line-height: 62px;
                }

                ion-text {
                    font-weight: 400;
                    font-size: 16px;
                    color: var(--darkblue);
                }
                svg {
                    margin-left: auto;

                    path {
                        fill: var(--bluedark);
                    }
                }
            }
            .firstitem {
                border-top: 1px solid #C7C8CC;
            }
            ion-item{
                --border-color: #C7C8CC;
             }
        }
        .leftcontent{
            width: 100%;
            margin: 16px 0;
            line-height: normal;
            display: block;
            text-align: left;
            .setting-user-title{
                color: var(--darkgray);
                font-size: 16px;
                font-weight: bold;
                display: block;
                margin-bottom: 0.5rem;
            }
            .config-title{
                font-size: 36px;
                color: var(--greencolor);
                font-weight: 400;
                margin: 0;
            }
        }
    }
    .header-content{
        width: 100%;
        margin: 16px 0;
        line-height: normal;
        display: block;
        text-align: left;
        .backarrowdiv{
            width: 24px;
    height: 24px;
    float: left;
        }
        .setting-title{
            color: var(--bluelight);
            font-size: 16px;
            font-weight: 600;
            display: block;
            margin-bottom: 2rem;
            text-align: center;
            justify-content: center;
            .titleicon{
                max-width: 20px;
    display: inline-block;
    vertical-align: text-bottom;
    margin: 0 9px 0 0;
            }
            
        }
    }
    .col-right {
        @media only screen and (min-width: 992px) {
            min-height: calc(100vh - 95px);
            padding: 2rem 0 5rem 0rem;
            position: absolute;
            right: 0;
            background-color: #f4f5f7;
        }
    }
    .mainText {
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        color: var(--bluedark);
        padding-left: 4rem;
        @media only screen and (max-width: 991px) {
            font-weight: 500;
            font-size: 30px;
            line-height: normal;
            margin-bottom: 1rem;
            margin-top: 0.5rem;
            margin-bottom: 0 !important;
        }
        @media only screen and (max-width: 375px) {
            font-size: 26px;
        }

        .iconleft {
            max-width: 30px;
            min-width: 30px;
        }
    }
    .middle-content {
        max-height: calc(100vh - 383px);
        overflow-y: auto;
        position: absolute;
        width: 100%;
        left: 0;
        top: 85px;
        max-width: 800px;
        padding: 0 2rem 0 4rem;

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
        @media only screen and (max-width: 1100px) {
            max-height: calc(100vh - 372px);
        }

        @media only screen and (max-width: 991px) {
            top: auto;
            padding-right: 0;
            max-height: calc(100vh - 372px);
        }

        .innterPolicyContext {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.015em;
            color: rgba(0, 0, 0, 0.7);
            span {
                font-weight: 700;
                color: var(--darkblue);
                strong {
                    color: var(--lightblue);
                }
            }

            p {
                margin-bottom: 1rem;
                font-size: 16px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.7);
            }
            a {
                color: var(--lightblue);
            }
        }
    }
    .bottom-btn {
        background: #ffffff;
        width: 100%;
        padding: 1rem;
        color: var(--bluedark);
        .inner-sec {
            max-width: 800px;
            ion-col {
                max-width: 400px;
                margin: 0 auto;
            }
        }
    }
    .orange-btn{
        --ion-color-base: var(--bluedark) !important;
    }

    .delete-text {
        color: #192C50;
        --border-color: #192C50;
    }

    .logout-text {
        color: #E30513;
    }
}
.subtext-iron {
    color: var(--darkblue);
}
.subtext-health {
    color: var(--lightblue);
}

.maskedInput-border{
    border: 1px solid var(--bluedark) !important;
}
.settingscreen-view {
    .termscondition {
        .container {
            padding: 0 5rem;
            @media screen and (max-width: 992px) {
                padding: 0 0.75rem;
            }
        }
        .header-content {
            .titleicon {
                height: 20px; 
                width: 20px;
                display: inline-block;
                vertical-align: text-bottom;
                margin: 0 9px 0 0;
            }
            .setting-title {
                color: var(--bluelight);
                font-size: 16px;
                font-weight: 600;
                display: block;
                margin-bottom: 2rem;
                text-align: center;
                justify-content: center;
            
            }
        }
    }
}