.header {
  background-color: #FE5000;
  padding: 17px 0;
  position: sticky;
  top: 0;
  position: -webkit-sticky;
  z-index: 3;
  .toptext {
    color: #fff;
    margin-left: 2rem;
  }
  .col-menu-4{
    padding-right: 0px;
    width: 30.2%;
  }
  .middlemenu {
    .menu-saie-label{
      margin: 5px 0 0 0;
      width: 100%;
      text-align: left;
    }
    .menu-saie-label-logout{
      color: #fff;
    }
    .menu-home-label{
      margin: 0px !important;
      text-align: right;
      width: 100%;
    }
   
    .menu-profile{
      min-width: 10px;
    }
    padding-left: 0px !important;
    .in-segment {
      color: #fff;
      --indicator-color: transparent !important;
      --color-checked:#fff !important;
      --background-hover:none !important;
      font-family: 'Source Sans Pro', sans-serif;
      text-transform: none;
      font-size: 17px;
        font-weight: 400;
        letter-spacing: 0;
      &.active {
        color: #fff;
        font-weight: 700;
        span{
          font-weight: 700;
        }
        
      }
      &:hover {
        --color: #fff !important;
         --indicator-color: #fff !important;
      }
      span{
        font-size: 17px;
        font-weight: 400;
        letter-spacing: 0;
      }
    }
    .signout {
      margin-right: 5px;
    }
    .header-profile {
      width: 40px !important;
      height: 40px !important;
    }
  }
  .logout-style {
      margin-left: auto !important;
  }
}
