@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,700;0,900;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
body {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
  color: #000;
}

.text-center {
  --ion-text-color: #FE5000;
    color: #FE5000;
  --ion-color-base: #FE5000 !important;
  --background-activated: #FE5000 !important;
  --background-focused: #FE5000 !important;
  --ion-color-primary: #FE5000 !important; 
}

*,
*::before,
*::after {
  font-family: "Roboto", sans-serif;
}
.ion-margin-bottom {
  margin-bottom: 0;
  margin-top: 0;
}
.orange-btn {
  font-weight: 400;
  font-size: 16px;
  --box-shadow: none;
  letter-spacing: 0px;
  text-transform: inherit;
  min-height: 48px;
  min-width: 190px;
  --ion-color-base: #FE5000 !important;
  --background-activated: #FE5000 !important;
  --background-focused: #FE5000 !important;
}

.greenoutline-btn {
  font-weight: 400;
  font-size: 16px;
  color: var(--darkblue);
  --box-shadow: none;
  letter-spacing: 0px;
  text-transform: inherit;
  min-height: 48px;
  --ion-color-primary: #FE5000 !important; 
  --ion-color-base: #FE5000 !important;
}

.without-border-btn {
  --border-width: 0px;
}

.modal-shadow.sc-ion-modal-ios {
  --height: 40% !important;
}
.link-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;color: #4F4F4F;
  letter-spacing: -0.015em;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
.privacy-modal {
  .modal-wrapper {
      background-color: white;
      color: #000;
      border-radius: 12px;
      width: 328px;
      height: auto;
      @media screen and (max-width: 414px) {
          width: 300px !important;
      }
      .ion-page {
          justify-content: center;
          text-align: center;
          padding: 20px;
          position: relative;
          contain: content;
      }
      .privacy-title {
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          color: var(--greencolor1);
          padding: 0 0 20px;

          // font-family: "Roboto", sans-serif;
      }
      .contentdiv {
          // font-family: "Roboto", sans-serif;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 20px;
      }
      .custom-privacy-btn {
          height: 44px;
          margin: 0;
          --ion-color-base: #fff !important;
          margin-bottom: 20px;
          --border-radius: 50px !important;
          --box-shadow: none;
          color: var(--redcolor);
          border: var(--redcolor) solid 1px;
          text-transform: none;
          --border-radius: 50px;
          --background-focused: #fff;
          --background-focused-opacity: #fff;
          --background-activated-opacity: #fff;
          --background-activated: #fff;
          --background-hover: #fff;
          --background-hover: #fff;
          border-radius: 51px;
          font-size: 16px;
          --ion-color-contrast: var(--redcolor) !important;
          &:hover {
              background-color: transparent;
          }
      }
      .custom-privacy-cancel-btn {
          text-align: center;
          color: var(--greencolor2);
          margin-top: 20px;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          margin-top: 20px;
          cursor: pointer;
          caret-color: #fff;
      }
  }
}

.custom-modal {
  --border-radius: 12px;
  padding: 2rem 0;
  --max-width: 340px;
  .modal-wrapper {
    height: auto;

    position: absolute;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);

    .passWelContent {
      .custom-input {
        ion-item {
          ion-input {
            padding-right: 3rem !important;
          }
        }
      }
      .cust-validation-msg {
        text-align: center;
        display: block;
        margin-bottom: 7px;
        color: var(--redcolor);
      }
      .password-field {
        position: relative;
        .custom-input {
          input {
            border-radius: 12px;
            --padding-start: 14px !important;
            background-color: var(--lightgray);
            --border-radius: 12px;
            --padding-end: 14px !important;
            padding: 13px;
            min-height: 45px;
            border: none;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: var(--darkgray);
          }
        }
        ion-icon {
          position: absolute;
          right: 15px;
          z-index: 11;
          top: 10px;
          cursor: pointer;
          max-width: 18px;
          min-width: 20px;
        }
      }
    }

    .ion-page {
      padding: 1.5rem;
      position: relative;
      contain: content;
    }

    .true-img {
      max-width: 160px;
      margin: 0 auto;
    }
    .password-delete-title {
      font-weight: 700;
      font-size: 16px;
      color: #4f4f4f;
      margin-bottom: 1.5rem;
      text-align: center;
    }
  }
  .orange-btn {
    min-width: auto;
  }
  .ion-margin-bottom {
    max-width: 160px;
    margin-left: auto;
    margin-right: auto;
  }
  .text-upper {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: var(--gray);
    strong {
      color: var(--bluecolor);
    }
  }
  .text-lower {
    color: var(--darkgray);
    a {
      color: var(--greenlight);
      text-decoration: none;
    }
  }
 
  &.devicedetect-modal {
    .modal-wrapper {
      .ion-page {
        background: #f5dbd3;
        border-radius: 2.2037px;
      }
    }
    .closeicon {
      max-width: 20px;
      float: right;
      margin-left: auto;
      min-width: 18px;
      position: absolute;
      right: 10px;
      top: 11px;
      cursor: pointer;
      z-index: 1111;
    }
    .text-div {
      font-weight: 600;
      font-size: 13.2222px;
      color: var(--bluecolor);
    }
  }
}

.logout-title{
  font-weight: 600;
}

.font-bold{
  font-weight: 600;
  .link-text{
    font-weight: 600;
  }
}

.termsclass {
  // background-color: #FE5000;
  // --ion-color-base: #FE5000 !important;
  // --background-activated: #FE5000 !important;
  // --background-focused: #FE5000 !important;
  --width: 380px !important;
  --height: 250px !important;
  .modal-wrapper {
    border-radius: 12px;
  }
  .terms-content-box{
    padding: 15px;
    text-align: center;
     
  }
  .btnsdls {
    flex-direction: column;
   
    ion-button {
      width: 100% !important;
      color:  #FE5000 !important;
    }
  }
}

.fixed-loader {
  @media only screen and (max-width: 1024px) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 11;
  }
}

/** Ionic CSS Variables **/

// ion-button[type="submit"] {
//   appearance: none;
//   -moz-appearance: none;
//   -webkit-appearance: none;
// }

.backarrow {
  max-width: 36px;
  cursor: pointer;
  min-width: 36px;
}

ion-progress-bar {
  --background: #04486d;
  --buffer-background: #e1e1e1;
  --progress-background: #04486d;
}

.img-leftdls {
  max-width: 303px;
  margin: 0 auto;
}

/* ===== Scrollbar CSS ===== */

* {
  scrollbar-width: auto;
  scrollbar-color: #e8e5e5 #ffffff;
}
*::-webkit-scrollbar {
  width: 12px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #e8e5e5;
  border-radius: 10px;
}

.middlearea-message {
  min-height: calc(100vh - 247px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 1024px) {
    min-height: calc(100vh - 379px);
  }
  .connection_text {
    color: var(--lightblue);
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: normal;
  }
}

.nodata-container {
  text-align: center;
  max-width: 250px;
  font-size: 18px;
  font-weight: 400;
  color: var(--highdarkblue);
  line-height: normal;
  margin: 0 auto;
}

// ion-segment-button {
//   --ripple-color: transparent;
//   --ion-color-base: transparent !important;
//   --background-activated: transparent !important;
//   --background-focused: transparent !important;
//   --background-hover: transparent !important;
// }

// ion-segment-button{
//   --ripple-color: transparent;
//   --ion-color-base: transparent !important;
//   --background-activated: transparent !important;
//   --background-focused: transparent !important;
//   --background-hover: transparent !important;
// }

.userlist-popover {
  .popover-arrow {
    display: none;
  }
  .popover-content {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    overflow: hidden;
    border: 0px;
    box-shadow: -6px 12px 26px rgba(0, 0, 0, 0.25);
    min-width: 100%;
    padding: 10px 0;
    left: 0 !important;
    ion-list {
      padding: 0px;
      border-radius: 0px;
    }
    .currentuser {
      background: linear-gradient(
        65.58deg,
        rgba(255, 168, 143, 0.032) -28.08%,
        rgba(10, 164, 107, 0.1) 25.05%,
        rgba(255, 168, 143, 0.076) 71.14%,
        rgba(0, 240, 118, 0.112) 121.93%
      );
      --background: linear-gradient(
        65.58deg,
        rgba(255, 168, 143, 0.032) -28.08%,
        rgba(10, 164, 107, 0.1) 25.05%,
        rgba(255, 168, 143, 0.076) 71.14%,
        rgba(0, 240, 118, 0.112) 121.93%
      );
      .text-overflow-class {
        font-weight: 600;
        --color: var(--lightblue) !important;
        color: var(--lightblue);
        text-decoration: none;
        text-align: left;
      }
      ion-label {
        font-weight: 400;
        font-size: 14px;
        color: #000;
        text-decoration: underline;
        text-align: right;
        margin-right: 0;
      }
    }
    .currentuser:hover {
      background: linear-gradient(
        65.58deg,
        rgba(255, 168, 143, 0.032) -28.08%,
        rgba(10, 164, 107, 0.1) 25.05%,
        rgba(255, 168, 143, 0.076) 71.14%,
        rgba(0, 240, 118, 0.112) 121.93%
      );
      --background: linear-gradient(
        65.58deg,
        rgba(255, 168, 143, 0.032) -28.08%,
        rgba(10, 164, 107, 0.1) 25.05%,
        rgba(255, 168, 143, 0.076) 71.14%,
        rgba(0, 240, 118, 0.112) 121.93%
      );
    }
    ion-item {
      cursor: pointer;
      --color: var(--darkblue) !important;
      --background: rgba(247, 191, 144, 0.2) !important;
      --border-style: none;
      --padding-start: 15px;
      --padding-end: 15px;
      margin: 10px 15px;
      --padding-top: 9px;
      --padding-bottom: 9px;
      --padding-start: 20px;
      --padding-end: 20px;
      text-transform: capitalize;
      --inner-padding-end: 0;
      --inner-padding-start: 0;
      ion-avatar {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-right: 15px;
      }
    }
    ion-item:hover {
      --background: rgba(247, 191, 144, 0.2) !important;
      color: var(--darkblue);
    }
  }
}

.userlist-popover-desk {
  .popover-arrow {
    display: none;
  }
  .popover-content {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    right: 95px !important;
    overflow: hidden;
    border: 0px;
    box-shadow: -6px 12px 26px rgba(0, 0, 0, 0.25);
    min-width: 350px !important;
    padding: 10px 0;
    ion-list {
      padding: 0px;
      border-radius: 0px;
    }
    .currentuser {
      background: linear-gradient(
        65.58deg,
        rgba(255, 168, 143, 0.032) -28.08%,
        rgba(10, 164, 107, 0.1) 25.05%,
        rgba(255, 168, 143, 0.076) 71.14%,
        rgba(0, 240, 118, 0.112) 121.93%
      );
      --background: linear-gradient(
        65.58deg,
        rgba(255, 168, 143, 0.032) -28.08%,
        rgba(10, 164, 107, 0.1) 25.05%,
        rgba(255, 168, 143, 0.076) 71.14%,
        rgba(0, 240, 118, 0.112) 121.93%
      );
      .text-overflow-class {
        font-weight: 600;
        --color: var(--lightblue) !important;
        color: var(--lightblue);
        text-decoration: none;
        text-align: left;
      }
      ion-label {
        font-weight: 400;
        font-size: 14px;
        color: #000;
        text-decoration: underline;
        text-align: right;
        margin-right: 0;
      }
    }
    .currentuser:hover {
      background: linear-gradient(
        65.58deg,
        rgba(255, 168, 143, 0.032) -28.08%,
        rgba(10, 164, 107, 0.1) 25.05%,
        rgba(255, 168, 143, 0.076) 71.14%,
        rgba(0, 240, 118, 0.112) 121.93%
      );
      --background: linear-gradient(
        65.58deg,
        rgba(255, 168, 143, 0.032) -28.08%,
        rgba(10, 164, 107, 0.1) 25.05%,
        rgba(255, 168, 143, 0.076) 71.14%,
        rgba(0, 240, 118, 0.112) 121.93%
      );
    }
    ion-item {
      cursor: pointer;
      --color: var(--darkblue) !important;
      --background: rgba(247, 191, 144, 0.2) !important;
      --border-style: none;

      margin: 10px 15px;
      --padding-top: 9px;
      --padding-bottom: 9px;
      --padding-start: 20px;
      --padding-end: 10px;
      text-transform: capitalize;
      ion-avatar {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-right: 15px;
      }
    }
    ion-item:hover {
      --background: rgba(247, 191, 144, 0.2) !important;
      color: #000000;
    }
  }
}

:root {
  /* sitecolor */
  // --dark-green: #0c5c3e;
  --highdarkblue: #04486d;
  --darkblue: #192c50;
  --lightblue: #009ddb;
  --darkgray: #3d3c3c;
  --gray: #4f4f4f;
  --lightgray: #f4f5f7;
  --greenlight: #58a831;
  --lightorange: #ffa88f;
  --textgray: #828282;
  --redcolor: #f9423a;
  --darkgray1: #757575;
  --darkgray3: #5f5f5f;
  --darkgray2: #5a6479;
  --darkgray4: #262626;
  --SourceSansPro: "Source Sans Pro", sans-serif;

  /** primary **/
  --ion-color-primary: #009ddb;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #009ddb;
  --ion-color-primary-tint: #009ddb;
  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #0f734d;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0f734d;
  --ion-color-success-tint: #0f734d;
  /** warning **/
  --ion-color-warning: #dd4848;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #dd4848;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #dd4848;
  --ion-color-warning-tint: #dd4848;
  /** danger **/
  --ion-color-danger: #f9423a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #0c5c3e;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #f9423a;
  --ion-color-danger-tint: #f9423a;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark11) {
  /*
   * Dark Colors
   * -------------------------------------------
   */
  body.dark11 {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;
    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;
    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }
  /*
   * iOS Dark Theme
   * -------------------------------------------
   */
  .ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-item-background: #000000;
    --ion-card-background: #1c1c1d;
  }
  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
  .md body.dark11 {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
    --ion-item-background: #1e1e1e;
    --ion-toolbar-background: #1f1f1f;
    --ion-tab-bar-background: #1f1f1f;
    --ion-card-background: #1e1e1e;
  }
}