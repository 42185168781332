.profile-details {
  display: block;
}

.profile-mainwrapper {
  display: flex;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0px;
    padding-top: 0px;
    .update-profile-view {
      padding-left: 16px;
      padding-bottom: 30px;
      background-color: #ffffff;
      background: #ffffff;
    }
  }
  .input-hide-calendar-icon{
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
  }
  .rightcontent {
    flex: 2;
    padding-left: 0px;
    padding-top: 0px;
    position: relative;
    background-color: #ffffff;
    background: #ffffff;
    @media screen and (max-width: 767px) {
      padding-left: 0px;
      padding-top: 0px;
    }
    @media screen and (min-width: 768px) {
      &:after {
        background: #F6F1EE;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 50%;
      }
    }

    .container {
      position: relative;
      z-index: 1;
    }

    .update-profile-view {
      //   padding-left: 0px;
      // margin-top:16px;
      background-color: #ffffff;
      background: #ffffff;
      overflow-y: auto;
      overflow-x: hidden;

      .header-content {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-top: 0;
        @media screen and (max-width: 767px) {
          justify-content: center;
        }
      }
      .header-title-txt {
        // position: static;
        // height: 48px;
        // font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40.5px;
        margin: 0;
        background: transparent;
        color: #FFAD00;
        justify-content: center;
        text-align: left;
        @media screen and (max-width: 767px) {
          font-weight: 700;
          font-size: 16px;
          color: #FE5000;
          font-family: 'Roboto', sans-serif;
        }
      }

      .header-sub-title-text {
        // font-family: Source Sans Pro;
        font-style: normal;
        font-size: 16px;
        line-height: 23.04px;
        color: #5f5f5f;
        font-weight: 400;
      }
      .header-sub-title-text-body {
        visibility: hidden;
      }
      .title-img-view {
        width: 32px;
        height: 32px;
      }

      .title-sub-header-txt {
        margin-top: 0;
        margin-bottom: 0;
      }
      .mobilecontent {
        margin-bottom: 2.7rem;
        text-align: left;
        margin-top: 2.3rem;
       
        @media screen and (max-width: 767px) {
          text-align: center;
          margin-bottom: 2rem;
          // text-align: left;
          margin-top: 2rem
        }

        span {
          border-bottom: 1px solid #FE5000;
          display: flex;
          text-align: center;
          width: 100%;
          flex-direction: column;
          margin: 1.5rem 0 10px 0;
          padding-bottom: 10px;

          .nametit {
            color: #FE5000;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            font-family: 'Roboto', sans-serif;
            @media screen and (max-width: 767px) {
              text-align: center;
            }
          }
          .subname {
            color: #000000;
            font-size: 16px;
            font-weight: 400;
            margin-top: 8px;
            text-align: left;
            @media screen and (max-width: 767px) {
              text-align: center;
            }
          }
        }

        .dataname {
          color: #FE5000;
          font-size: 14px;
          font-weight: 500;
          width: 100%;
          text-align: left;
          display: block;
          font-family: 'Roboto', sans-serif;
          @media screen and (max-width: 767px) {
            text-align: center;
          }
        }
      }

      ion-col ion-grid {
        margin: 0px;
        padding: 0px;
      }
      .title-sub-view {
        position: static;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: normal;
        color: #999998;
        @media screen and (max-width: 767px) {
        color: #5F5F5F;
        }
      }

      .title-component {
        // font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #999998;
        margin-bottom: 10px;
        margin-left: 15px;
        font-family: 'Open Sans', sans-serif;
        @media screen and (max-width: 767px) {
color: #5F5F5F;    margin-bottom: 4px;
        }
      }
      .title-component-ans {
        height: 44px;
        // font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #5f5f5f;
        flex: none;
        order: 0;
        flex-grow: 0;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
      }

      .title-component-not-ans {
        // font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #d9d9d9;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 8px;
      }

      .update-btn-vuew {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 202px;
        height: 40px;
        border: transparent;
        box-sizing: border-box;
        border-radius: 4px;
        text-transform: none;
        // font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16.8px;
        line-height: 16px;
        margin-top: 30px;
        /* identical to box height, or 95% */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        --border-radius: 10px !important;
        --ion-color-base: #7d54b9 !important;
        --background-activated: #7d54b9 !important;
        --background-focused: #7d54b9 !important;
        --border-color: transparent !important;
        --border-width: 0;
        --color: #ffff;
        --background: #7d54b9;
        --box-shadow: none;
      }

      .input-icon {
        position: absolute;
        bottom: 20px;
        right: 30px;
        /* top: 0; */
        z-index: 2;
        max-width: 25px;
      }
      .input-icon-body {
        position: absolute;
        bottom: 20px;
        right: 30px;
        z-index: 2;
        height: 16px;
        width: 16px;
      }

      .input-border-view {
        --background: #ffffff;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 16px;
        position: static;
        height: 50px;
        border: 1.5px solid #999998;
        box-sizing: border-box;
        border-radius: 10px;
        margin: 2px 0px;
        padding-inline-start: 10px !important;
        --color: #999998 !important;
        line-height: 20px;
        font-weight: 400;
        font-size: 16px;

        @media only screen and (max-width: 767px) {
          --color: #5F5F5F !important;
        }
        /* ion-label{
            color: #9D9D9D !important; 
        } */
        input {
          height: 45px;
        }
        ion-datetime {
          --background: #ffffff !important;
          height: 50px;
          //   font-family: Source Sans Pro;
          font-style: normal;
          // font-weight: normal;
          font-size: 16px;
          // line-height: 24px;
          align-items: center;
          --color: #5f5f5f !important;
        }
        ion-input {
          height: 50px;
          //   font-family: Source Sans Pro;
          font-style: normal;
          // font-weight: normal;
          font-size: 16px;
          // line-height: 24px;
          align-items: center;
          --color: #5f5f5f !important;
        }
      }
      .custom-error-label {
        color: #e53737;
        margin-top: 4px;
        font-size: 11px;
        font-weight: 600;
        margin-left: 10px;
        // font-family: Source Sans Pro;
      }

      .salvar-btn {
        height: 40px;
        background: transparent;
        // border-radius: 4px;
        width: 100%;
        display: block;
        margin: 0 0 1rem 0;
        // font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        text-transform: none;
        --ion-color-base: #FE5000 !important;
        --background-activated: #FE5000 !important;
        --background-focused: #FE5000 !important;
        --border-color: transparent !important;
        --border-width: 0;
        --color: white;
        --background: #FE5000;
        --box-shadow: none;
        --border-radius: 10px !important;
        text-transform: capitalize;
        font-family: "Open Sans", sans-serif !important;
      }
      .salvar-btn-mobile {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 177px;
        height: 40px;
        background: #7d54b9;
        --border-radius: 10px !important;
        height: 40px;
        min-width: 200px;
        font-family: "Open Sans", sans-serif !important;

        // font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        text-transform: none;
        --ion-color-base: #7d54b9 !important;
        --background-activated: #7d54b9 !important;
        --background-focused: #7d54b9 !important;
        --border-color: transparent !important;
        --border-width: 0;
        --color: white;
        --background: #7d54b9;
        --box-shadow: none;
        text-transform: capitalize;
      }
      .cancelar-view-btn {
        background: transparent;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-transform: capitalize;
        text-align: center;
        letter-spacing: 0.02em;
        color: #c06666;
        margin-bottom: 12px;
        height: 40px;
        width: 100%;
        appearance: none !important;
        -moz-appearance: none !important;
        -webkit-appearance: none !important;
        // --ion-color-base: #C06666 !important;
        --background-activated: transparent !important;
        --background-focused: #c06666 !important;
        --border-color: #c06666 !important;
        --border-width: 1px !important;
        --border-radius: 10px !important;
        --border-style: solid !important;

        --background: transparent;
        --box-shadow: none;
      }

      ion-grid {
        margin: 0px;
      }
      //   ion-col {

      // }

      @media only screen and (max-width: 767px) {
        //padding: 12px 12px;

        .footer-btn-view {
          margin-bottom: 0;
          margin-top: 20px;
          text-align: -webkit-center;
        }
      }

      .edit-grid-view {
        // @media screen and (max-height: 568px) {
        //   width: fit-content;
        // }
        // @media screen and (max-height: 480px) {
        //   width: calc(100vh - 168px);
        // }
        .background-view {
          background-color: #ffffff;
          background: #ffffff;
        }
        @media only screen and (max-width: 767px) {
          .mxautomobile {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      form {
        ion-row {
          & > ion-col {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

.logout-modal {
  .modal-wrapper {
    background-color: white;
    color: #000;
    border-radius: 12px;
    width: 328px;
    height: auto;
    @media screen and (max-width: 414px) {
      width: 280px !important;
    }

    .ion-page {
      justify-content: center;
      text-align: center;
      padding: 20px;
      position: relative;
      contain: content;
    }

    .logout-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #000;
      padding: 15px 0 30px;
    }

    .custom-logout-btn {
      height: 44px;
      margin: 0;
      --ion-color-base: #FE5000 !important;
      margin-bottom: 20px;
      --border-radius: 10px !important;
      --box-shadow: none;
    }

    .custom-logout-cancel-btn {
      text-align: center;
      color: #FE5000;
      margin-top: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 132%;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
.topsubtitle-profile {
  color: #7d54b9;
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-bottom: 1rem;
}
.topsubtitle-profile-update {
  color: #FE5000;
  font-size: 16px;
  font-weight: 700;
  display: block;
  margin-bottom: 11px;
  line-height: 20px;
  font-family: 'Roboto', sans-serif;
}

.profile-container-main {
  display: flex;
  align-items: center;
}
.personal-details {
  max-width: 45%;
  padding: 0 50px 0 0;
  border-right: 1px solid #5f5f5f;
}
.body-details {
  max-width: 65%;
  padding-left: 50px;
}
.button-item {
  // flex-direction: column;
  // justify-content: center;
  // align-items: baseline;
  // margin-top: 1rem;
}
.edit-personal-details {
  //   background-color: #ffffff;
  //   height: 100vh;
  //   margin: -3px 0 0 0;
  //   padding: 50px 0 0 130px;
  padding: 1rem 0;
  .mobile-title {
    color: #999998;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    @media screen and (max-width: 767px) {
      display: block;
      margin-bottom: 10px;
      color: #5F5F5F;
    }
  }
}
.edit-body-measurements {
  background-color: #F6F1EE;
  padding-left: 3rem;
  padding-bottom: 2.5rem;
  padding-top: 3rem;
  height: calc(100vh - 183px);
  margin-right: -18px;
  // width: 100%;
  padding-right: 2rem;
  @media screen and (max-width: 767px) {
    height: auto;
    padding-left: 0rem;
    padding-bottom: 0;
    padding-top: 0;
    background: none;
  }
  .header-content-body {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  // height: calc(100vh - 195px);
  //   height: 100vh;
  //   margin: -3px 0 0 0;
  //   padding: 50px 0 0 85px;
  .bottomform {
    align-items: flex-end;

    .bottombuttondiv {
      @media screen and (max-width: 767px) {
        width: 100% !important;
      }
    }
  }
}
.topsubtitle-profile-update-body {
  visibility: hidden;
}
.header-content-body {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0;
  margin-top: 0;
  .profile-cursor{
    cursor: pointer;
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}
.edit-body-col {
  margin: 30px 0 10px 0 !important;
}
.edit-body-title-label {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #999998;
  margin-top: 10px;
  display: block;
  font-family: 'Roboto', sans-serif;
  @media screen and (max-width: 767px) {
  color: #5F5F5F;
  }
}
.edit-body-item {
  --background: transparent !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.examfile-upload{
  .modal-wrapper{
    border-radius: 12px;
    width: 328px;
    height: auto;
    position: absolute;
    bottom: auto;
    @media (max-width: 767px) {
      bottom: 0;
      width: 100%;
      border-radius: 12px 12px 0 0;
    }
    .ion-page{
      justify-content: center;
      text-align: center;
      padding:0 20px;
      position: relative;
      contain: content;
      .listdiv{
        border-bottom: 1px solid #cbcbcb;
        padding: 15px 0;
        cursor: pointer;
        img{
          margin-right: 14px;
        }
        label{
          min-width: 59px;
          text-align: left;
          font-size: 16px;
        }
      }
      .lastlist{
        padding: 13px 0 25px 0;
    color: #026447;
    cursor: pointer;
      }
    }
  }
}
.policy-content-dialog {
  // --height: 40%;
  // padding: 5% !important;
  // --border-radius: 8px !important;
}

.policy-content-dialog {
  .modal-wrapper {
    // background-color: white;
    // color: #000;
    // border-radius: 12px;
    // width: 328px;
    // height: 260px;
    // @media (max-width: 400) {
    //   width: 300px;
    // }
    // @media (min-width: 768px) {
    //   bottom: auto !important;
    // }

    .ion-page {
      // justify-content: center;
      // text-align: center;
      // padding: 20px;
    }

    .viewModelTitle {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #00885F !important;
      font-family: 'Inter';
      margin: 0;
    }

    .viewModelBody {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #000;
      padding: 15px 0 30px;
    }

    .custom-primary-btn {
      height: 44px;
      // border: #0012E6 solid 2px;
      border-radius: 50px;
      --ion-color-base: #00885f !important;
      --background-activated: #00885f !important;
      --background-focused: #00885f !important;
      --border-color: transparent !important;
      --border-width: 0;
      --color: white;
      --background: #00885f;
      --box-shadow: none;
      text-transform: capitalize;
      font-family:Inter;
      font-weight: 500;
      font-size: 14px;
      margin: 0 0 20px 0 !important;
     
    }
  }
&.bottom-modal{
  .modal-wrapper{
    // bottom: 20px;
    // position: absolute;
  }
 
}
}

.camerashowmodal{
  .react-html5-camera-photo{
    height: 100%;
  }

  .closeiconmodal{
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: red;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    text-align: center;
    z-index: 9;
    border: 0;
    outline: none;
    line-height: 35px;

    img{
      width: 15px;
      max-width: 15px;
      height: 15px;
      display: inline-block;
    }
  }
}
.camera-error{
  &.fixedheight-div{
    height: calc(100vh - 195px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 767px) {
      height: calc(100vh - 349px);

    }
  }
}