@mixin customscroll {
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #efefef;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #bdbdbd;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #bdbdbd;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 600px) {
  .hide-on-desktop,
  * [aria-labelledby="hide-on-desktop"] {
    display: none;
    max-height: 0;
    overflow: hidden;
  }
}

@media screen and (max-width: 600px) {
  .title_message {
    visibility: hidden;
    clear: both;
    float: left;
    display: none;
    max-height: 0 !important;
    overflow: hidden;
  }
}

.exam-mainwrapper {
  display: flex;
  background-color: #ffffff;
  background: #ffffff;
  height: calc(100vh - 183px);
  overflow-y: auto;
  // @media screen and (max-width: 767px) {
  //   flex-direction: column;
  //   flex-wrap: wrap;
  //   padding-left: 0px;
  //   padding-top: 0px;
  // }
  .rightcontent {
    padding-left: 0;
    padding-top: 25px;
    padding-bottom: 0 !important;
    width: 100%;
    .row {
      align-items: center !important;
    }

    .exam-header-content {
      width: 100%;

      margin: 0px 0;
      line-height: normal;
      display: block;
      text-align: left;
      .exam-topsubtitle {
        color: #fe5000;
        font-size: 16px;
        font-weight: bold;
        display: block;
        margin-bottom: 1rem;
        font-family: "Roboto", sans-serif;
      }
      .exam-main-title {
        font-size: 30px;
        color: #ffad00;
        font-weight: 400;
      }
      .exam-main-title-arrow-left {
        width: 24px;
        height: 24px;
        float: left;
      }
      .exam-topsubtitle-mobile {
        color: #fe5000;
        font-size: 16px;
        font-weight: bold;
        display: block;
        margin-bottom: 1rem;
        text-align: center;
        justify-content: center;
      }
    }

    .exam-sub-content {
      max-width: 550px;
      // min-height: 545px;
      // margin: 0 auto;
      @media (min-width: 768px) {
        margin-left: 2rem;
      }
      .nodata-container {
        height: calc(100vh - 510px);
        align-items: flex-start;
        justify-content: center;
        flex-direction: row;
        margin-top: 20px;
      }

      .exam-header-content {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .exam-title-img-view {
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 9px 28px 0px;
        /* filter: invert(18%) sepia(44%) saturate(3971%) hue-rotate(297deg)
          brightness(89%) contrast(91%); */
      }

      .exam-main-title {
        margin: 0 0 40px 0;
        text-align: center;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 20px;
        color: #231f20;

        /* background: #a8277f;
        background: -moz-linear-gradient(left, #a8277f 0%, #6dcff6 100%);
        background: -webkit-gradient(
          left top,
          right top,
          color-stop(0%, #a8277f),
          color-stop(100%, #6dcff6)      
        );
        background: -webkit-linear-gradient(left, #a8277f 0%, #6dcff6 100%);
        background: -o-linear-gradient(left, #a8277f 0%, #6dcff6 100%);
        background: -ms-linear-gradient(left, #a8277f 0%, #6dcff6 100%);
        background: linear-gradient(to right, #a8277f 0%, #6dcff6 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a8277f', endColorstr='#6dcff6', GradientType=1 );
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent; */
      }

      .exam-header-tab-view {
        ion-segment-button {
          --indicator-box-shadow: transparent !important;
          --background-checked: var(--ion-color-success);
          --background: white;
          --indicator-color: transparent !important;
          --padding-start: 0px;
          --padding-end: 0px;
          --margin-end: 0px;
          --margin-start: 0px;
          --border-radius: 0px;
          background: white !important;
          padding: 0.2rem;
          border-bottom: 1px solid #bdbdbd;
          &.segment-button-checked {
            --indicator-color: #ffad00;
            border-bottom: 3px solid #ffad00;
            ion-label {
              color: #ffad00;
              font-size: 16px;
              font-weight: 400;
              text-transform: capitalize;
            }
          }
          --indicator-color: rgba(51, 195, 240, 1);
        }
        ion-label {
          color: #999998;
          font-size: 16px;
          font-weight: 400;
          text-transform: capitalize;
          //   border-bottom: 2px solid;
          margin: 0 20px;
          font-family: "Source Sans Pro", sans-serif;
          letter-spacing: 0;
        }
      }

      .exam-list-content {
        overflow-y: auto;
        height: calc(100vh - 510px);
        padding-right: 1rem;
        @include customscroll();
        margin-top: 20px;
        overflow-x: hidden;
        background: transparent;

        @media (max-device-width: 1024px) {
          height: calc(100vh - 670px);
        }
        @media (max-device-width: 640px) {
          height: calc(100vh - 670px);
        }
        @media (max-device-width: 540px) {
          height: calc(100vh - 433px);
        }
        @media (max-device-width: 414px) {
          height: calc(100vh - 444px);
        }
        @media (max-device-width: 375px) {
          height: calc(100vh - 444px);
        }
        @media (max-device-width: 360px) {
          height: calc(100vh - 436px);
        }
        @media (max-device-width: 320px) {
          height: calc(100vh - 431px);
        }
        @media (max-device-width: 280px) {
          height: calc(100vh - 444px);
        }
        // @media (max-width: 479px) {

        //   margin-top: 20px;
        //   height: calc(100vh - 375px);
        //   padding-right: 0;
        // }

        .exam-card-content {
          margin-top: 0;
          padding: 20px 20px 20px 16px;
          // border: #dfdfdf solid 1px;
          box-shadow: none;
          margin-bottom: 15px;
          border-radius: 10px;
          background: #f6f1ee;
          border: 1px solid #dfdfdf;

          .exam-content-box {
            display: flex;
            width: 100%;

            .exam-content-header-img {
              width: 30px;
              height: 30px;
            }

            .exam-box {
              width: calc(100% - 40px);
              margin: 0px 0px 0 14px;

              .exam-detail-sub-txt-label {
                font-family: "Source Sans Pro";
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: inherit;
                color: #000000;
              }
            }
            .exam-detail-content {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 0px;
              font-size: 14px;
              font-family: "Source Sans Pro";

              .exam-pedi-date-label {
                font-family: Inter;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 10px;
                color: #757575;
              }

              .exam-detail-label {
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: #fe5000;
                margin-left: 3px;
              }
            }
          }

          .exam-footerbott {
            padding: 0;
          }
          .exam-hr-content {
            background-color: #bdbdbd;
            margin: 14px 0;
            opacity: 1;
          }
          .exam-action-content {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            .exam-outer-content{
              font-weight: 500;
              font-size: 14px;
              color:#000000;
              white-space: normal;
              word-break: break-all;
            }
            .exam-action-label {
              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              display: flex;
              align-items: center;
              color: #3e3e40;
              text-decoration: none;
              .sub-icon {
                padding-right: 5px;
                padding-left: 5px;
              }
              &.action-btn {
                font-family: Roboto;
                font-weight: 700;
                color: #fe5000;
                font-size: 14px;
                align-items: center;
                cursor: pointer;
                @media (max-width: 380px) {
                  font-size: 13px;
                }
                @media (max-width: 360px) {
                  font-size: 12px;
                }
                ion-img {
                  padding: 0;
                  width: 20px;
                  margin: 0 5px;
                }
              }
            }
          }
        }
      }
      .bottomexam-box {
        padding: 0 35px 0 12px;
        margin-top: 1rem;
        .exambox-subtitle {
          font-size: 16px;
          color: #ffad00;
          margin-bottom: 10px;
          display: block;
          padding-left: 1rem;
        }
        .examinput {
          border: #d9d9d9 solid 1px;
          border-radius: 4px;
          height: 48px;
          padding: 0 12px !important;
          color: black;
        }
        .input-exam-action-label {
          color: #fe5000;
          font-size: 14px;
          font-weight: 700;
          text-decoration: none;
          cursor: pointer;
          .input-sub-icon {
            width: 24px;
            height: 24px;
          }
        }
      }
      .exam-nodata-list-content {
        overflow-y: auto;
        height: calc(100vh - 397px);
        padding-right: 1rem;
        @include customscroll();
        margin-top: 44px;
        @media (max-width: 479px) {
          height: calc(100vh - 536px) !important;
        }

        .exam-card-content {
          padding: 20px;
          border: #dfdfdf solid 1px;
          box-shadow: none;
          margin-bottom: 1rem;
          border-radius: 12px;
          background-color: #fbfbfb;

          .exam-content-box {
            display: flex;
            width: 100%;

            .exam-content-header-img {
              width: 30px;
              height: 30px;
            }
            .exam-box {
              width: 100%;
              margin: 0px 0px 0 15px;

              .exam-detail-sub-txt-label {
                font-family: "Source Sans Pro";
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: inherit;
                color: #000000;
              }
            }
            .divider-live-view {
              height: 1px;
              left: -40px;
              top: 41px !important;
              right: -40px;
              width: 332px;
              position: relative;
              background: rgba(0, 0, 0, 0.35);
            }
            .exam-detail-content {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 0px;
              font-size: 14px;
              font-family: "Source Sans Pro";
              .exam-detail-label {
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: #fe5000;
                margin-left: 3px;
              }
              // .visular-icon{
              // }
            }
          }

          .exam-footerbott {
            padding: 0;
          }
          .exam-hr-content {
            background-color: #bdbdbd;
            margin: 14px 0;
            opacity: 1;
          }
          .exam-action-content {
            display: flex;
            justify-content: space-between;
            .exam-action-label {
              font-family: "Source Sans Pro";
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: #fe5000;
              text-decoration: none;
              .sub-icon {
                height: 24px;
                width: 24px;
                padding-right: 0;
                margin-left: 5px;

                //padding-left: 5px;
              }
            }
          }
        }
      }

      .exam-pedidos-list-content {
        overflow-y: auto;
        height: calc(100vh - 510px);
        padding-right: 1rem;
        background-color: #ffffff;
        background: #ffffff;
        @include customscroll();
        margin-top: 20px;
        // @media (max-width: 479px) {

        //   margin-top: 20px;
        //   height: calc(100vh - 375px);
        //   padding-right: 0;
        // }

        .exam-pedidos-card-content {
          margin-top: 0;
          padding: 20px;
          border: 1px solid #f6f1ee;
          box-shadow: none;
          margin-bottom: 15px;
          border-radius: 12px;
          background: #f6f1ee;

          .exam-pedidos-content-box {
            display: flex;
            width: 100%;

            .exam-pedidos-img {
              filter: invert(95%) sepia(8%) saturate(10%) hue-rotate(27deg)
                brightness(91%) contrast(86%);
            }
            .exam-pedidos-box {
              width: 100%;
              margin: 0px 0px 0 20px;
            }
            .exam-pedidos-detail-txt {
              color: #262626;
              font-size: 18px;
            }
            .exam-pedidos-detail-content {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 0;
              font-size: 12px;

              .exam-pedidos-date {
                color: #757575;
                font-size: 14px;
              }

              .exam-pedidos-detail-label {
                color: #828282;
                font-size: 14px;
                margin-left: 3px;
              }
            }
          }

          .exam-pedidos-footerbott {
            padding: 10px 0 0px 0;
          }
          .exam-pedidos-hr-content {
            background-color: rgba(196, 196, 196, 0.31);
          }
          .exam-pedidos-action-content {
            display: flex;
            justify-content: space-between;
            .exam-pedidos-action-label {
              color: #d1d1d1;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
.exam-visuasls {
  display: flex;
  align-items: center;
  cursor: pointer;
}
