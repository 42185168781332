@mixin customscroll {
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #efefef;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #BDBDBD;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #BDBDBD;
    border-radius: 10px;
  }
}


.history-mainwrapper {
  display: flex;
  background-color: #ffffff;
  background: #ffffff;
  height: calc(100vh - 183px);
  width: 100%;
  overflow-y: auto;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 0px;
    padding-top: 0px;
  }
  .rightcontent {
    padding-left: 0;
    padding-top: 2px;
    padding-bottom: 0 !important;
    width: 100%;
    .header-content {
      width: 100%;
    
      margin: 16px 0;
      line-height: normal;
      display: block;
      text-align: left;
      .topsubtitle{
        color: #FE5000;
      font-size: 16px;
      font-weight: 700;
      display: block;
      margin-bottom: 1rem;
      font-family: 'Roboto', sans-serif;
      }
      .history-title {
        font-size: 30px;
        color: #FFAD00;
        font-weight: 400;
      }
      .history-main-title-arrow-left {
        width: 24px;
        height: 24px;
        float: left;
      }
      .history-topsubtitle-mobile {
        color: #FE5000;
        font-size: 16px;
        font-weight: bold;
        display: block;
        margin-bottom: 1rem;
        text-align: center;
        justify-content: center;
      }
    }

    .lefthistoryimg{
      max-width: 100%;
    }
   
    .history-layout-view {
      max-width: 450px;
      // margin: 0 auto;
      // min-height: 540px;
      @media (min-width: 768px) {
        margin-left: 2rem;
      }
     
      .title-img-view {
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 0 20px 16px;
        /* filter: invert(18%) sepia(44%) saturate(3971%) hue-rotate(297deg)
          brightness(89%) contrast(91%); */
      }
    
    
    
      .header-tab-view {
      
        ion-segment-button {
          --indicator-box-shadow: transparent!important;
          --background-checked: var(--ion-color-success);
          --background: white;
          --indicator-color : transparent!important;
          --padding-start: 0px;
          --padding-end: 0px;
          --margin-end:0px;
          --margin-start:0px;
          --border-radius: 0px;
          background: white !important;
          padding: 0.2rem;
          border-bottom: 1px solid #999998;
            &.segment-button-checked {
                --indicator-color: #FFAD00;
                border-bottom: 3px solid #FFAD00;
                ion-label {
                    color: #FFAD00;
                    font-size: 16px;
                    // font-weight: 700;
                    text-transform: capitalize;
                  }
            }
          --indicator-color: rgba(51, 195, 240, 1);
        }
        ion-label {
          color: #999998;
          font-size: 16px;
          font-weight: 400;
          font-family: 'Source Sans Pro', sans-serif;
          text-transform: capitalize;
        //   border-bottom: 2px solid;
          margin: 0 20px;
        }
      }
    
      .history-footer-view {
        position: fixed;
      }
    
      .nodata-container{
        height: calc(100vh - 387px);
        margin-top: 20px;
        padding: 0 2rem;
        align-items: flex-start;
        justify-content: center;
        flex-direction: row;
      }
      .history-list-view {
        text-align: -webkit-center;
        justify-content: center;
        justify-self: center;
        justify-items: center;
        overflow-y: auto;
        height: calc(100vh - 387px);
        overflow-x: hidden !important;
        margin-top: 20px;
        padding: 0 2rem;
        background: transparent;

    
        @include customscroll();
        // margin-top: 44px; 
        @media (max-width: 479px) {
          margin-top: 20px;
          height: calc(100vh - 375px);
          overflow-x: hidden !important;
        }

        @media (max-device-width: 1024px) {
          height: calc(100vh - 387px);
        }
        @media (max-device-width: 640px) {
          height: calc(100vh - 387px);
        }
        @media (max-device-width: 540px) {
          height: calc(100vh - 300px);
        }
        @media (max-device-width: 414px) {
          height: calc(100vh - 306px);
        }
        @media (max-device-width: 375px) {
          height: calc(100vh - 316px);
        }
        @media (max-device-width: 360px) {
          height: calc(100vh - 300px);
        }
        @media (max-device-width: 320px) {
          height: calc(100vh - 309px);
        }
        @media (max-device-width: 280px) {
          height: calc(100vh - 310px);
        }
          
        .txtPolicyContext {
          display: flex;
          flex-wrap: wrap;
          word-break: break-word;
          height: calc(100vh - 430px);
          overflow-y: auto;
          justify-content: center;
          text-align: -webkit-center;
          justify-self: center;
          justify-items: center;
          margin-top: 30px;
    
          @media (max-device-width: 1024px) {
            height: calc(100vh - 260px);
          }
          @media (max-device-width: 640px) {
            height: calc(100vh - 260px);
          }
          @media (max-device-width: 540px) {
            height: calc(100vh - 260px);
          }
          @media (max-device-width: 414px) {
            height: calc(100vh - 249px);
          }
          @media (max-device-width: 375px) {
            height: calc(100vh - 397px);
          }
          @media (max-device-width: 320px) {
            height: calc(100vh - 296px);
          }
          @media (max-device-width: 280px) {
            height: calc(100vh - 260px);
          }
        }
        .box-section {
          padding: 0;
          margin: -6px;
        }
        .tab-content-box-one {
          border: 1px solid rgba(0, 0, 0, 0.25) !important;
        }
        .tab-content-box-two-recent {
          border: 1px solid #c7c8cc !important;
        }
        .tab-content-box-two-old {
            opacity: 0.65;
          border: 1px solid #c7c8cc !important;
          ion-label{
              color:#ACACAC !important;
              h3{
                  color: #ACACAC !important;
              }
          }
        }
    
        .tab-content-box {
          // width: 417px;
          box-shadow: none;
          // border-radius: 12px;
          padding: 12px 12px 12px 12px;
          margin: 10px 0;
          background: #F6F1EE;
          border: 1px solid #DFDFDF !important;
          box-sizing: border-box;
          border-radius: 10px;
    
            
          .persencial-history-header {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin: 0 0 0 10px;
            img {
              margin-right: 10px;
            }
          }
          .viewPresencial-history {
            background-color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            font-family: Inter;
            font-style: normal;
            align-items: center;
            text-align: center;
            color: #000000;
          }
          .profile-img {
            margin: 0;
            background: #FE5000;
            padding: 5px 2px 0 2px;
          }
          ion-item {
            --background: transparent;
          }
          ion-label {
            margin-left: 8px;
            color: #999998;
            font-family: 'Roboto', sans-serif; font-weight: 400;
            h3 {
              font-size: 20px;
              font-weight: 600;
              line-height: 16px;
              // font-family: "Inter", sans-serif;
              color: #FE5000;
            }
            .past-speciality{
              color:#ACACAC !important
            }
            .future-speciality {
              font-size: 16px;
              font-weight: 700;
              line-height: normal;
              // font-family: "Inter", sans-serif;
              color: #FFAD00;
              white-space: normal;
              padding-top: 8px;
              font-family: 'Roboto', sans-serif;
            }
            &.sub-label {
              margin-left: 0;
            //   margin-top: 5px;
              display: inline-block;
              vertical-align: middle;
              font-size: 14px;
              font-weight: 500;
              line-height: 14px;
              color: #FE5000;
              font-family: 'Roboto', sans-serif;
              // font-family: "Inter", sans-serif;
            }
          }
    
          .tab-content-bottom-box {
            display: flex;
            margin-top: 5px;
            justify-content:space-between;
            padding: 0 10px;
            > div {
            
              display: flex;
              align-items: center;
            }
          }
    
          .Icon-img {
         padding-right: 8px;
    
           
          }
          .logo-wrapper {
            /* filter: invert(18%) sepia(44%) saturate(3971%) hue-rotate(297deg)
            brightness(89%) contrast(91%); */
          }
    
          .custom-success-btn {
            margin-top: 20px;
          }
    
          .agendadas-btn-view {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
          }
    
          .iniciar-agendadas-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
            // width: 330px;
            height: 40px;
            background: #FE5000;
            margin: 15px 0;
            border-radius: 10px;
            font-weight: 400;
            text-transform: none;
            --ion-color-base: #FE5000 !important;
            --background-activated: #FE5000 !important;
            --background-focused: #FE5000 !important;
            --border-color: transparent !important;
            --border-width: 0;
            --color: white;
            --background: #FE5000;
            --box-shadow: none;
          }
    
          .iniciar-agenda-cancel {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            height: 40px;
            margin: 15px 0;
            background: white;
            border-radius: 10px;
            border: 1px solid;
            text-transform: none;
            --background-activated: transparent !important;
            --color-activated: #C06666 !important;
            // --ion-color-base: #FE5000 !important;
            // --background-activated: #FE5000 !important;
            // --background-focused: #FE5000 !important;
            --border-color: #C06666 !important;
            --border-width: 0;
            --color: #C06666;
            --box-shadow: none;
            // @media (max-width: 767px) {
            //   margin-top: 0;
            // }
          }
        }
      }
      .history-nodata-list-view {
        margin-top: 6px;
        text-align: -webkit-center;
        justify-content: center;
        justify-self: center;
        justify-items: center;
        overflow-y: auto;
        height: calc(100vh - 387px);
        overflow-x: hidden;
        @include customscroll();
        margin-top: 44px;
        @media (max-width: 479px) {
          height: calc(100vh - 520px) !important;
        }
    
        .txtPolicyContext {
          display: flex;
          flex-wrap: wrap;
          word-break: break-word;
          height: calc(100vh - 430px);
          overflow-y: auto;
          justify-content: center;
          text-align: -webkit-center;
          justify-self: center;
          justify-items: center;
          margin-top: 30px;
    
          @media (max-device-width: 1024px) {
            height: calc(100vh - 260px);
          }
          @media (max-device-width: 640px) {
            height: calc(100vh - 260px);
          }
          @media (max-device-width: 540px) {
            height: calc(100vh - 260px);
          }
          @media (max-device-width: 414px) {
            height: calc(100vh - 249px);
          }
          @media (max-device-width: 375px) {
            height: calc(100vh - 397px);
          }
          @media (max-device-width: 320px) {
            height: calc(100vh - 296px);
          }
          @media (max-device-width: 280px) {
            height: calc(100vh - 260px);
          }
        }
        .box-section {
          padding: 0;
          margin: -6px;
        }
        .tab-content-box-one {
          border: 1px solid rgba(0, 0, 0, 0.25) !important;
        }
        .tab-content-box-two {
          border: 1px solid #c7c8cc !important;
        }
    
        .tab-content-box {
          width: 417px;
          box-shadow: none;
          border-radius: 12px;
          padding: 12px 12px 12px 12px;
          margin: 10px 0;
          background-color: #fcfcfc;
        
          .persencial-history-header {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin: 0 0 0 10px;
            img {
              margin-right: 10px;
            }
          }
          .viewPresencial-history {
            background-color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            font-family: Inter;
            font-style: normal;
            align-items: center;
            text-align: center;
            color: #000000;
          }
          .profile-img {
            margin: 0;
          }
          ion-item {
            --background: transparent;
          }
          ion-label {
            margin-left: 8px;
            h3 {
              font-size: 16px;
              font-weight: 400;
              line-height: 16px;
              font-family: "Inter", sans-serif;
              color: #000;
            }
            p {
              font-size: 12px;
              font-weight: 400;
              line-height: 12px;
              font-family: "Inter", sans-serif;
              color: #8d8d8d;
              white-space: normal;
              padding-top: 8px;
            }
            &.sub-label {
              margin-left: 0;
              margin-top: 5px;
              display: inline-block;
              vertical-align: middle;
              font-size: 14px;
              font-weight: 500;
              line-height: 14px;
              color: #9a9a9a;
              font-family: "Inter", sans-serif;
              font-style: normal;
              color: #000000;
            }
          }
    
          .tab-content-bottom-box {
            display: flex;
            margin-top: 5px;
            > div {
              margin-right: 1.5em;
              @media (max-width: 400px) {
                margin-right: 10px;
              }
            }
          }
    
          .Icon-img {
            display: inline-block;
            vertical-align: text-top;
            margin-left: 50px;
            padding-right: 6px;
    
            @media (max-device-width: 1024px) {
              margin-left: 61px;
            }
            @media (max-device-width: 640px) {
              margin-left: 50px;
            }
            @media (max-device-width: 540px) {
              margin-left: 50px;
            }
            @media (max-device-width: 414px) {
              margin-left: 38px;
            }
            @media (max-device-width: 375px) {
              margin-left: 40px;
            }
            @media (max-device-width: 360px) {
              margin-left: 22px;
            }
            @media (max-device-width: 320px) {
              margin-left: 0px;
            }
            @media (max-device-width: 280px) {
              margin-left: 5px;
            }
          }
          .logo-wrapper {
            filter: invert(18%) sepia(44%) saturate(3971%) hue-rotate(297deg)
              brightness(89%) contrast(91%);
          }
    
          .custom-success-btn {
            margin-top: 20px;
          }
    
          .agendadas-btn-view {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
          }
    
          .iniciar-agendadas-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
            // width: 330px;
            height: 40px;
            margin: 15px 0;
            background: #FE5000;
            border-radius: 10px;
            font-weight: 700;
            text-transform: none;
            --ion-color-base: #FE5000 !important;
            --background-activated: #FE5000 !important;
            --background-focused: #FE5000 !important;
            --border-color: transparent !important;
            --border-width: 0;
            --color: white;
            --background: #FE5000;
            --box-shadow: none;
          }
    
          .iniciar-agenda-cancel {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            margin: 15px 0;
            height: 40px;
            background: white;
            border-radius: 10px;
            border: 1px solid;
            text-transform: none;
            --background-activated: transparent !important;
            --color-activated: #FE5000 !important;
            // --ion-color-base: #FE5000 !important;
            // --background-activated: #FE5000 !important;
            // --background-focused: #FE5000 !important;
            // --border-color: #FE5000 !important;
            --border-width: 0;
            --color: #FE5000;
            --box-shadow: none;
          }
        }
      }
      @media only screen and (min-width: 600px) {
        .hide-on-desktop,
        * [aria-labelledby="hide-on-desktop"] {
          display: none;
          max-height: 0;
          overflow: hidden;
        }
      }
    
      .history-agendadas-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 330px;
        height: 44px;
        background: #ffffff;
        border-radius: 50px;
        text-transform: none;
        border: 1.5px solid #FE5000;
        box-sizing: border-box;
        border-radius: 50px;
        margin-bottom: 22px;
        margin-top: 22px;
        --ion-color-base: #FE5000 !important;
        --background-activated: #FE5000 !important;
        --background-focused: #FE5000 !important;
        --border-color: transparent !important;
        --border-width: 0;
        --color: #FE5000;
        --background: #ffffff;
        --box-shadow: none;
      }
    }
  }
}

.logout-modal {
  .modal-wrapper {
    background-color: white;
    color: #000;
    border-radius: 12px;
    width: 328px;
    height: auto;
    @media screen and (max-width: 414px) {
      width: 280px !important;
    }

    .ion-page {
      justify-content: center;
      text-align: center;
      padding: 20px;
      position: relative;
      contain: content;
    }

    .logout-title {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #000;
      padding: 15px 0 30px;
    }

    .custom-logout-btn {
      height: 44px;
      margin: 0;
      --ion-color-base: #FE5000 !important;
      margin-bottom: 20px;
    }

    .custom-logout-cancel-btn {
      text-align: center;
      color: #FE5000;
      margin-top: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 132%;
      margin-top: 20px;
    }
  }
}


  .reschedule-btn{
   
        margin-top: 2rem !important;
          .round{
            border-radius:10px!important;
          }
        --ion-color-base:  transparent !important;
        --background-activated:  transparent !important;
        --background-focused: transparent !important;        
        --border-width: 1px !important;
        --border-color: #FFAD00!important;
        --border-style: solid !important;
        --color: #FFAD00;
        --background:  transparent !important;
        --box-shadow: none;
        margin-top: 14px;
        text-transform: none;
        --border-radius: 10px !important;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        height: 52px;
        
      
}
  