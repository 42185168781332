.footermobile {
  background-color: #fff;

  //   ion-segment-button{
  //       flex-direction: column;
  //   }
  .topsegment{
    background-color: #F6F1EE;
    ion-segment{
        background: transparent;
    }
    ion-segment-button{
      --indicator-box-shadow: transparent!important;
      --background-checked: transparent !important;
      --color-checked: transparent !important;
      --indicator-color : transparent!important;
      }
  }
  .mainicon {
    min-width: auto;
    max-width: none;
    height: auto;
    min-height: auto;
    flex-direction: column;
    white-space: normal;
    flex-wrap: wrap;
    line-height: normal;
    flex: 1;
    --indicator-color: transparent !important;
    --indicator-color-checked: transparent !important;
    .icons {
        white-space: normal;
        /* clear: both; */
        position: absolute;
        border: #BDBDBD solid 1px;
        border-radius: 150px;
        width: 70px;
        height: 70px;
        object-fit: contain;
        padding: 15px;
        background: #fff;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        @media (max-width: 360px) {
          width: 60px;
          height: 60px;
        }
    }
    .labellink {
        display: block;
        width: 100%;
        white-space: normal;
        margin: 5rem 0 0 0;
        padding: 0;
        color: #FE5000;
        font-size: 13px;
        font-weight: 400;
        @media (max-width: 360px) {
          font-size: 13px;
          line-height: normal;  margin: 4.3rem 0 0 0;
        }
    }
  }

  .socialicons {
    ion-img {
      margin: 0 15px; cursor: pointer; 
      @media (max-width: 320px) {
        width: 28px;height: 28px;
      }
    }
    .logoans {
      margin-right: 15px;
    }
  }
  .textright {
    color: #999998;
    margin-left: 0;
    font-size: 12px;
    float: right;
    margin-top: 9px;
    font-family: 'Roboto', sans-serif; font-weight: 500;
    
  }
  .logoans{
    max-width: 130px; margin-left: auto;
  }
  .logotext {
    color: #999998;
    font-size: 12px;
    font-weight: bold;
    
  }
}
