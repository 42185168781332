@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,700;0,900;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600;700&family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');

// font-family: 'Roboto', sans-serif;
// font-family: 'Source Sans Pro', sans-serif;

body{
    font-family: 'Open Sans', sans-serif !important; 
    font-weight: 400;
}


*, *::before, *::after{
    font-family: 'Open Sans', sans-serif;
}
.container{
    max-width: 1320px !important;
}