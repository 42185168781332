.appointment-wapper {
    display: flex;
    margin: 50px 0 0 0 !important;
    .MuiFormHelperText-root {
      color: red !important;
    }
    .calender-wapper {
      //margin: 10px 40px;
      margin: 0;
      @media (max-width: 767px) {
        display: block;
        margin: 0;
      }
      .mobile-calander-section {
        margin-top: 1rem;
        .summary-picture-m {
          width: 60px;
          height: 60px;
          background-color: #FE5000;
          padding: 8px 4px 0 4px;
        }
        .summary-name {
          color: #FE5000;
          font-size: 24px;
          font-weight: 400;
          @media (max-width: 1024px) {
            font-weight: bold;
          }
        }
        .summary-dra-name {
          color: #FE5000;
          font-size: 16px;
          font-weight: 700;
          white-space: normal;
          font-family: "Roboto", sans-serif;
          @media (max-width: 1024px) {
            color: #555555;
            font-weight: normal;
          }
        }
      }
      .appointment-header {
        @media (max-width: 1024px) {
          color: #FE5000;
          font-size: 18px;
          font-weight: 700;
          justify-content: center;
          font-family: "Source Sans Pro", sans-serif;
        }
      }
  
      .time-slot-main .time-slot-div {
        min-height: 95px;
      }
      .time-slot-main .time-slot-div {
        min-height: 95px;
      }
      .maincircle {
        min-height: 95px;
        .time-slot-wapper {
          display: flex;
          display: -webkit-box;
          overflow-x: auto;
          width: 100%;
          margin-bottom: 20px;
          /* white-space: nowrap; */
          /* touch-action: auto; */
          position: absolute;
          left: 0;
          top: auto;
          bottom: auto;
          .time-slot {
            color: #404040;
            text-align: center;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14.42px;
            border: 1px solid #c7c8cc;
            box-sizing: border-box;
            margin: 0 4px !important;
            border-radius: 60px;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            line-height: 18px;
            color: #404040;
            &.active {
              background-color:#FFAD00;
              color: #fff;
              font-weight: 600;
              border-color:#FFAD00;
            }
          }
        }
      }
      .buttonbottom {
        margin: 0 -2rem;
        @media (max-width: 1024px) {
          margin: 0;
          padding: 0;
          input {
            &:first-child {
              margin-bottom: 1.5rem;
            }
          }
          .professional-btn.inactive {
            background: #F6F1EE !important;
            color: #999998;
            font-size: 17px;
            font-weight: 400;
            margin-bottom: 1.5rem;
            border-radius: 10px;
            margin-top: 10px;
          }
        }
        input {
          margin-bottom: 0;
        }
      }
    }
    .react-calendar__month-view__weekdays {
     font-weight: normal;
      abbr {
        text-decoration: none !important;
        color: #999998;
      }
    }
    .react-calendar__navigation button[disabled] {
      background-color: #ffffff;
    }
    .react-calendar {
      .react-calendar__navigation__label__labelText {
        color: black !important;
      }
      .highlight {
        color: black;
        font-weight: 600;
      }
      .nonHighlight {
        color: grey;
        pointer-events: none;
      }
      .react-calendar__tile:disabled {
        color: grey;
      }
      border-radius: 10px !important;
      width: 270px;
      border: 0.719512px solid #c7c8cc !important;
      box-sizing: border-box;
      border-radius: 8px;
      border: none;
      margin-top: 27px;
      margin-left: 3rem;
      @media (max-width: 1024px) {
        margin-left: auto;
        margin-right: auto;
        max-width: 350px;
        width: auto;
      }
      .react-calendar__navigation {
        .react-calendar__navigation__arrow {
          border-radius: 10px;
          @media (max-width: 1024px) {
            color: #FFAD00;
            font-size: 30px;
          }
        }
        .react-calendar__navigation__next2-button {
          @media (max-width: 1024px) {
            display: none;
          }
        }
        .react-calendar__navigation__prev2-button {
          @media (max-width: 1024px) {
            display: none;
          }
        }
      }
      .react-calendar__tile {
        padding: 1em 0.5em;
        &.react-calendar__month-view__days__day--weekend {
          color: grey;
          pointer-events: none;
          font-weight: normal;
        }
      }
    }
    .react-calendar__tile--active {
      background: #FFAD00 !important;
      border-radius: 100%;
      color: #fff !important;
    }
    .appointment-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px;
      // margin: 0;
      // margin-left: -5rem;
      margin-top: 0.6rem;
    }
    .react-calendar__tile:disabled {
      background-color: transparent;
    }
    .react-calendar__tile--now {
      background: transparent;
    }
    .appointment-header-span {
      // padding: 12px;
      // border-radius: 56%;
      // background: #75BAE7;
      // margin-right: 6px;
      // font-size: 22px;
      // font-weight: 700;
      // color: white;
      border-radius: 56%;
      background: #75bae7;
      margin-right: 6px;
      font-size: 20px;
      font-weight: 700;
      color: white;
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .time-slot-wapper {
      position: relative;
      display: grid;
      grid-template-areas: "a a a a";
      gap: 8px;
      padding-top: 57px;
      //grid-auto-columns: 90px;
  
      .left-arrow {
        position: absolute;
  
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      .right-arrow {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
  
      .time-slot {
        text-align: center;
        padding: 0;
        border: 1px solid rgba(199, 163, 163, 0.38);
        border-radius: 50px;
        /* width: 27px; */
        /* height: 51px; */
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        margin: 0 0 6px 0;
        width: 52px;
        height: 52px;
        font-weight: 400;
        color: #404040;
        &.active {
          background-color: #FFAD00;
          color: #fff;
          font-weight: 600;
          border-color: #FFAD00;
        }
      }
    }
    .contact-details-main {
      @media (max-width: 767px) {
        display: block;
        // margin: auto auto 33.84px auto !important;
      }
    }
    .text-field {
      margin-top: 0;
      min-width: 250px;
      @media (max-width: 1200px) {
        min-width: 100%;
      }
      @media (max-width: 360px) {
        min-width: 300px;
      }
    }
    // .text-field-wapper{
    //     display:flex;
    //     justify-content: center;
    // }
    .text-input-header {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color:#FE5000;
      padding-left: 20px;
      margin-bottom: 5px;
      .errormessage {
        color: #392A24;
        font-style: italic;
        font-weight: 400;
        margin-left: 4px;
      }
      .errormessage-msg {
        color: #C06666;
        font-style: italic;
        font-weight: 400;
        margin-left: 4px;
      }
    }
    .text-feild-first {
      margin: 0 0 20px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 0px solid #999998;
      box-sizing: border-box;
      border-radius: 10px;
      padding-left: 0;
      padding-right: 0px;
      padding-top: 8px;
      padding-bottom: 8px;
      text-decoration: none;
  
      .MuiInputBase {
        color: #5f5f5f;
      }
      .text-edit-icon {
        width: 18px;
        height: 18px;
        padding-bottom: 0;
        margin-right: 12px;
      }
    }
    .text-feild-aliment {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #999998;
      border-radius: 10px;
      box-sizing: border-box;
      background: transparent;
      border-radius: 12px;
      padding-left: 18px;
      padding-right: 5px;
      padding-top: 8px;
      padding-bottom: 8px;
      text-decoration: none;
      // color: #5F5F5F;
      height: 48px;
      &.telphonenumber {
        position: relative;
        .editicondiv {
          position: absolute;
          right: 6px;
          top: 24px;
        }
      }
      .text-field {
        border: 0;
        outline: none;
        color: black;
        background: transparent; 
      }
      .MuiInputBase {
        color: #5f5f5f;
      }
      .text-edit-icon {
        width: 18px;
        height: 18px;
        padding-bottom: 0;
        margin-right: 12px;
      }
    }
    .text-edit-icon {
      width: 30px;
      height: 30px;
      padding-bottom: 3px;
    }
    .confirm-btn {
      --background: #a212a0;
      margin: 18px 0 0 0;
      height: 32px;
    }
  
    .time-slot-main {
      margin: 0 59px;
      @media (max-width: 767px) {
        justify-content: center;
        margin: 0 auto 33.84px auto;
      }
    }
    .calender-icon {
      margin: 0 8px 0 0;
      width: 16px;
    }
    .hide-section {
      display: none;
    }
    .all-div-appointment {
      margin: 35px 0 0 0;
    }
    .formik-form {
      margin-top: 33px;
    }
    .professional-wapper {
      width: 100%;
      margin: 0px 16px 0px 6px;
      .date-time-main {
        display: flex;
        // justify-content: space-between;
        margin-top: 0;
        .mainlistcard {
          white-space: normal;
          overflow-x: visible;
          .professional-card {
            margin-right: 0 !important;
            border: 0;
            padding: 0 !important;
            margin-bottom: 0 !important;
            width: 300px !important;
            ion-label {
              color: #FE5000;
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 10px;
              font-family: "Source Sans Pro", sans-serif;
            }
          }
        }
      }
      .active {
        background: rgba(255, 173, 0, 0.15);
        // border-color: transparent !important;
        border: 0px solid #c7c8cc !important;
        .professional-name {
          color: #000 !important;
        }
        .professional-crp {
          color: #000 !important;
        }
      }
      .professional-header {
        font-family: Source Sans Pro;
        font-weight: 600;
        font-size: 20px;
        align-items: center;
        text-align: center;
        color: #5f5f5f;
        margin-bottom: 10px;
        @media (max-width: 1024px) {
          margin-top: 10px;
          ion-label {
            color: #392a24;
            font-weight: 400;
            font-family: "Roboto", sans-serif;
          }
        }
      }
      .professional-card {
        display: flex;
        align-items: center;
        border: 1px solid #F6F1EE;
        border-radius: 8.5px;
        margin-bottom: 21px;
        .professional-picture {
          height: 65px;
          width: 65px;
          margin: 10px 15px;
          border-radius: 100%;
          background: #FE5000;
          padding: 8px 2px 0 2px;
        }
        .no-professional-picture {
          height: 65px;
          width: 65px;
          margin: 10px 15px;
          border-radius: 100%;
        }
        .professional-name {
          font-weight: bold;
          font-size: 16.1768px;
          color: #646464;
          @media (max-width: 1024px) {
            font-family: "Roboto", sans-serif;
            color: #FE5000;
            font-weight: 700;
          }
        }
        .professional-crp {
          font-size: 14px;
          color: #FFAD00;
        }
      }
    }
  
    .summary-wapper {
      width: 100%;
      margin: 0px 16px 0px 6px;
      @media (max-width: 1024px) {
        margin: 0;
      }
      .summary-card {
        border: 1.15549px solid #8f216c;
        border-radius: 4px;
        margin-bottom: 21px;
        @media (max-width: 1024px) {
          background: #F6F1EE;
          border: 0px solid #DFDFDF;
          box-sizing: border-box;
          box-shadow: 0px 12px 20px rgba(61, 98, 103, 0.1);
          border-radius: 10px;
          /* text-align: center; */
          margin-bottom: 2rem;
        }
        .summary-card-inner {
          display: flex;
          align-items: center;
        }
        .summary-card-inner-m {
          align-items: center;
          text-align: center;
          @media (max-width: 1024px) {
            text-align: center;
          }
          .departement-text {
            // color: #7d54b9;
            // font-size: 24px;
            // border-bottom: #7d54b9 solid 1px;
            // max-width: 300px;
            // text-align: center;
            // margin-bottom: 2rem;
            // margin-top: 2rem;
            // font-weight: bold;
            // padding-bottom: 0.7rem;
            // color: #7d54b9;
            font-size: 24px;
            border-bottom: #FE5000 solid 1px;
  
            text-align: center;
            margin-bottom: 2rem;
            margin-top: 0;
            font-weight: 600;
            padding-bottom: 1.3rem;
            margin-left: -20px;
            margin-right: -20px;
            color: #FE5000;
            background: none;
            border-radius: 0;
            cursor: pointer;
            @media (max-width: 1024px) {
              font-size: 24px;
              border-bottom: none;
              text-align: center;
              margin-bottom: 9px;
              margin-top: 0;
              font-weight: 400;
              padding-bottom: 0;
              margin-left: 0;
              margin-right: 0;
              color: #FE5000;
              background: none;
              border-radius: 0;
              text-align: left;
              min-width: auto;
              justify-content: flex-start;
            }
          }
          .especialista-div {
            margin-top: 20px;
            @media (max-width: 767px) {
              margin-top: 0;
              text-align: center;
              margin-bottom: 10px;
            }
          }
        }
        .summary-profile-div {
          display: flex;
          justify-content: left;
          align-items: center;
          flex-direction: column;
          @media (max-width: 767px) {
            justify-content: center;
          }
        }
        .summary-picture {
          height: 65px;
          width: 65px;
          margin: 20px;
          border-radius: 100%;
        }
        .summary-picture-m {
          height: 65px;
          width: 65px;
          margin: 0 auto 14px;
          border-radius: 100%;
          background: #FE5000;
          padding: 8px 3px 0 3px;
        }
        .summary-name {
          font-weight: bold;
          font-size: 17.1768px;
          color: #33c3f0;
          margin-bottom: 5px;
        }
        .summary-crp {
          font-size: 14px;
          color: #646464;
        }
        .summary-date-time {
          font-size: 24px;
          justify-content: center;
          /* display: flex; */
          color: #231f20;
          margin: 7px 0;
          padding: 0;
          box-sizing: border-box;
          @media (max-width: 767px) {
            justify-content: center;
          }
        }
        .summary-edit {
          font-size: 12px;
          /* line-height: 12px; */
          /* display: flex; */
          /* align-items: center; */
          // color: #3e3e40;
          padding: 6px 0 0 0;
          font-weight: 700;
          color: #392a24;
          .text-pointer{
            cursor: pointer;
          }
          @media (max-width: 767px) {
            padding: 6px 0 0 15px;
          }
        }
      }
      .summary-conf-btn {
        width: 100%;
        background: #045e96;
        border-radius: 4px;
        border: 0;
        color: #fff;
        height: 44px;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 25px;
        text-align: center;
      }
      .summary-cancel-btn {
        width: 100%;
        height: 44px;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 25px;
        text-align: center;
        border: 1px;
        border: 1.5px solid #ce0017;
        box-sizing: border-box;
        border-radius: 4px;
        color: #ce0017;
        cursor: pointer;
      }
    }
  }
  .selected-department {
    color: #8f216c;
    font-size: 18px;
  }
  .selected-doctor {
    color: #0b9dd6;
    font-size: 24px;
    font-weight: bold;
  }
  .MuiInputBase-root input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #404040;
    opacity: 1; /* Firefox */
  }
  
  @media (max-width: 1024px) {
    .appointment-mobile {
      padding-left: 0px;
      padding-top: 0px;
      display: block;
    }
    .all-div-appointment {
      // margin-bottom: 180px;
    }
    .appointment-wapper {
      margin: 0 !important;
      .appointment-header {
        justify-content: center;
        // font-weight: 700;
      }
      .time-slot-wapper {
        padding-top: 21px;
        .left-arrow,
        .right-arrow {
          top: 54%;
        }
      }
      .time-slot-main {
        margin: 0 auto;
      }
      .contact-details-main {
        .appointment-header {
          font-weight: 700;
        }
      }
    }
  }
  .success-popup .img {
    margin-bottom: 10px;
    width: auto !important;
  }
  .success-popup .ion-page {
    justify-content: center !important;
    align-items: center;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    contain: none;
    width: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    overflow: visible;
    & > div {
      text-align: left;
      & > div:first-child {
        color: #ff8574;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  .success-popup .ion-page p {
    margin-top: 22px !important;
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #0b9dd6;
  }
  .success-popup .modal-wrapper {
    /* width: 366px !important; */
    height: auto !important;
    text-align: center;
    box-shadow: none;
    border-radius: 4px;
    position: absolute;
    bottom: auto;
    top: auto;
    width: auto;
    padding: 2rem;
    border-radius: 20px;
    background: white;
    @media (max-width: 767px) {
      padding: 1rem;
      border-radius: 20px;
      max-width: 310px;
    }
    .mainmodalbox {
      .success-model-img {
        max-width: 60px;
        margin: 0 auto 20px;
      }
      .modaltitel {
        color: #FFAD00;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
      }
      .modaldesc {
        color: #999998;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
  
  .nostaff-popup .img {
    margin-bottom: 10px;
    width: auto !important;
  }
  .nostaff-popup .ion-page {
    justify-content: center !important;
    align-items: center;
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    contain: none;
    width: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    overflow: visible;
    & > div {
      text-align: left;
      & > div:first-child {
        color: #ff8574;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  .nostaff-popup .ion-page p {
    margin-top: 22px !important;
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #0b9dd6;
  }
  .nostaff-popup .modal-wrapper {
    width: 366px !important;
    height: auto !important;
    text-align: center;
    box-shadow: none;
    border-radius: 4px;
    position: absolute;
    bottom: auto;
    top: auto;
    width: auto;
    padding: 2rem;
    border-radius: 20px;
    background: white;
    @media (max-width: 767px) {
      padding: 1rem 2rem;
      border-radius: 20px;
      max-width: 310px;
    }
    .mainmodalbox {
      .success-model-img {
        max-width: 60px;
        margin: 0 auto 1rem;
      }
      .modaltitel {
        color: #FE5000;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        display: block;
        font-family: "Roboto", sans-serif;
        margin-bottom: 1rem;
      }
      .modaldesc {
        color: #392a24;
        font-size: 15px;
        text-align: center;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        display: block;
      }
    }
  }
  
  .cancel-pop .modal-wapper {
    width: 328px !important;
    height: 294px !important;
    background: white;
  }
  .cancel-pop .ion-page {
    justify-content: center !important;
    align-items: center;
    background: white;
  }
  .cancel-pop .img {
    margin-bottom: 10px;
    width: auto !important;
  }
  .cancel-pop .modal-wrapper {
    height: 294px !important;
    text-align: center;
    box-shadow: none;
    border-radius: 14px;
    position: absolute;
    bottom: 60px;
    @media (max-width: 575px) {
      width: 300px !important;
      height: 280px !important;
    }
    margin-bottom: 10px;
    width: 328px !important;
  }
  .viewModelTitle {
    font-size: 14px;
    color: #2a275e;
    font-weight: 700;
    margin: 25px 20px;
  }
  .cancel-btn {
    min-width: calc(100% - 40px);
    height: 45px;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
    border: 1px;
    cursor: pointer;
    border: 1px solid #C06666;
    box-sizing: border-box;
    border-radius: 50px;
    color: #C06666 !important;
    background: white;
    //margin-bottom: 12px;
    @media (max-width: 575px) {
      min-width: 280px;
    }
  }
  .voltar-btn {
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    background: #FE5000;
    border: 1px solid #FE5000;
    box-sizing: border-box;
    border-radius: 37px;
    width: calc(100% - 40px);
    padding: 8px;
  }
  .app-wrapper {
    margin: 100px 0 0 0 !important;
  }
  .pro-card,
  .summary {
    min-width: 300px;
  }
  .time-slot-div {
    margin: 0 50px 0 0 !important;
    .time-slot-conf-btn {
      background: #3e3e40;
      border-radius: 30px;
      padding: 12px 12px;
  
      height: 44px;
      color: #ffffff;
      width: auto;
      display: inline-block;
      vertical-align: middle;
      min-width: 268px;
      margin-right: 2rem;
      text-align: center;
      margin-top: 40px;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 132%;
      align-items: center;
  
      ion-img {
        vertical-align: middle;
        display: inline-block;
      }
    }
    .disable {
      background: #f4f5f7;
    }
  }
  .calender {
    margin: 0 50px;
  }
  .contact-div {
    margin: 0 50px 0 0;
  }
  
  .mobile-header-wapper {
    width: 100%;
    .icon-div {
      align-items: center;
      justify-content: space-between;
      display: flex;
      margin-top: 20px;
      @media only screen and (max-width: 767px) {
        margin-left: 1rem;
        ion-img{
          position: relative;
          top: 0px;
        }
      }
    }
    .icon-header {
      height: 25px;
      width: 25px;
      color: #929292;
    }
  
    .header-text-div {
      text-align: center;
  
      .header-text {
        font-weight: bold;
        font-size: 24px;
        color: #231f20;
        @media only screen and (max-width: 1024px) {
          font-weight: 700;
          margin-top: -1.5rem;
          font-size: 16px;
          color: #FE5000;
          font-family: "Roboto", sans-serif;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ion-img{
          max-width: 20px;
          margin-right: 8px;
        }
      }
      .specialist-text {
        color: #283084;
        // border-bottom: 1px solid;
        font-weight: bold;
        margin: 0px 53px;
        font-size: 18px;
      }
      .departement-text {
        font-weight: 600;
        font-size: 14.4529px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10.3235px 20.6471px;
        background: #3e3e40;
        border: 1px solid #3e3e40;
        box-sizing: border-box;
        border-radius: 28px;
        margin: 15px 100px;
        color: #ffffff;
        height: 34px;
        cursor: pointer;
        @media only screen and (max-width: 1024px) {
          font-weight: 400;
          font-size: 24px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10.3235px 0;
          background: transparent;
          border: 1px solid #FE5000;
          box-sizing: border-box;
          border-radius: 0;
          margin: 10px 30px 1rem 30px;
          color: #FE5000;
          height: auto;
          letter-spacing: 0;
          border-top: 0;
          border-left: 0;
          border-right: 0;
        }
      }
      .info-text {
        font-family: Source Sans Pro;
        font-size: 13px;
        line-height: 14px;
        color: #646464;
        margin-bottom: 15px;
        .bold-text {
          font-weight: bold;
        }
      }
    }
  }
  .selected-specialist-wapper {
    display: flex;
    align-items: center;
    justify-content: center;
  
    &.constact-spec {
      margin-left: 0;
      & + .appointment-header {
        margin-left: -3rem;
      }
    }
    .selected-specialist-img {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      margin-right: 15px;
    }
    .doctor-department {
      font-size: 14px;
      line-height: 20px;
      color: #646464;
    }
    .doctor-name {
      font-size: 16.1768px;
      line-height: 23px;
      color: #646464;
      font-weight: bold;
    }
  }
  
  .professional-btn {
    width: 100%;
    background: #FE5000;
    border-radius: 10px;
    border: 0;
    color: #fff !important;
    height: 50px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
    &.inactive {
      background: #F2F2F2;
      color: #999998 !important;
      @media only screen and (max-width: 1024px) {
        background: #F6F1EE;
      }
    }
  }
  .professional-btn.canelar {
    background: white;
    border: 1px solid #C06666;
    color: #C06666 !important;
    margin-top: 0 !important;
    // cursor: none !important;
    // pointer-events: none;
    // outline: none;
  }
  // desktopcss
  .toptilediv {
    //   padding: 0 4rem;
    // margin-top: 0.5rem;
    margin: 20px 0 20px 0;
    .consultas-txt {
      margin: 16px 0px 0 0;
      color: #810083;
      font-weight: bold;
      font-size: 25px;
    }
    .subtext {
      color: #FE5000;
      font-size: 16px;
      font-weight: 700;
      display: block;
      width: 100%;
      margin: 0 0 10px 0;
      font-family: "Roboto", sans-serif;
    }
    .maintext {
      color: #FFAD00;
      font-size: 32px;
      font-weight: 700;
      display: block;
      width: 100%;
    }
  
    .especialista-txt {
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 2rem;
      color: #392a24;
      margin-top: 1rem;
      font-family: "Roboto", sans-serif;
    }
  }
  .appointment-wapper.app-wrapper {
    width: 100%;
    //   padding: 0 4rem;
    margin-top: 0 !important;
    flex-direction: column;
  
    .list-div-multispeciality {
      flex: 0 0 auto;
      width: 100%;
      padding: 0;
      margin: 0;
      text-align: left;
      width: auto !important;
      .small-heading {
        color: #392a24;
        margin-bottom: 33px;
        font-size: 20px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        b {
          font-weight: 700;
        }
      }
  
      .mobile-view {
        background: #fff;
        max-width: inherit !important;
        width: 70%;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #7d54b9;
        border-radius: 10px;
        padding: 10px 20px;
        .doctor-continue {
          width: 25%;
          .doctor-continue-btn {
            background-color: #33c3f0 !important;
            box-shadow: none !important;
            border-radius: 10px !important;
            width: 100%;
            border: none;
            padding: 8px;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
          }
        }
        .specialityBtn {
          flex-direction: inherit;
          width: 25%;
          padding: 4px 10px;
          .web-btns {
            margin: 0;
            width: 100%;
          }
          button {
            border: 0.928946px solid rgba(0, 0, 0, 0.25);
            border-radius: 40px;
            color: #000;
            font-weight: normal;
            width: 100%;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 14px;
            margin-bottom: 0px !important;
            text-align: left;
            border: none;
            padding: 0;
          }
        }
      }
    }
    .professional-wapper {
      // flex: 0 0 auto;
      // width: 75%;
      margin: 0;
      // margin-left: 10%;
  
      .selected-department {
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        color: #810083;
        border-bottom: 1px solid #179ace;
        display: inline-block;
        padding-bottom: 12px;
        margin-bottom: 16px;
      }
      .titel-text {
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
        color: #38b4e4;
        margin-bottom: 8px;
      }
      .mainlistcard {
        white-space: normal;
        // overflow-x: auto;
        /* width: 29%; */
        float: left;
        max-width: calc(100% - 300px);
        display: flex;
        flex-wrap: wrap;
        .professional-card {
          flex-direction: column;
          // margin: 0 10px;
          margin-right: 40px;
          min-width: auto;
          /* width: calc(33.3% - 0px); */
          /* flex: 1; */
          flex: 0 0 auto;
          width: 300px;
          padding: 2rem 0;
          .professional-name {
            font-weight: 700;
            font-size: 17.3691px;
            line-height: 22px;
            text-align: center;
            color: #FFAD00;
            font-family: "Roboto", sans-serif;
          }
          .professional-crp {
            font-size: 14px;
            margin-top: 4px;
            line-height: 20px;
            text-align: center;
            color: #392A24;
            font-weight: 600;
          }
          .professional-picture {
            margin-top: 0;
            margin-bottom: 1rem;
            background: #FE5000;
            padding: 8px 3px 0 3px;
          }
          .no-professional-picture {
            margin-top: 0;
            margin-bottom: 1rem;
            background: #7d54b9;
            padding: 15px;
          }
          &:nth-child(3n + 3) {
            margin-right: 0;
          }
        }
      }
      .btn-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 320px;
        input {
          // width: 32%;
          margin-top: 2rem;
        }
      }
    }
  
    /* consulta agendada */
    .consulta-agendada-column {
      float: left;
      width: 53%;
      padding: 0;
      margin-top: 0rem;
      @media (max-width: 1024px) {
        width: 100%;
      }
      &:first-child {
        width: 47%;
        @media (max-width: 1024px) {
          width: 100%;
        }
      }
      .summary-wapper {
        margin: 0;
        max-width: 100%;
        .summary-card {
          margin-bottom: 0;
          padding: 32px;
          box-shadow: none;
          border-radius: 18px;
          .summary-date-time {
            font-weight: 400;
            margin: 0.5rem 0 1rem 0;
          }
          .summary-dra-name {
            margin: 5px 0;
            white-space: normal;
          }
          .summary-edit {
            color: #392a24;
            font-family: "Roboto", sans-serif;
            font-weight: 400;
          }
        }
      }
      .summary-wapper-list-item {
        .history-list-view {
          /* max-width: 545px; */
          margin-left: auto;
          padding: 0 35px 0 12px;
          height: calc(100vh - 450px);
          background: none;
          .box-section {
            margin: 0;
            width: 100%;
            .tab-content-box {
              width: 100%;
              box-shadow: none;
              border-radius: 8px;
              margin-bottom: 18px;
              margin-top: 0;
              .tab-content-box-item {
                .list-item-consulta-name {
                  font-size: 24px;
                }
                .list-item-consulta-time {
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
    .consulta-agendada-row {
      margin-top: -3rem;
      @media (max-width: 767px) {
        margin: 1rem 0 0 0;
        padding: 0 1rem;
      }
      &:after {
        content: "";
        display: table;
        clear: both;
      }
      .mobilebutton {
        --background-activated: #f6f1ee !important;
        --background-focused: #f6f1ee !important;
        --border-width: 1px !important;
        --border-color: #f6f1ee !important;
        --border-style: solid !important;
        // --box-shadow: none !important;
        --background: #f6f1ee !important;
        height: 60px;
        --border-radius: 10px !important;
        margin: 1rem 0 0 0 !important;
        width: 100%;
        box-shadow: 0px 9.71591px 16.1932px rgba(61, 98, 103, 0.1);
        border-radius: 8.09659px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #392a24;
  
        ion-img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
        span {
          text-transform: capitalize;
          color: #5f5f5f;
        }
      }
      .mobile-header-title {
        font-size: 16px;
        font-weight: bold;
        color: #045e96;
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: 1rem;
      }
    }
  
    .summary-wapper {
      width: 100%;
      margin: 0px 16px 0px 6px;
      .summary-card {
        background: #FBFBFB;  
        border: 1px solid #FE5000;
        box-sizing: border-box;
        box-shadow: 0px 12px 20px rgba(61, 98, 103, 0.1);
        border-radius: 10px;
        padding: 15px;
        // border-radius: 4px;
        margin-bottom: 21px;
        @media (max-width: 767px) {
          background: #F6F1EE;
          border: 0px solid #DFDFDF;
          box-sizing: border-box;
          box-shadow: 0px 12px 20px rgba(61, 98, 103, 0.1);
          border-radius: 10px;
          padding: 20px !important; 
          margin-bottom: 2rem;
        }
        .summary-card-inner {
          display: flex;
          align-items: center;
        }
        .summary-card-inner-m {
          align-items: center;
          text-align: left;
          @media (max-width: 767px) {
            text-align: center;
          }
          .summary-profile-div {
            @media (max-width: 1024px) {
              flex-direction: row;
              justify-content: flex-start;
            }
          }
          .especialista-div {
            margin-top: 0px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 152.69%;
            text-align: center;
            letter-spacing: -0.005em;
            color: #392a24;
            @media (max-width: 767px) {
              margin-top: 0;
              text-align: center;
              margin-bottom: 10px;
            }
          }
        }
        .summary-profile-div {
          display: flex;
          justify-content: left;
          align-items: center;
          @media (max-width: 767px) {
            justify-content: center;
          }
        }
        .summary-picture {
          height: 65px;
          width: 65px;
          margin: 20px;
          border-radius: 100%;
        }
        .summary-picture-m {
          height: 60px;
          width: 60px;
          margin: 10px 10px 10px 0;
          border-radius: 100%;
          @media (max-width: 767px) {
            margin-right: 1rem;
          }
        }
        .summary-name {
          // font-family: Open Sans;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 20px;
          // font-family: 'Roboto', sans-serif;
          color: #FFAD00 !important;
          @media (max-width: 1024px) {
            font-size: 16px;
            line-height: 20px;
            // color: #33c3f0;
            text-align: left;
            color: #FE5000;
            font-family: "Roboto", sans-serif;
            font-weight: 700;
          }
        }
        .summary-crm {
          @media (max-width: 1024px) {
            color: #392a24;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            font-family: "Roboto", sans-serif;
          }
        }
        .summary-dra-name {
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #045e96;
          white-space: normal;
        }
        .summary-srmsp-name {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #646464;
        }
        .summary-crp {
          font-size: 14px;
          color: #646464;
        }
        .summary-date-time {
          justify-content: center;
          display: flex;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: Roboto;
          font-style: normal;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: #392A24;
  
          @media (max-width: 1024px) {
            justify-content: flex-start;
            color: #392A24;
            font-size: 24px;
            font-weight: 400;
            margin: 0.7rem 0;
            text-align: left;
          }
        }
        .summary-edit {
          font-family: Roboto;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 139.69%;
          text-align: center;
          // letter-spacing: -0.005em;
          color: #FFAD00;
          @media (max-width: 1024px) {
            padding: 6px 0 0 0;
            text-align: left;
            // color: #392A24;
          }
        }
      }
      .departement-text {
        margin-top: 10px;
        margin-bottom: 5px;
        min-width: 165px;
        flex-direction: row;
        padding: 10px 0px;
        background: #045e96;
        border-radius: 10px;
        // font-family: Open Sans;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        justify-content: center;
      }
      .summary-conf-btn {
        width: 100%;
        background: #045e96;
        border-radius: 4px;
        border: 0;
        color: #fff;
        height: 44px;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 25px;
        text-align: center;
      }
      .summary-cancel-btn {
        width: 100%;
        height: 44px;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 25px;
        text-align: center;
        border: 1px;
        border: 1.5px solid #ce0017;
        box-sizing: border-box;
        border-radius: 4px;
        color: #ce0017;
        cursor: pointer;
      }
    }
    .summary-wapper-list-item {
      width: 100%;
      .txtPolicyContext {
        display: flex;
        flex-wrap: wrap;
        word-break: break-word;
        height: calc(100vh - 430px);
        overflow-y: auto;
        justify-content: center;
        text-align: -webkit-center;
        justify-self: center;
        justify-items: center;
  
        @media (max-device-width: 1024px) {
          height: calc(100vh - 260px);
        }
        @media (max-device-width: 640px) {
          height: calc(100vh - 260px);
        }
        @media (max-device-width: 540px) {
          height: calc(100vh - 260px);
        }
        @media (max-device-width: 414px) {
          height: calc(100vh - 249px);
        }
        @media (max-device-width: 375px) {
          height: calc(100vh - 397px);
        }
        @media (max-device-width: 320px) {
          height: calc(100vh - 296px);
        }
        @media (max-device-width: 280px) {
          height: calc(100vh - 260px);
        }
      }
  
      @mixin customscroll {
        &::-webkit-scrollbar {
          width: 6px;
          border-radius: 10px;
        }
  
        /* Track */
        &::-webkit-scrollbar-track {
          background: #eee;
          border-radius: 10px;
        }
  
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #999998;
          border-radius: 10px;
        }
  
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #999998;
          border-radius: 10px;
        }
      }
  
      .history-list-view {
        text-align: -webkit-center;
        justify-content: center;
        justify-self: center;
        justify-items: center;
        overflow-y: auto;
        height: calc(100vh - 397px);
        overflow-x: hidden !important;
        @include customscroll();
        @media (max-width: 479px) {
          margin-top: 20px;
          height: calc(100vh - 375px);
          overflow-x: hidden !important;
        }
        @media (max-width: 479px) {
        }
  
        .txtPolicyContext {
          display: flex;
          flex-wrap: wrap;
          word-break: break-word;
          height: calc(100vh - 430px);
          overflow-y: auto;
          justify-content: center;
          text-align: -webkit-center;
          justify-self: center;
          justify-items: center;
          margin-top: 30px;
  
          @media (max-device-width: 1024px) {
            height: calc(100vh - 260px);
          }
          @media (max-device-width: 640px) {
            height: calc(100vh - 260px);
          }
          @media (max-device-width: 540px) {
            height: calc(100vh - 260px);
          }
          @media (max-device-width: 414px) {
            height: calc(100vh - 249px);
          }
          @media (max-device-width: 375px) {
            height: calc(100vh - 397px);
          }
          @media (max-device-width: 320px) {
            height: calc(100vh - 296px);
          }
          @media (max-device-width: 280px) {
            height: calc(100vh - 260px);
          }
        }
        .box-section {
          padding: 0;
          margin: -6px;
        }
        .tab-content-box {
          width: 417px;
          background-color: #f6f1ee;
          border: 1px solid #f6f1ee;
          box-sizing: border-box;
          box-shadow: 0px 9.71591px 16.1932px rgba(61, 98, 103, 0.1);
          border-radius: 8.09659px;
          @media only screen and (min-device-width: 280px) and (max-device-width: 653px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
            width: calc(100vh - 438px);
          }
  
          @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
            width: calc(100vh - 195px);
          }
  
          @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
            width: calc(100vh - 272px);
          }
  
          @media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (orientation: portrait) {
            width: calc(100vh - 320px);
          }
  
          // iphone 4
          @media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
            width: calc(100vh - 184px);
          }
          // iphone 5
          @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
            width: calc(100vh - 273px);
          }
          // iphone 6, 6s, 7, 8
          @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
            width: calc(100vh - 320px);
          }
          // iphone 6+, 6s+, 7+, 8+
          @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
            width: calc(100vh - 320px);
          }
  
          @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-device-pixel-ratio: 3) {
            width: calc(100vh - 320px);
          }
  
          @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
            width: calc(100vh - 455px);
          }
  
          @media only screen and (min-device-width: 411px) and (max-device-width: 731px) and (orientation: portrait) {
            width: calc(100vh - 348px);
          }
  
          @media only screen and (min-device-width: 411px) and (max-device-width: 736px) and (orientation: portrait) {
            width: calc(100vh - 348px);
          }
  
          @media only screen and (min-device-width: 411px) and (max-device-width: 823px) and (orientation: portrait) {
            width: calc(100vh - 436px);
          }
  
          @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
            width: calc(100vh - 338px);
          }
  
          @media only screen and (min-device-width: 540px) and (max-device-width: 720px) and (orientation: portrait) {
            width: calc(100vh - 323px);
          }
  
          @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            width: calc(100vh - 609px);
          }
  
          @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
            width: calc(100vh - 950px);
          }
  
          ion-item {
            --background: transparent;
          }
  
          .list-item-consulta-title {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 152.69%;
            text-align: center;
            letter-spacing: -0.005em;
            color: #5f5f5f;
          }
          .list-item-consulta-name {
            font-family: Open Sans;
            font-style: normal;
            margin-top: 10px;
            font-weight: 400;
            font-size: 20px;
            line-height: 18px;
            text-align: center;
            color: #045e96;
          }
          .list-item-consulta-time {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            margin-top: 5px;
            font-size: 20px;
            line-height: 32px;
            text-align: center;
            color: #5f5f5f;
          }
        }
      }
    }
    .time-slot-main {
      width: 50%;
      text-align: center;
      margin: -4.5rem 0 0 0 !important;
      &.time-slot-div {
        width: 40%;
        ion-label {
          margin-left: 0;
          margin-top: 0;
          width: 100%;
          justify-content: center;
          color: #FE5000;
          font-size: 18px;
          font-weight: 700;
          font-family: "Source Sans Pro", sans-serif;
        }
        .professional-btn {
          max-width: 360px;
        }
      }
      .react-calendar {
        .react-calendar__navigation__label__labelText {
          color: black !important;
        }
        .highlight {
          color: black;
          font-weight: 600;
        }
        .nonHighlight {
          color: grey;
          pointer-events: none;
        }
        .react-calendar__tile:disabled {
          color: grey;
        }
        margin-left: auto;
        margin-right: auto;
        width: 354px;
        border-color: #dfdfdf;
        overflow: hidden;
        margin-top: 15px;
        .react-calendar__tile {
          padding: 1rem 0.5em;
        }
        .react-calendar__navigation {
          .react-calendar__navigation__next2-button,
          .react-calendar__navigation__prev2-button {
            display: none;
          }
          .react-calendar__navigation__next-button {
            color: #FFAD00;
            font-size: 30px;
          }
          .react-calendar__navigation__prev-button {
            color: #FFAD00;
            font-size: 30px;
          }
        }
        .react-calendar__month-view__days__day--weekend {
          color: grey;
          pointer-events: none;
          font-weight: normal;
        }
      }
      .time-slot-wapper {
        max-width: 365px;
        margin-right: auto;
        margin-left: auto;
        overflow-y: auto;
        max-height: 120px;
        padding-top: 0;
        margin-top: 2rem;
        overflow-x: hidden;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 1.5rem;
      }
  
      .appointment-header {
        margin-left: 0;
        margin-top: 0;
        width: 100%;
        justify-content: center;
        color: #FE5000;
        font-size: 18px;
        font-weight: 700;
        font-family: "Source Sans Pro", sans-serif;
      }
    }
    .contact-details-main {
      width: 35%;
      text-align: left;
      margin: 0;
      padding: 0 0 0 5rem;
      float: left;
      @media (max-width: 1024px) {
        width: 100%;
        padding: 20px 10px ;
      }
      .appointment-header {
        justify-content: left;
        margin-left: 0;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
        @media (max-width: 1024px) {
          color: #FE5000;
          font-size: 18px;
          font-weight: 700;
          text-align: center;
          justify-content: center;
          margin-bottom: 0;
          font-family: "Source Sans Pro", sans-serif;
        }
      }
      .formik-form {
        .text-field-wapper {
          .text-field {
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #5F5F5F !important;
            .MuiInputAdornment-positionEnd{
              opacity: 0.4;
            }
            .MuiInputBase-input{
              font-weight: bold;
            font-size: 14px;
            line-height: 24px;
            color: #392A24 !important;
            padding-left: 15px !important;
            }
            @media (max-width: 375px) {
              min-width: 100%;
            }
          }
        }
      }
      &.contact-div {
        .appointment-header {
          color: #FE5000;
          font-size: 18px;
          font-weight: 700;
          justify-content: center;
          margin-bottom: 0;
          font-family: "Source Sans Pro", sans-serif;
        }
      }
    }
    .summary-wapper.summary {
      text-align: left;
      padding: 0;
      float: left;
      margin: 0;
      max-width: 363px;
      min-width: auto;
      .summary-card {
        background: #FBFBFB;
        border: 1px solid #DFDFDF;
        box-sizing: border-box;
        box-shadow: 0px 12px 20px rgba(61, 98, 103, 0.1);
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 0;
        text-align: center;
        .summary-name {
          color: #FE5000;
          font-family: "Roboto", sans-serif;
          font-weight: 700;
          font-size: 18px;
        }
  
        .summary-card-inner {
          flex-direction: column;
        }
      }
    }
    .contact-summary-main {
      width: 100%;
      .lastbuttondiv {
        text-align: right;
        max-width: 320px;
        padding: 0;
        margin-top: 11.8rem;
        margin-left: 2.5rem;
        display: inline-block;
        .disable-confirm-btn {
          background: #b5b5b5;
          pointer-events: none;
          border-radius: 10px;
          font-weight: 600;
          font-size: 15px;
          color: #ffffff;
          width: 100%;
          display: inline-block;
          vertical-align: middle;
          /* min-width: 245px; */
          margin-right: 0;
          text-align: center;
          padding: 13px 20px;
          margin-bottom: 1.2rem;
          ion-img {
            vertical-align: middle;
            display: inline-block;
          }
        }
        .summary-conf-btn {
          background: #FE5000;
          border-radius: 10px;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          width: 100%;
          display: inline-block;
          vertical-align: middle;
          /* min-width: 245px; */
          margin-right: 0;
          text-align: center;
          padding: 13px 20px;
          margin-bottom: 1.2rem;
          cursor: pointer;
          span {
            cursor: pointer;
          }
          ion-img {
            vertical-align: middle;
            display: inline-block;
          }
        }
        .summary-cancel-btn {
          color: #C06666 !important;
          font-weight: 500;
          border: 1px solid #C06666;
          box-sizing: border-box;
          border-radius: 10px;
          padding: 13px 20px;
          text-align: center;
          line-height: normal;
          width: 100%;
          background: white;
          cursor: pointer;
          outline: none;
        }
      }
    }
  
    .pronto-atend-view {
      /* width: 250px !important; */
      flex: 0 0 auto;
      width: 30%;
      padding: 0 40px;
      margin-top: -48px;
    }
    .pronto-atend-header-txt {
      //position: absolute;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 20px;
      color: #231f20;
    }
    .pronto-atend-sub-txt {
      //position: absolute;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      color: #231f20;
    }
    .pronto-atend-bold-sub-txt {
      //position: absolute;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #f7941d;
    }
    .pronto-clocl-view {
      text-align: -webkit-right;
    }
    .pronto-clock-icon {
      width: 65.44px;
      height: 65.44px;
      justify-items: right;
      align-items: end;
      margin-top: -62px;
    }
    .video-corner-icon {
      margin: 0 8px 0 0;
      width: 16px;
    }
    .pronto-btn-view {
      text-align: left;
    }
    .pronto-atend-btn {
      --ion-color-base: #f7941d !important;
      --background-activated: #f7941d !important;
      --background-focused: #f7941d !important;
      --border-color: transparent !important;
      --border-width: 0;
      --color: white;
      --background: #f7941d;
      --box-shadow: none;
      --border-radius: 37px;
      height: 44px;
      width: 250px;
      text-transform: none;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }
    @media only screen and (max-width: 1024px) {
      padding: 0 10px;
    }
  
    .maintext {
      @media only screen and (max-width: 1024px) {
        color: #045e96;
        font-size: 20px;
        font-weight: 400;
        margin-top: 1.5rem;
      }
    }
    .listdiv-multi.multi-btn-mobile {
      @media only screen and (max-width: 1024px) {
        color: #5f5f5f;
        font-size: 20px;
        margin: 0;
        max-width: 100%;
      }
      b {
        @media only screen and (max-width: 1024px) {
          font-weight: 700;
        }
      }
      .small-heading {
        @media only screen and (max-width: 1024px) {
          text-align: left;
          margin-bottom: 1rem;
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          color: #392a24;
        }
      }
    }
  }
  
  .especialista-div {
    font-weight: bold;
  }
  .summary-btns {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .summary-conf-btn-m {
    width: 100%;
    background: #FE5000;
    border-radius: 50px;
    border: 0;
    color: #fff !important;
    height: 50px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 25px;
    text-align: center;
  }
  .summary-cancel-btn-m {
    width: 100%;
    height: 50px;
    font-weight: 400;
    font-size: 16px;
    // margin-bottom: 25px;
    text-align: center;
    border: 1px;
    border: 0px solid #ce0017;
    box-sizing: border-box;
    border-radius: 50px;
    color: #C06666 !important;
    background: white;
  }
  
  .web-specailist {
    .especialista-div {
      color: #392A24;
      font-size: 20px;
      font-weight: 400;
      font-family: "Roboto", sans-serif;
      strong {
        font-weight: 700;
      }
    }
    .departement-text {
      color: #FE5000;
      font-size: 22px;
      border-bottom: #FE5000 solid 1px;
      max-width: 300px;
      text-align: center;
      margin-bottom: 2rem;
      margin-top: 18px;
      font-weight: 600;
      padding-bottom: 0.7rem;
      margin-left: 0;
      margin-right: 0;
      position: relative;
      padding-left: 20px;
      cursor: pointer;
      ion-img {
        @media screen and (min-width: 992px) {
          position: absolute;
          left: 0;
        }
      }
    }
    .especialista-div {
      margin-top: 0;
    }
  }
  .successo-web {
    .successo-main {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: 12px;
      padding: 25px;
    }
    .orange-text {
      font-weight: bold;
      font-size: 28px;
      color: #ff8574;
    }
    .black-text {
      font-size: 28px;
    }
  }
  .specialityBtn:hover {
    background-color: #def5fc;
    padding: 4px 10px !important;
    border-radius: 5px;
  }
  .dropDownbtn {
    --background: #fff;
    text-transform: inherit;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    width: 320px;
    height: 50px;
    --border-color: #FE5000 !important;
    --border-width: 1px;
    --border-style: solid;
    letter-spacing: 0;
    --background-activated: #FE5000 !important;
    --box-shadow: none;
    --color: #fff;
    --background: #fff;
    --border-radius: 10px;
    color: #fff;
    --background: #FE5000;
    margin: 0;
    position: relative;
    z-index: 9;
  
    @media screen and (max-width: 480px) {
      width: 100%;
      // font-size: 14px;
    }
    &.openDrop {
      .down-arrow {
        transform: rotate(180deg);
      }
    }
    .down-arrow {
      margin-right: 0;
      width: 18px;
      /* float: left; */
      position: absolute;
      right:  0;
      top: 13px;
    }
  }
  
  // .time-slot-wrap{
  //   max-width: 365px;
  //   overflow-y: auto;
  //   max-height: 120px;
  //   padding-top: 0;
  //   overflow-x: hidden;
  //   padding-left: 30px;
  //   padding-right: 30px;
  //   margin: 2rem auto 1.5rem;
  //   .time-slot {
  //     color: #404040;
  //     text-align: center;
  //     width: 52px !important;
  //     height: 52px;
  //     display: flex !important;
  //     align-items: center;
  //     justify-content: center;
  //     font-size: 14.42px;
  //     border: 1px solid #c7c8cc;
  //     box-sizing: border-box;
  //     margin: 0 4px !important;
  //     border-radius: 60px;
  //     font-family: Source Sans Pro;
  //     font-style: normal;
  //     font-weight: normal;
  //     line-height: 18px;
  //     color: #404040;
  //     &.active {
  //       background-color: #33c3f0;
  //       color: #fff;
  //       font-weight: 600;
  //       border-color: #33c3f0;
  //     }
  //   }
  // }
  // .time-slot-div .time-slot-wapper {
  //   display: flex;
  //   display: -webkit-box;
  //   overflow-x: auto;
  //   width: 100%;
  //   margin-bottom: 20px;
  //   /* white-space: nowrap; */
  //   /* touch-action: auto; */
  //   position: absolute;
  //   left: 0;
  //   top: auto;
  //   bottom: auto;
  //   .time-slot {
  //     color: #404040;
  //     text-align: center;
  //     width: 52px ;
  //     height: 52px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     font-size: 14.42px;
  //     border: 1px solid #c7c8cc;
  //     box-sizing: border-box;
  //     margin: 0 6px !important;
  //     border-radius: 60px;
  //     font-family: Source Sans Pro;
  //     font-style: normal;
  //     font-weight: normal;
  //     line-height: 18px;
  //     color: #404040;
  //     &.active {
  //       background-color: #33c3f0;
  //       color: #fff;
  //       font-weight: 600;
  //       border-color: #33c3f0;
  //     }
  //   }
  // }
  .slick-list {
    max-width: 400px;
    padding-top: 0 !important;
    padding-right: 30px !important;
    margin: 2rem auto 1.5rem !important;
  }
  .slick-list .time-slot {
    text-align: center;
    padding: 0;
    border: 1px solid rgba(112, 112, 112, 0.38);
    border-radius: 50px;
    // display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    margin: 0 15px 10px 15px;
    width: 52px !important;
    height: 52px;
    font-weight: 400;
    color: #404040;
    padding: 15px 0;
    cursor: pointer;
    &.active {
      background-color: #FFAD00 !important;
      color: #fff;
      border-color: #FFAD00 !important;
    }
  }
  .slick-prev {
    left: 25px !important;
  }
  .slick-next {
    right: 25px !important;
  }
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    /* width: 20px; */
    height: 40px !important;
    /* padding: 0; */
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    //background-image: url("../../../../assets/images/");
    background-image: url("./assets/images/left-icon.svg") !important;
    z-index: 999;
  }
  .slick-prev:before,
  .slick-next:before {
    color: transparent !important;
  }
  .slick-next {
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  
    background-image: url("./assets/images/right-icon.svg") !important;
  }
  .custom-toast-modal {
    --background: white;
    width: 300px;
    right: 28px;
    height: 61px;
    left: auto;
    bottom: auto;
    top: 100px;
    /* z-index: 999999; */
    /* max-width: 199px; */
    border-radius: 50px;
    /* padding: 10px; */
    box-shadow: #33333347 0 8px 26px;
  
    .innercontent {
      display: flex;
      align-items: center;
      justify-content: start;
      z-index: 9999999;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 0 0 16px;
      ion-img {
        margin-right: 0.6rem;
        background: #c06666;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        padding: 5px;
      }
      p {
        margin: 0;
        color: #392a24;
        font-weight: 600;
      }
    }
  }
  .react-calendar__navigation__label {
    pointer-events: none;
  }
  