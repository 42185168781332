.addusermodel {


  @media (max-width: 992px) {
    padding: 0px 5%;
  }

  .modal-wrapper {
    width: 480px !important;
    height: auto !important;
  }
  .ion-page {
    justify-content: center;
    text-align: center;
    padding: 20px;
    position: relative;
    contain: content;
  }
  .contenttitle {
    margin: 0 0 15px 0;
    text-align: center;
    ion-label {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 127.19%;
      text-align: center;
      color: #726f6f;
    }
  }

  .contentsubtitle {
    margin: 0 0 15px 0;
    text-align: center;
    ion-label {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 127.19%;
      text-align: center;
      color: #000000;

      span {
        font-weight: bold;
      }
    }
  }

  .userinfoformdiv {
    text-align: left;
    max-width: 300px;
    margin: 0 auto;

    .MuiFormControl-root {
      margin-bottom: 0 !important;

      .MuiInput-underline::after {
        border-bottom: none !important;
        content: "";
      }
      .MuiInput-underline::before {
        border-bottom: none !important;
        content: "";
      }
      .MuiInputBase-root {
        min-height: unset !important;
        .MuiInputBase-input {
          border-bottom: 1px solid rgba(0, 0, 0, 0.42);
          &:hover {
            border-bottom: 2px solid rgb(0, 0, 0);
          }
          &:focus {
            border-bottom: 2px solid #3f51b5 !important;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .formbtndiv {
    margin: 40px 0 10px 0;

    .enviar-btn {
      margin: 0 auto;
      --ion-color-base: #000000 !important;
      --background-activated: #000000 !important;
      --background-focused: #000000 !important;
      --border-color: transparent !important;
      --border-width: 0;
      --background: #000000;
      --box-shadow: none;
      text-transform: capitalize;
      border-radius: 5px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 127.19%;
      color: #ffffff;
      max-width: 150px;

      ion-icon {
        margin: 0 0 0 6px;
        border-bottom: 0;
        padding: 0;
      }
    }

    .informardiv {
      margin: 20px 0 0 0;
      text-align: center;
      ion-label {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 127.19%;
        text-align: center;
        color: #CB4848;
        ;
        cursor: pointer;
      }
    }
  }

  .email-popup {
    .react-tel-input {
      padding-top: 10px;
      border: 2px solid #e3e3e3;
    
      .flag-dropdown {
        top: 0px !important;
        height: 100% !important;
      }

      .form-control {

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #333;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: #333;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: #333;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: #333;
        }

        &:hover {
          border-bottom: 2px solid rgba(0, 0, 0, 0.87) !important;
        }

        &:focus {
          outline: none;
          box-shadow: none;
          border-bottom: 2px solid #3f51b5 !important;
        }
      }
    }
    .smallmessage{
      font-size: 14px;
      color: #CB4848;
      margin: 0;
      position: absolute;
      bottom: -22px;
  }
  }
}