@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');




#comTomTicketChatWidget {
  margin-bottom: 10px !important;
}

.user-support-page {
  font-family: "Roboto", sans-serif;
  /* padding : 69px 0 !important; */

}

@media(max-width:767px) {

  .user-support-page {
    padding: 0px 0;

  }
}

.live_support_timer {
  color: #014c93 !important;
}

.platform-call {
  text-align: center;
}

.support-buttons button {
  width          : 81px;
  height         : 55px;
  color          : #ffffff;
  font-family    : Open Sans;
  font-size      : 26px;
  font-weight    : normal;
  font-style     : normal;
  text-decoration: none;
  text-align     : center;
  background     : #424242;
  border         : none;
  border-radius  : 10px;
}

.profile_detail img {
  width        : 150px;
  height       : 150px;
  border-radius: 100%;
  margin       : 0 auto;
}

.live_support {
  height          : calc(100vh - 82px);
  overflow        : hidden;
  background-color: #000;
  z-index : 9 !important;
}

#remote-media1 {
  height  : 100% !important;
  width   : 100%;
  overflow: hidden;
}

.suppott_btn {
  left  : 0;
  right : 0;
  bottom: 0;
}

.video_cl_user {
  width     : 100%;
  height    : 100%;
  background: #ccc;
  position  : absolute;
  object-fit: cover;
  text-align: center;
}

.video_cl_user img {
  width     : 100%;
  object-fit: cover;
}

.user_two {
  width     : 200px;
  height    : 200px;
  background: #eee;
  position  : absolute;
  object-fit: cover;
  text-align: center;
  right     : 10px;
  bottom    : 10px;
  overflow  : hidden;
}

.user_two video {
  width     : 200px;
  height    : 200px;
  object-fit: cover;
}

.not_avail {
  /* background-image: url("images/unavailable.png"); */
  background-position: center;
  background-repeat  : no-repeat;
}

.oppos_screen img {
  width     : 100%;
  height    : 100%;
  object-fit: cover;
}

.icon-bundle .btn-danger {
  background-color: rgb(107, 74, 168);
  border-color    : rgb(107, 74, 168);
}

.w_20 {
  width: 28px;
}

.top_btm {
  bottom: 10px;
  left  : 0;
  right : 0;
}

.video_chat {
  height: 367px;
}

.oppos_screen {
  width   : 100px;
  height  : 120px;
  right   : 10px;
  bottom  : 10px;
  border  : 1px solid #ccc;
  overflow: hidden;
}

.connecting_screen {
  top    : 0;
  left   : 0;
  z-index: 1;
}

.no_video_show {
  position: absolute;
  top     : 0;
  right   : 0;
}

.disabled {
  pointer-events: none;
}

.iron_logo {
  max-width: 90px !important;
  height   : 90px !important;

}

.h-100vh {
  height: calc(100vh - 300px);
}

.connection_text {
  color: #004986;
}

.live_support button img {
  width: 24px;
}

.height_100vh {
  height: calc(100vh - 180px);
}

#remote-media1 video {
  width        : 100%;
  /* height    : 100%; */
  height       : calc(100vh - 82px);
  border       : 2px solid #000;
  /* object-fit: cover; */
}

.vue-star-rating-rating-text {
  display: none;
}

.user-support-page .react-rater {
  width : 250px;
  height: 50px;
}

.user-support-page .react-rater .react-rater-star {
  color      : #D9D9D9;
  width      : 50px;
  height     : 50px;
  font-size  : 43px;
  line-height: 50px;
}

.user-support-page .react-rater .react-rater-star.is-active,
.user-support-page .react-rater .react-rater-star.will-be-active {
  color: #F9D056;
}

.review-section {
  width: 40%;
}

.width_30 {
  width: 30%;
}

.video-section {
  margin: 100px;
}

.header_cassi .navbar-nav {
  list-style   : none;
  display      : flex;
  padding      : 0;
  color        : #605d54;
  align-items  : center;
  margin-bottom: 0;
}

.header_cassi .navbar-nav .nav-item {
  margin-left: 10px;
}

.header_cassi .navbar-nav .nav-item p {
  margin-bottom: 0;
  text-align   : right;
}

.header_cassi .navbar-nav .nav-item .btn {
  color           : #fff;
  background-color: #004c94;
  padding-left    : 20px;
  padding-right   : 20px;
}

.header_cassi .navbar-nav .nav-link {
  color      : #004c94;
  font-weight: 600;
  margin-left: 5px;
}

.banner-img {
  position: relative;
}

/*
.banner-img:after {
  position: absolute;
  width: 100%;
  height: 140px;
  bottom: -50px;
  left: 0;
  background: transparent
    url(http://demo.estaticinfotech.com/cloud-animation/wave-img.png) no-repeat;
  background-position: bottom;
  content: "";
  background-size: 100% 105px;
  z-index: 2;
}

.banner-img:before {
  position: absolute;
  width: 100%;
  height: 208px;
  bottom: 2px;
  left: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 76, 148, 0.82));
  content: "";
  z-index: 1;
}
*/

.banner-img .post_imgg img {
  /* object-fit: cover;
  object-position: bottom;
  height: 600px;
  width: 100%; */
  -o-object-fit     : cover;
  object-fit        : cover;
  -o-object-position: bottom;
  object-position   : bottom;
  height            : 61.91950464396285vh;
  width             : 100%;
}

.post_content {
  position: absolute;
  top     : 100px;
  width   : 100%;
  left    : 0;
}

.post_content h4 {
  color        : #004c94;
  font-size    : 40px;
  font-weight  : 400;
  margin-bottom: 0;
}

.post_content a {
  color          : #004c94;
  border-bottom  : 3px solid #ffde00;
  font-size      : 40px;
  font-weight    : 400;
  text-decoration: none;
  line-height    : 1.2;
}

.social_link {
  list-style : none;
  display    : flex;
  align-items: center;
  padding    : 0;
  margin     : 10px;
}

.footer_cssi p {
  font-size: 14px;
  color    : #636057;
}

.social_link a {
  padding        : 5px;
  width          : 30px;
  height         : 30px;
  border-radius  : 20px;
  background     : #c6c6c6;
  color          : #fff;
  display        : flex;
  align-items    : center;
  justify-content: center;
  text-decoration: none;
}

.social_link li {
  margin-right: 10px;
}

.btn_theme {
  color           : #4c4c4c;
  font-weight     : 600;
  background-color: #ffe139 !important;
  font-size       : 28px;
  min-width       : 195px;
  box-shadow      : 0 0 6px rgba(0, 0, 0, 0.28);
  padding         : 5px 10px;
}

.select-service p {
  font-size  : 14px;
  margin     : 10px 0;
  font-weight: 600;
}

.select-service .select-service__title {
  color      : #ffffff;
  margin-top : 6px;
  font-size  : 14px;
  font-weight: 700;
}

.select-service .select-service__icon {
  width          : 120px;
  height         : 120px;
  border-radius  : 50%;
  background     : #004986;
  margin-bottom  : 1rem;
  position       : relative;
  border         : 5px solid #ffe139;
  box-shadow     : 0px 4px 10px rgba(0, 0, 0, 0.42);
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
}

.select-service:hover .select-service__icon {
  background: #004986;
}

.select-service:hover .select-service__title {
  color: #ffffff;
}

.select-service .select-service__time {
  font-size    : 12px;
  color        : #757575;
  font-weight  : 700;
  padding      : 4px 16px;
  line-height  : 1;
  display      : inline-block;
  border-radius: 3px;
  background   : #f2f2f2;
  border       : 0px;
  box-shadow   : 1px 1px 5px rgba(0, 0, 0, 0.25);
  margin       : 0;
  font-weight  : normal;
}

.user-support-page .nav {
  /* border-bottom: 1px solid #777; */
  padding: 10px 0;
}

.select-service .select-service__icon span {
  padding   : 0rem;
  text-align: center;
  position  : static;
  left      : auto;
  right     : auto;
  top       : auto;
  transform : none;
}

.call_det {
  display: none;
}

.select-service .select-service__icon span svg {
  width   : 50px;
  position: relative;
}

.select-service {
  position     : relative;
  margin-bottom: 4rem;
  margin-top   : -73px;
}

/* .height_changes {
  min-height: calc(100vh - 172px);
} */
.user-support-page>.container {
  min-height: calc(100vh - 172px);
}

.show_controler {
  z-index: 999 !important;
  bottom : 0;
}

@media (max-width: 1400px) {
  .banner-img .post_imgg img {
    height: 373px;
  }

  .height_changes {
    min-height: calc(100vh - 190px);
  }
}

@media (max-width: 1300px) {
  .banner-img .post_imgg img {
    height: 345px;
  }

  .select-service {
    margin-top: -90px;
  }
}

@media (max-width: 768px) {
  .banner-img .post_imgg img {
    height: 400px;
  }

  .header_cassi .navbar-nav .nav-item p {
    font-size: 14px;
  }

  .header_cassi .navbar-nav .nav-item .btn {
    font-size: 14px;
  }

  #remote-media1 video {
    object-fit: cover;
  }

  .user_two,
  .user_two video {
    width : 110px;
    height: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .header_cassi .navbar-nav .nav-link {
    display: block;
  }
}

@media (max-width: 576px) {
  .suppott_btn {
    padding-left: 10px;
    text-align  : left !important;
  }
}

@media (max-width: 479px) {

  .post_content h4,
  .post_content a {
    font-size: 24px;
  }

  .banner-img .post_imgg img {
    height    : 254px;
    margin-top: 10px;
  }

  .select-service {
    margin-top: -73px;
  }

  .height_changes {
    min-height: inherit !important;
  }

  .height_100vh {
    /* height: calc(100vh - 230px); */
    height         : 100vh;
    align-items    : center;
    justify-content: center;
    display        : flex;
    flex-direction : column;
  }

  .width_30 {
    width: 75%;
  }

  .header_cassi .navbar-nav .nav-item p {
    display: none;
  }

  .header_cassi .navbar-nav .nav-item .btn {
    font-size  : 13px;
    padding    : 5px 11px !important;
    font-weight: normal;
  }

  .header_cassi .navbar-nav .nav-item {
    margin-left: 5px;
  }

  .select-service .select-service__icon span svg {
    width: 38px;
  }

  .select-service .select-service__title {
    font-size  : 12px;
    font-weight: normal;
  }

  .select-service p {
    font-size     : 16px;
    /* font-weight: normal; */
  }

  .select-service .select-service__time {
    font-size: 15px;
    padding  : 3px 10px;
  }

  .footer_cssi p {
    text-align   : center;
    margin-bottom: 5px !important;
  }

  .social_link {
    margin         : 5px 10px;
    justify-content: center;
  }

  .banner-img {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    padding-top   : 10px;
  }

  .post_imgg {
    order: 2;
  }

  .post_content {
    order     : 1;
    position  : static;
    text-align: center;
  }

  .select-service .select-service__icon {
    width : 90px;
    height: 90px;
    margin: 109px auto 40px auto;

  }

  .btn_theme {
    font-size  : 24px;
    line-height: 1.3;
  }

  .navbar-brand img {
    max-width: 130px;
  }

  .footer_cssi>.row .col-auto {
    flex-wrap      : wrap;
    justify-content: center;
    display        : inline-block !important;
  }

  .call_det {
    display  : block;
    font-size: 12px;
  }

  .call_det a {
    font-weight: 600;
  }

  .contactcls {
    font-size: 20px;
  }

  .post_content .row {
    justify-content: center;
  }

  .call_det a {
    margin-left: 5px;
  }
}

.user-support-page.wl-plansaude .nav {
  height             : 80px;
  padding            : 0;
  background-color   : #195090;
  /* background-image: url(/assets/image/plansaude/background-bar.png); */
  background-position: left top;
  background-size    : cover;
  border             : 0;
}

.user-support-page.wl-plansaude .nav>div {
  height   : 80px;
  /* margin: 0 0 ; */
  padding  : 15px 220px 10px 150px;
}

.user-support-page.wl-plansaude .nav .navbar-nav {
  margin         : 0;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : flex-end;
}

.user-support-page.wl-plansaude .nav .navbar-nav span {
  font-size     : 24px;
  font-family   : "Roboto", sans-serif;
  color         : #fff;
  text-align    : right;
  letter-spacing: 0.4px;
}

@media(max-width: 920px) {
  .user-support-page.wl-plansaude .nav .navbar-nav {
    display: none;
  }
}

@media(max-width: 380px) {
  .user-support-page.wl-plansaude .nav>div {
    padding: 15px 20px 10px 110px;
  }
}

.user-support-page.wl-plansaude>.container {
  min-height: calc(100vh - 151px);
}

.user-support-page.wl-plansaude>.height_changes {
  min-height    : calc(100vh - 151px);
  display       : flex;
  flex-direction: column;
}

.user-support-page.wl-plansaude>.welcome-content {
  position: relative;
}

.user-support-page.wl-plansaude>.welcome-content .signout-button {
  position     : absolute;
  top          : 30px;
  right        : 30px;
  cursor       : pointer;
  box-shadow   : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  border-radius: 50%;
}

.user-support-page.wl-plansaude .banner-container {
  flex: 1;
}

.user-support-page.wl-plansaude .banner-container .welcome-call {
  padding: 50px 75px;
}

.user-support-page.wl-plansaude .banner-container .welcome-call>h1 {
  font-size  : 28px;
  font-family: "Roboto", sans-serif;
  color      : #747373;
  text-align : right;
}

.user-support-page.wl-plansaude .banner-container .welcome-call>h1>span {
  font-weight: 500;
  color      : #202529
}

@media(max-width: 550px) {
  .user-support-page.wl-plansaude .banner-container>div:nth-of-type(2) {
    margin-right: 0;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-plansaude .banner-container .welcome-call>h1 {
    font-size: 22px;
  }

  .user-support-page.wl-plansaude .features-item {
    font-size: 12px;
  }
}

.user-support-page.wl-plansaude .banner-container .doctor-banner {
  max-width      : 770px;
  position       : relative;
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items    : flex-end;
  width          : 100%;
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble {
  position        : absolute;
  top             : 85px;
  left            : 0;
  padding         : 18px;
  background-color: #fff;
  border-radius   : 30px;
  border          : solid 1px #eeee;
  box-shadow      : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  display         : flex;
  flex-direction  : row;
  justify-content : flex-start;
  align-items     : center;
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble:after {
  content             : "";
  position            : absolute;
  top                 : 16px;
  right               : -25px;
  z-index             : 1;
  border              : solid 15px transparent;
  border-left-color   : #FFF;
  /* top              : 96px; */
  /* right            : 16px; */
  /* border-left-color: unset; */
  /* border-top-color : #FFF; */
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble>span {
  display         : block;
  background-color: #eeeeee;
  padding         : 13px;
  border-radius   : 50%;
  color           : #383a3d;
  font-size       : 24px;
  line-height     : 24px;
  margin-right    : 18px;
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble>div {
  flex           : 1;
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
  font-family    : "Roboto", sans-serif;
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble>div>h3 {
  font-size: 16px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble>div>a {
  font-size      : 16px;
  color          : #006cb3;
  text-decoration: underline;
  cursor         : pointer;
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast {
  font-size: 14px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>i,
.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>b {
  margin-right: 5px;
  font-size   : 14px;
  color       : #383a3d;
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-picture {
  max-width: 460px;
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-picture>img {
  border-radius: 30px;
}

.user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-picture>div {
  padding         : 15px 20px;
  border-radius   : 5px;
  background-color: rgba(64, 64, 64, 0.8);
  font-size       : 16px;
  font-family     : "Roboto", sans-serif;
  color           : #f6f6f6;
  position        : absolute;
  right           : 30px;
  bottom          : 5px;
}

@media(max-width: 820px) {
  .user-support-page.wl-plansaude .banner-container .welcome-call>h1 {
    text-align: center;
  }

  .user-support-page.wl-plansaude .banner-container .doctor-banner {
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    margin-bottom  : 50px;
  }

  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble {
    position     : relative;
    top          : unset;
    left         : unset;
    right        : unset;
    margin-bottom: 18px;
    z-index      : 3;
    box-shadow   : 4px 8px 10px 0 rgba(18, 18, 18, 0.16);
  }

  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble:after {
    content         : "";
    position        : absolute;
    top             : unset;
    right           : 16px;
    bottom          : -25px;
    z-index         : 1;
    border          : solid 15px transparent;
    border-top-color: #FFF;
  }

  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-picture {
    position: relative;
    z-index : 1;
  }

  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-picture>div {
    z-index: 2;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble>span {
    padding     : 9px;
    font-size   : 16px;
    line-height : 16px;
    margin-right: 11px;
  }

  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble>div>h3,
  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-talk-bubble>div>a {
    font-size: 14px;
  }

  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-picture {
    max-width: 320px;
  }

  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-picture>img {
    max-width    : 320px;
    border-radius: 30px;
  }
}

/* @media(max-width: 470px) {
  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-picture {
    max-width: calc(100vw-20px);
  }
  .user-support-page.wl-plansaude .banner-container .doctor-banner .doctor-picture > img {
    max-width: calc(100vw-20px);
  }
} */

.user-support-page.wl-plansaude .features-container {
  border-top: solid 1px #f6f6f6;
}

.user-support-page.wl-plansaude .features-list {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  flex-wrap      : wrap;
  padding-top    : 20px;
}

.user-support-page.wl-plansaude .features-item {
  padding-left : 7px;
  padding-right: 7px;
  margin-bottom: 20px;
  word-wrap    : none;
  font-weight  : 500;
}

.user-support-page.wl-plansaude .features-item>i {
  color       : #efa25b;
  margin-right: 7px;
}

@media(max-width: 820px) {
  .user-support-page.wl-plansaude .features-list {
    padding-left   : 30px;
    padding-right  : 30px;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    width          : auto;
  }
}

.footer--ans-wrapper {
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;
  color          : white;
  font-size      : 12px;
}

.user-support-page.wl-plansaude footer {
  min-height         : 70px;
  background-color   : #195090;
  /* background-image: url(/assets/image/plansaude/background-bar.png); */
  background-position: left top;
  background-size    : cover;
}

.user-support-page.wl-plansaude footer>div {
  padding: 10px 220px 10px 150px;
}

.user-support-page.wl-plansaude footer .social_link {
  margin-left : 60px;
  margin-right: 60px;
}

.user-support-page.wl-plansaude footer .social_link a {
  background-color: #fff;
}

.user-support-page.wl-plansaude footer .social_link a>i {
  color: #246cb2;
}

.user-support-page.wl-plansaude footer a.contact {
  font-size      : 14px;
  font-family    : "Roboto", sans-serif;
  color          : #fff;
  text-decoration: underline;
}

.user-support-page.wl-plansaude footer p {
  font-size  : 14px;
  font-family: "Roboto", sans-serif;
  color      : #fff;
  text-align : right;
}

@media(max-width: 820px) {
  .user-support-page.wl-plansaude footer p {
    display: none;
  }
}

@media(max-width: 550px) {
  .user-support-page.wl-plansaude footer a.contact {
    display: none;
  }
}

@media(max-width: 430px) {
  .user-support-page.wl-plansaude footer>div {
    padding: 10px 20px 10px 60px;
  }

  .user-support-page.wl-plansaude footer .social_link {
    margin-left : 20px;
    margin-right: 20px;
  }
}


.user-support-page.wl-plansaude .btn.btn-warning {
  background-color: #ea862b;
}

.user-support-page.wl-plansaude .btn.btn-primary {
  background-color: #006cb3;
}

.user-support-page.wl-plansaude .react-rater {
  width : 300px;
  height: 60px;
}

.user-support-page.wl-plansaude .react-rater .react-rater-star {
  color      : #ea862b;
  opacity    : 0.3;
  width      : 60px;
  height     : 60px;
  font-size  : 53px;
  line-height: 60px;
}

.user-support-page.wl-plansaude .react-rater .react-rater-star.is-active,
.user-support-page .react-rater .react-rater-star.will-be-active {
  opacity: 1;
}

.user-support-page.wl-plansaude .cpf-auth.container .row>h5 {
  margin-top   : 50px;
  margin-bottom: 50px;
}

.user-support-page.wl-plansaude .cpf-auth.container .row>p {
  margin-bottom: 41px;
}

.user-support-page.wl-plansaude .cpf-auth.container form {
  /* background-image: url(/assets/image/plansaude/background-auth-row.png); */
  background-size: cover;
  width          : 580px;
  height         : 279px;
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
  flex-wrap      : wrap;
  padding        : 59px 50px;
  margin-bottom  : 70px;
}

.user-support-page.wl-plansaude .cpf-auth.container form .input-container {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
}

.user-support-page.wl-plansaude .cpf-auth.container form .input-container:first-child {
  margin-right: 40px;
}

.user-support-page.wl-plansaude .cpf-auth.container form .input-container label {
  font-size  : 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color      : #fff;
}

.user-support-page.wl-plansaude .cpf-auth.container form .input-container input {
  width           : 220px;
  height          : 40px;
  padding         : 12px 20px;
  border-radius   : 10px;
  box-shadow      : 2px 2px 4px 0 rgba(18, 18, 18, 0.16);
  border          : solid 1px #eeeeee;
  background-color: #ffffff;
  font-family     : "Roboto", sans-serif;
  font-size       : 14px;
  color           : rgba(116, 115, 115, 1);
}

.user-support-page.wl-plansaude .cpf-auth.container form .input-container input::placeholder,
.user-support-page.wl-plansaude .cpf-auth.container form .input-container input::-moz-placeholder,
.user-support-page.wl-plansaude .cpf-auth.container form .input-container input::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size  : 14px;
  color      : rgba(116, 115, 115, 0.6);
}

.user-support-page.wl-plansaude .cpf-auth.container form .button-container {
  position       : relative;
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.user-support-page.wl-plansaude .cpf-auth.container form .button-container button {
  width           : 200px;
  height          : 40px;
  border-radius   : 6px;
  box-shadow      : 2px 2px 4px 0 rgba(234, 134, 43, 0.2);
  border          : solid 1px #ea862b;
  background-color: #ea862b;
  font-size       : 16px;
  font-weight     : 500;
  font-family     : "Roboto", sans-serif;
  color           : #fff;
}

.user-support-page.wl-plansaude .cpf-auth.container form .button-container button:hover {
  background-color: #c47023;
}

.user-support-page.wl-plansaude .cpf-auth.container form .button-container button:disabled {
  background-color: #aca6a1;
  border          : solid 1px #aca6a1;
}

.user-support-page.wl-plansaude .cpf-auth.container form .button-container p {
  position   : absolute;
  font-size  : 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color      : #fff;
  margin     : 0;
  bottom     : -55px;
  left       : auto;
}

@media(max-width: 600px) {
  .user-support-page.wl-plansaude .cpf-auth.container form {
    /* background-image: url(/assets/image/plansaude/background-auth-column.png); */
    width  : 355px;
    height : 320px;
    padding: 28px;
  }

  .user-support-page.wl-plansaude .cpf-auth.container form .input-container {
    margin-bottom: 25px;
  }

  .user-support-page.wl-plansaude .cpf-auth.container form .input-container:first-child {
    margin-right: 0;
  }

  .user-support-page.wl-plansaude .cpf-auth.container form .button-container {
    margin-bottom: 20px;
  }

  .user-support-page.wl-plansaude .cpf-auth.container form .button-container p {
    bottom: -100px;
  }
}

@media(max-width: 380px) {
  .user-support-page.wl-plansaude .cpf-auth.container form {
    width  : 320px;
    height : 288px;
    padding: 18px;
  }
}


/* IF IS PAC19 CUSTOMER - PLANSAUDE */

.user-support-page.wl-pac19 .nav {
  height             : 80px;
  padding            : 0;
  background-color   : #81a9ff;
  background-position: left top;
  background-size    : cover;
  border             : 0;
}

.user-support-page.wl-pac19 .nav>div {
  height   : 80px;
  /* margin: 0 0 ; */
  padding  : 0 220px 0 150px;
}

.user-support-page.wl-pac19 .nav .navbar-nav {
  margin         : 0;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : flex-end;
}

.user-support-page.wl-pac19 .nav .navbar-nav span {
  font-size     : 24px;
  font-family   : "Roboto", sans-serif;
  color         : #fff;
  text-align    : right;
  letter-spacing: 0.4px;
}

@media(max-width: 920px) {
  .user-support-page.wl-pac19 .nav .navbar-nav {
    display: none;
  }
}

@media(max-width: 380px) {
  .user-support-page.wl-pac19 .nav>div {
    padding: 15px 20px 10px 110px;
  }
}

.user-support-page.wl-pac19>.container {
  min-height: calc(100vh - 151px);
}

.user-support-page.wl-pac19>.height_changes {
  min-height    : calc(100vh - 151px);
  display       : flex;
  flex-direction: column;
}

.user-support-page.wl-pac19>.welcome-content {
  position: relative;
}

.user-support-page.wl-pac19>.welcome-content .signout-button {
  position     : absolute;
  top          : 30px;
  right        : 30px;
  cursor       : pointer;
  box-shadow   : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  border-radius: 50%;
}

.user-support-page.wl-pac19 .banner-container {
  flex: 1;
}

.user-support-page.wl-pac19 .banner-container .welcome-call {
  padding: 50px 75px;
}

.user-support-page.wl-pac19 .banner-container .welcome-call>h1 {
  font-size  : 28px;
  font-family: "Roboto", sans-serif;
  color      : #747373;
  text-align : right;
}

.user-support-page.wl-pac19 .banner-container .welcome-call>h1>span {
  font-weight: 500;
  color      : #202529
}

@media(max-width: 550px) {
  .user-support-page.wl-pac19 .banner-container>div:nth-of-type(2) {
    margin-right: 0;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-pac19 .banner-container .welcome-call>h1 {
    font-size: 22px;
  }

  .user-support-page.wl-pac19 .features-item {
    font-size: 12px;
  }
}

.user-support-page.wl-pac19 .banner-container .doctor-banner {
  max-width      : 770px;
  position       : relative;
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items    : flex-end;
  width          : 100%;
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble {
  position        : absolute;
  top             : 85px;
  left            : 0;
  padding         : 18px;
  background-color: #fff;
  border-radius   : 30px;
  border          : solid 1px #eeee;
  box-shadow      : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  display         : flex;
  flex-direction  : row;
  justify-content : flex-start;
  align-items     : center;
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble:after {
  content             : "";
  position            : absolute;
  top                 : 16px;
  right               : -25px;
  z-index             : 1;
  border              : solid 15px transparent;
  border-left-color   : #FFF;
  /* top              : 96px; */
  /* right            : 16px; */
  /* border-left-color: unset; */
  /* border-top-color : #FFF; */
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble>span {
  display         : block;
  background-color: #eeeeee;
  padding         : 13px;
  border-radius   : 50%;
  color           : #383a3d;
  font-size       : 24px;
  line-height     : 24px;
  margin-right    : 18px;
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble>div {
  flex           : 1;
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
  font-family    : "Roboto", sans-serif;
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble>div>h3 {
  font-size: 16px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble>div>a {
  font-size      : 16px;
  color          : #81a9ff;
  text-decoration: underline;
  cursor         : pointer;
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast {
  font-size: 14px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>i,
.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>b {
  margin-right: 5px;
  font-size   : 14px;
  color       : #383a3d;
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-picture {
  max-width: 460px;
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-picture>img {
  border-radius: 30px;
}

.user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-picture>div {
  padding         : 15px 20px;
  border-radius   : 5px;
  background-color: rgba(64, 64, 64, 0.8);
  font-size       : 16px;
  font-family     : "Roboto", sans-serif;
  color           : #f6f6f6;
  position        : absolute;
  right           : 30px;
  bottom          : 5px;
}

@media(max-width: 820px) {
  .user-support-page.wl-pac19 .banner-container .welcome-call>h1 {
    text-align: center;
  }

  .user-support-page.wl-pac19 .banner-container .doctor-banner {
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    margin-bottom  : 50px;
  }

  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble {
    position     : relative;
    top          : unset;
    left         : unset;
    right        : unset;
    margin-bottom: 18px;
    z-index      : 3;
    box-shadow   : 4px 8px 10px 0 rgba(18, 18, 18, 0.16);
  }

  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble:after {
    content         : "";
    position        : absolute;
    top             : unset;
    right           : 16px;
    bottom          : -25px;
    z-index         : 1;
    border          : solid 15px transparent;
    border-top-color: #FFF;
  }

  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-picture {
    position: relative;
    z-index : 1;
  }

  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-picture>div {
    z-index: 2;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble>span {
    padding     : 9px;
    font-size   : 16px;
    line-height : 16px;
    margin-right: 11px;
  }

  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble>div>h3,
  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-talk-bubble>div>a {
    font-size: 14px;
  }

  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-picture {
    max-width: 320px;
  }

  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-picture>img {
    max-width    : 320px;
    border-radius: 30px;
  }
}

/* @media(max-width: 470px) {
  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-picture {
    max-width: calc(100vw-20px);
  }
  .user-support-page.wl-pac19 .banner-container .doctor-banner .doctor-picture > img {
    max-width: calc(100vw-20px);
  }
} */

.user-support-page.wl-pac19 .features-container {
  border-top: solid 1px #f6f6f6;
}

.user-support-page.wl-pac19 .features-list {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  flex-wrap      : wrap;
  padding-top    : 20px;
}

.user-support-page.wl-pac19 .features-item {
  padding-left : 7px;
  padding-right: 7px;
  margin-bottom: 20px;
  word-wrap    : none;
  font-weight  : 500;
}

.user-support-page.wl-pac19 .features-item>i {
  color       : #efa25b;
  margin-right: 7px;
}

@media(max-width: 820px) {
  .user-support-page.wl-pac19 .features-list {
    padding-left   : 30px;
    padding-right  : 30px;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    width          : auto;
  }
}

.user-support-page.wl-pac19 footer {
  min-height         : 70px;
  background-color   : #81a9ff;
  background-position: left top;
  background-size    : cover;
}

.user-support-page.wl-pac19 footer>div {
  padding: 10px 220px 10px 150px;
}

.user-support-page.wl-pac19 footer .social_link {
  margin-left : 60px;
  margin-right: 60px;
}

.user-support-page.wl-pac19 footer .social_link a {
  background-color: #fff;
}

.user-support-page.wl-pac19 footer .social_link a>i {
  color: #246cb2;
}

.user-support-page.wl-pac19 footer a.contact {
  font-size      : 14px;
  font-family    : "Roboto", sans-serif;
  color          : #fff;
  text-decoration: underline;
}

.user-support-page.wl-pac19 footer p {
  font-size  : 14px;
  font-family: "Roboto", sans-serif;
  color      : #fff;
  text-align : right;
}

@media(max-width: 820px) {
  .user-support-page.wl-pac19 footer p {
    display: none;
  }
}

@media(max-width: 550px) {
  .user-support-page.wl-pac19 footer a.contact {
    display: none;
  }
}

@media(max-width: 430px) {
  .user-support-page.wl-pac19 footer>div {
    padding: 10px 20px 10px 60px;
  }

  .user-support-page.wl-pac19 footer .social_link {
    margin-left : 20px;
    margin-right: 20px;
  }
}


.user-support-page.wl-pac19 .btn.btn-warning {
  background-color: #ea862b;
}

.user-support-page.wl-pac19 .btn.btn-primary {
  background-color: #81a9ff;
}

.user-support-page.wl-pac19 .react-rater {
  width : 300px;
  height: 60px;
}

.user-support-page.wl-pac19 .react-rater .react-rater-star {
  color      : #ea862b;
  opacity    : 0.3;
  width      : 60px;
  height     : 60px;
  font-size  : 53px;
  line-height: 60px;
}

.user-support-page.wl-pac19 .react-rater .react-rater-star.is-active,
.user-support-page .react-rater .react-rater-star.will-be-active {
  opacity: 1;
}

.user-support-page.wl-pac19 .cpf-auth.container .row>h5 {
  margin-top   : 50px;
  margin-bottom: 50px;
}

.user-support-page.wl-pac19 .cpf-auth.container .row>p {
  margin-bottom: 41px;
}

.user-support-page.wl-pac19 .cpf-auth.container form {
  background-color: #3c5b9e;
  border-radius   : 5px;
  width           : 580px;
  height          : 279px;
  display         : flex;
  flex-direction  : row;
  justify-content : center;
  align-items     : center;
  flex-wrap       : wrap;
  padding         : 59px 50px;
  margin-bottom   : 70px;
}

.user-support-page.wl-pac19 .cpf-auth.container form .input-container {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
}

.user-support-page.wl-pac19 .cpf-auth.container form .input-container:first-child {
  margin-right: 40px;
}

.user-support-page.wl-pac19 .cpf-auth.container form .input-container label {
  font-size  : 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color      : #fff;
}

.user-support-page.wl-pac19 .cpf-auth.container form .input-container input {
  width           : 220px;
  height          : 40px;
  padding         : 12px 20px;
  border-radius   : 10px;
  box-shadow      : 2px 2px 4px 0 rgba(18, 18, 18, 0.16);
  border          : solid 1px #eeeeee;
  background-color: #ffffff;
  font-family     : "Roboto", sans-serif;
  font-size       : 14px;
  color           : rgba(116, 115, 115, 1);
}

.user-support-page.wl-pac19 .cpf-auth.container form .input-container input::placeholder,
.user-support-page.wl-pac19 .cpf-auth.container form .input-container input::-moz-placeholder,
.user-support-page.wl-pac19 .cpf-auth.container form .input-container input::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size  : 14px;
  color      : rgba(116, 115, 115, 0.6);
}

.user-support-page.wl-pac19 .cpf-auth.container form .button-container {
  position       : relative;
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.user-support-page.wl-pac19 .cpf-auth.container form .button-container button {
  width           : 200px;
  height          : 40px;
  border-radius   : 6px;
  box-shadow      : 2px 2px 4px 0 rgba(234, 134, 43, 0.2);
  border          : solid 1px #ea862b;
  background-color: #ea862b;
  font-size       : 16px;
  font-weight     : 500;
  font-family     : "Roboto", sans-serif;
  color           : #fff;
}

.user-support-page.wl-pac19 .cpf-auth.container form .button-container button:hover {
  background-color: #c47023;
}

.user-support-page.wl-pac19 .cpf-auth.container form .button-container button:disabled {
  background-color: #aca6a1;
  border          : solid 1px #aca6a1;
}

.user-support-page.wl-pac19 .cpf-auth.container form .button-container p {
  position   : absolute;
  font-size  : 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color      : #fff;
  margin     : 0;
  bottom     : -55px;
  left       : auto;
}

@media(max-width: 600px) {
  .user-support-page.wl-pac19 .cpf-auth.container form {
    width  : 355px;
    height : 320px;
    padding: 28px;
  }

  .user-support-page.wl-pac19 .cpf-auth.container form .input-container {
    margin-bottom: 25px;
  }

  .user-support-page.wl-pac19 .cpf-auth.container form .input-container:first-child {
    margin-right: 0;
  }

  .user-support-page.wl-pac19 .cpf-auth.container form .button-container {
    margin-bottom: 20px;
  }

  .user-support-page.wl-pac19 .cpf-auth.container form .button-container p {
    bottom: -100px;
  }
}

@media(max-width: 380px) {
  .user-support-page.wl-pac19 .cpf-auth.container form {
    width  : 320px;
    height : 288px;
    padding: 18px;
  }
}


/* IF IS NORI CLIENT */

.nori-header-content {
  max-width: 1000px;
  width    : 100%;

}

.nori-header {
  background-color: #263339;
  color           : white;
  display         : flex;
  justify-content : center;
}

.nori-footer {
  background-color: #263339;
  color           : white;
  padding         : 16px;
}

/*
.user-support-page.wl-nori .nav {
  height: 80px;
  padding: 0;
  background-color: #263339;
  background-position: left top;
  background-size: cover;
  border: 0;
}
.user-support-page.wl-nori .nav > div {
  height: 80px;
  padding: 15px 220px 10px 150px;
}
.user-support-page.wl-nori .nav .navbar-nav {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.user-support-page.wl-nori .nav .navbar-nav span {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: right;
  letter-spacing: 0.4px;
}
@media(max-width: 920px) {
  .user-support-page.wl-nori .nav .navbar-nav {
    display: none;
  }
}
@media(max-width: 380px) {
  .user-support-page.wl-nori .nav > div {
    padding: 15px 20px 10px 110px;
  }
} */

.user-support-page.wl-nori>.container {
  min-height: calc(100vh - 151px);
}

.user-support-page.wl-nori>.height_changes {
  min-height    : calc(100vh - 151px);
  display       : flex;
  flex-direction: column;
}

.user-support-page.wl-nori>.welcome-content {
  position: relative;
}

.user-support-page.wl-nori>.welcome-content .signout-button {
  position     : absolute;
  top          : 30px;
  right        : 30px;
  cursor       : pointer;
  box-shadow   : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  border-radius: 50%;
}

.user-support-page.wl-nori .banner-container {
  flex: 1;
}

.user-support-page.wl-nori .banner-container .welcome-call {
  padding: 50px 75px;
}

.user-support-page.wl-nori .banner-container .welcome-call>h1 {
  font-size  : 28px;
  font-family: "Roboto", sans-serif;
  color      : #747373;
  text-align : right;
}

.user-support-page.wl-nori .banner-container .welcome-call>h1>span {
  font-weight: 500;
  color      : #202529
}

@media(max-width: 550px) {
  .user-support-page.wl-nori .banner-container>div:nth-of-type(2) {
    margin-right: 0;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-nori .banner-container .welcome-call>h1 {
    font-size: 22px;
  }

  .user-support-page.wl-nori .features-item {
    font-size: 12px;
  }
}

.user-support-page.wl-nori .banner-container .doctor-banner {
  max-width      : 770px;
  position       : relative;
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items    : flex-end;
  width          : 100%;
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble {
  position        : absolute;
  top             : 85px;
  left            : 0;
  padding         : 18px;
  background-color: #fff;
  border-radius   : 30px;
  border          : solid 1px #eeee;
  box-shadow      : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  display         : flex;
  flex-direction  : row;
  justify-content : flex-start;
  align-items     : center;
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble:after {
  content             : "";
  position            : absolute;
  top                 : 16px;
  right               : -25px;
  z-index             : 1;
  border              : solid 15px transparent;
  border-left-color   : #FFF;
  /* top              : 96px; */
  /* right            : 16px; */
  /* border-left-color: unset; */
  /* border-top-color : #FFF; */
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble>span {
  display         : block;
  background-color: #eeeeee;
  padding         : 13px;
  border-radius   : 50%;
  color           : #383a3d;
  font-size       : 24px;
  line-height     : 24px;
  margin-right    : 18px;
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble>div {
  flex           : 1;
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
  font-family    : "Roboto", sans-serif;
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble>div>h3 {
  font-size: 16px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble>div>a {
  font-size      : 16px;
  color          : #006cb3;
  text-decoration: underline;
  cursor         : pointer;
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast {
  font-size: 14px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>i,
.user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>b {
  margin-right: 5px;
  font-size   : 14px;
  color       : #383a3d;
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-picture {
  max-width: 460px;
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-picture>img {
  border-radius: 30px;
}

.user-support-page.wl-nori .banner-container .doctor-banner .doctor-picture>div {
  padding         : 15px 20px;
  border-radius   : 5px;
  background-color: rgba(64, 64, 64, 0.8);
  font-size       : 16px;
  font-family     : "Roboto", sans-serif;
  color           : #f6f6f6;
  position        : absolute;
  right           : 30px;
  bottom          : 5px;
}

@media(max-width: 820px) {
  .user-support-page.wl-nori .banner-container .welcome-call>h1 {
    text-align: center;
  }

  .user-support-page.wl-nori .banner-container .doctor-banner {
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    margin-bottom  : 50px;
  }

  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble {
    position     : relative;
    top          : unset;
    left         : unset;
    right        : unset;
    margin-bottom: 18px;
    z-index      : 3;
    box-shadow   : 4px 8px 10px 0 rgba(18, 18, 18, 0.16);
  }

  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble:after {
    content         : "";
    position        : absolute;
    top             : unset;
    right           : 16px;
    bottom          : -25px;
    z-index         : 1;
    border          : solid 15px transparent;
    border-top-color: #FFF;
  }

  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-picture {
    position: relative;
    z-index : 1;
  }

  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-picture>div {
    z-index: 2;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble>span {
    padding     : 9px;
    font-size   : 16px;
    line-height : 16px;
    margin-right: 11px;
  }

  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble>div>h3,
  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-talk-bubble>div>a {
    font-size: 14px;
  }

  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-picture {
    max-width: 320px;
  }

  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-picture>img {
    max-width    : 320px;
    border-radius: 30px;
  }
}

/* @media(max-width: 470px) {
  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-picture {
    max-width: calc(100vw-20px);
  }
  .user-support-page.wl-nori .banner-container .doctor-banner .doctor-picture > img {
    max-width: calc(100vw-20px);
  }
} */

.user-support-page.wl-nori .features-container {
  border-top: solid 1px #f6f6f6;
}

.user-support-page.wl-nori .features-list {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  flex-wrap      : wrap;
  padding-top    : 20px;
}

.user-support-page.wl-nori .features-item {
  padding-left : 7px;
  padding-right: 7px;
  margin-bottom: 20px;
  word-wrap    : none;
  font-weight  : 500;
}

.user-support-page.wl-nori .features-item>i {
  color       : #efa25b;
  margin-right: 7px;
}

@media(max-width: 820px) {
  .user-support-page.wl-nori .features-list {
    padding-left   : 30px;
    padding-right  : 30px;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    width          : auto;
  }
}

/* .user-support-page.wl-nori footer {
  min-height: 70px;
  background-color: #195090;
  background-image: url(/assets/image/plansaude/background-bar.png);
  background-position: left top;
  background-size: cover;
}
.user-support-page.wl-nori footer > div {
  padding: 10px 220px 10px 150px;
}
.user-support-page.wl-nori footer .social_link {
  margin-left: 60px;
  margin-right: 60px;
}
.user-support-page.wl-nori footer .social_link a {
  background-color: #fff;
}
.user-support-page.wl-nori footer .social_link a > i {
  color: #246cb2;
}
.user-support-page.wl-nori footer a.contact {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-decoration: underline;
}
.user-support-page.wl-nori footer p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: right;
}
@media(max-width: 820px) {
  .user-support-page.wl-nori footer p {
    display: none;
  }
}
@media(max-width: 550px) {
  .user-support-page.wl-nori footer a.contact {
    display: none;
  }
}
@media(max-width: 430px) {
  .user-support-page.wl-nori footer > div {
    padding: 10px 20px 10px 60px;
  }
  .user-support-page.wl-nori footer .social_link {
    margin-left: 20px;
    margin-right: 20px;
  }
} */


.user-support-page.wl-nori .btn.btn-warning {
  background-color: #ea862b;
}

.user-support-page.wl-nori .btn.btn-primary {
  background-color: #006cb3;
}

.user-support-page.wl-nori .react-rater {
  width : 300px;
  height: 60px;
}

.user-support-page.wl-nori .react-rater .react-rater-star {
  color      : #ea862b;
  opacity    : 0.3;
  width      : 60px;
  height     : 60px;
  font-size  : 53px;
  line-height: 60px;
}

.user-support-page.wl-nori .react-rater .react-rater-star.is-active,
.user-support-page .react-rater .react-rater-star.will-be-active {
  opacity: 1;
}

.user-support-page.wl-nori .cpf-auth.container .row>h5 {
  margin-top   : 50px;
  margin-bottom: 50px;
}

.user-support-page.wl-nori .cpf-auth.container .row>p {
  margin-bottom: 41px;
}

.user-support-page.wl-nori .cpf-auth.container form {
  width           : 580px;
  border-radius   : 8px;
  background-color: #ECEFF1;
  height          : 279px;
  display         : flex;
  flex-direction  : row;
  justify-content : center;
  align-items     : center;
  flex-wrap       : wrap;
  padding         : 59px 50px;
  margin-bottom   : 70px;
}

.user-support-page.wl-nori .cpf-auth.container form .input-container {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
}

.user-support-page.wl-nori .cpf-auth.container form .input-container:first-child {
  margin-right: 40px;
}

.user-support-page.wl-nori .cpf-auth.container form .input-container label {
  font-size  : 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color      : #303030;
}

.user-support-page.wl-nori .cpf-auth.container form .input-container input {
  width           : 220px;
  height          : 40px;
  padding         : 12px 20px;
  border-radius   : 10px;
  box-shadow      : 2px 2px 4px 0 rgba(18, 18, 18, 0.16);
  border          : solid 1px #eeeeee;
  background-color: #ffffff;
  font-family     : "Roboto", sans-serif;
  font-size       : 14px;
  color           : rgba(116, 115, 115, 1);
}

.user-support-page.wl-nori .cpf-auth.container form .input-container input::placeholder,
.user-support-page.wl-nori .cpf-auth.container form .input-container input::-moz-placeholder,
.user-support-page.wl-nori .cpf-auth.container form .input-container input::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size  : 14px;
  color      : rgba(116, 115, 115, 0.6);
}

.user-support-page.wl-nori .cpf-auth.container form .button-container {
  position       : relative;
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.user-support-page.wl-nori .cpf-auth.container form .button-container button {
  width           : 200px;
  height          : 40px;
  border-radius   : 6px;
  border          : solid 1px #263339;
  background-color: #263339;
  font-size       : 16px;
  font-weight     : 500;
  font-family     : "Roboto", sans-serif;
  color           : #fff;
}

.user-support-page.wl-nori .cpf-auth.container form .button-container button:hover {
  background-color: #111619;
}

.user-support-page.wl-nori .cpf-auth.container form .button-container button:disabled {
  background-color: #939596;
  border          : solid 1px #939596;
}

.user-support-page.wl-nori .cpf-auth.container form .button-container p {
  position   : absolute;
  font-size  : 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color      : #fff;
  margin     : 0;
  bottom     : -55px;
  left       : auto;
}

@media(max-width: 600px) {
  .user-support-page.wl-nori .cpf-auth.container form {
    /* background-image: url(/assets/image/plansaude/background-auth-column.png); */
    width  : 355px;
    height : 320px;
    padding: 28px;
  }

  .user-support-page.wl-nori .cpf-auth.container form .input-container {
    margin-bottom: 25px;
  }

  .user-support-page.wl-nori .cpf-auth.container form .input-container:first-child {
    margin-right: 0;
  }

  .user-support-page.wl-nori .cpf-auth.container form .button-container {
    margin-bottom: 20px;
  }

  .user-support-page.wl-nori .cpf-auth.container form .button-container p {
    bottom: -100px;
  }
}

@media(max-width: 380px) {
  .user-support-page.wl-nori .cpf-auth.container form {
    width  : 320px;
    height : 288px;
    padding: 18px;
  }
}


/* IF IS SESIdf CLIENT */

/* IF IS sesidfSP CLIENT */

.sesidf-header-content {
  max-width: 1000px;
  width    : 100%;

}

.sesidf-header {
  background-color: #50235D;
  border          : 1px solid #50235D;
  color           : white;
  display         : flex;
  justify-content : center;
}

.sesidf-footer {
  background-color: #50235D;
  color           : white;
  padding         : 16px;
  border          : 1px solid black;
}

.sesidf-footer a {
  color: white;
}

.sesidf-logout {
  width     : 100%;
  text-align: right;
  padding   : 24px;
  font-size : 18px;
  cursor    : pointer;
}

.sesidf-logout img {
  margin-left: 8px;
}

@media(max-width: 820px) {
  .user-support-page.wl-sesidf footer p {
    display: none;
  }
}

@media(max-width: 550px) {
  .user-support-page.wl-sesidf footer a.contact {
    display: none;
  }
}

@media(max-width: 430px) {
  .user-support-page.wl-sesidf footer>div {
    padding: 10px 20px 10px 60px;
  }

  .user-support-page.wl-sesidf footer .social_link {
    margin-left : 20px;
    margin-right: 20px;
  }
}

.user-support-page.wl-sesidf>.container {
  min-height: calc(100vh - 151px);
}

.user-support-page.wl-sesidf>.height_changes {
  min-height    : calc(100vh - 151px);
  display       : flex;
  flex-direction: column;
}

.user-support-page.wl-sesidf>.welcome-content {
  position: relative;
}

.user-support-page.wl-sesidf>.welcome-content .signout-button {
  position     : absolute;
  top          : 30px;
  right        : 30px;
  cursor       : pointer;
  box-shadow   : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  border-radius: 50%;
}

.user-support-page.wl-sesidf .banner-container {
  flex: 1;
}

.user-support-page.wl-sesidf .banner-container .welcome-call {
  padding-top: 0;
  display    : block;
}

.user-support-page.wl-sesidf .banner-container .welcome-call>h1 {
  font-size  : 28px;
  font-family: "Roboto", sans-serif;
  color      : #747373;
  text-align : right;
}

.user-support-page.wl-sesidf .banner-container .welcome-call>h1>span {
  font-weight: 500;
  color      : #202529
}

@media(max-width: 550px) {
  .user-support-page.wl-sesidf .banner-container>div:nth-of-type(2) {
    margin-right: 0;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-sesidf .banner-container .welcome-call>h1 {
    font-size: 22px;
  }

  .user-support-page.wl-sesidf .features-item {
    font-size: 12px;
  }
}

.user-support-page.wl-sesidf .banner-container .doctor-banner {
  max-width      : 770px;
  position       : relative;
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items    : flex-end;
  width          : 100%;
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble {
  position        : absolute;
  top             : 85px;
  left            : 0;
  padding         : 18px;
  background-color: #fff;
  border-radius   : 30px;
  border          : solid 1px #eeee;
  box-shadow      : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  display         : flex;
  flex-direction  : row;
  justify-content : flex-start;
  align-items     : center;
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble:after {
  content             : "";
  position            : absolute;
  top                 : 16px;
  right               : -25px;
  z-index             : 1;
  border              : solid 15px transparent;
  border-left-color   : #FFF;
  /* top              : 96px; */
  /* right            : 16px; */
  /* border-left-color: unset; */
  /* border-top-color : #FFF; */
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble>span {
  display         : block;
  background-color: #eeeeee;
  padding         : 13px;
  border-radius   : 50%;
  color           : #383a3d;
  font-size       : 24px;
  line-height     : 24px;
  margin-right    : 18px;
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble>div {
  flex           : 1;
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
  font-family    : "Roboto", sans-serif;
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble>div>h3 {
  font-size: 16px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble>div>a {
  font-size      : 16px;
  color          : #006cb3;
  text-decoration: underline;
  cursor         : pointer;
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast {
  font-size: 14px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>i,
.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>b {
  margin-right: 5px;
  font-size   : 14px;
  color       : #383a3d;
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-picture {
  max-width: 460px;
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-picture>img {
  border-radius: 30px;
}

.user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-picture>div {
  padding         : 15px 20px;
  border-radius   : 5px;
  background-color: rgba(64, 64, 64, 0.8);
  font-size       : 16px;
  font-family     : "Roboto", sans-serif;
  color           : #f6f6f6;
  position        : absolute;
  right           : 30px;
  bottom          : 5px;
}

@media(max-width: 820px) {
  .user-support-page.wl-sesidf .banner-container .welcome-call>h1 {
    text-align: center;
  }

  .user-support-page.wl-sesidf .banner-container .doctor-banner {
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    margin-bottom  : 50px;
  }

  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble {
    position     : relative;
    top          : unset;
    left         : unset;
    right        : unset;
    margin-bottom: 18px;
    z-index      : 3;
    box-shadow   : 4px 8px 10px 0 rgba(18, 18, 18, 0.16);
  }

  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble:after {
    content         : "";
    position        : absolute;
    top             : unset;
    right           : 16px;
    bottom          : -25px;
    z-index         : 1;
    border          : solid 15px transparent;
    border-top-color: #FFF;
  }

  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-picture {
    position: relative;
    z-index : 1;
  }

  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-picture>div {
    z-index: 2;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble>span {
    padding     : 9px;
    font-size   : 16px;
    line-height : 16px;
    margin-right: 11px;
  }

  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble>div>h3,
  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-talk-bubble>div>a {
    font-size: 14px;
  }

  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-picture {
    max-width: 320px;
  }

  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-picture>img {
    max-width    : 320px;
    border-radius: 30px;
  }
}

/* @media(max-width: 470px) {
  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-picture {
    max-width: calc(100vw-20px);
  }
  .user-support-page.wl-sesidf .banner-container .doctor-banner .doctor-picture > img {
    max-width: calc(100vw-20px);
  }
} */

.user-support-page.wl-sesidf .features-container {
  border-top: solid 1px #f6f6f6;
}

.user-support-page.wl-sesidf .features-list {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  flex-wrap      : wrap;
  padding-top    : 20px;
}

.user-support-page.wl-sesidf .features-item {
  padding-left : 7px;
  padding-right: 7px;
  margin-bottom: 20px;
  word-wrap    : none;
  font-weight  : 500;
}

.user-support-page.wl-sesidf .features-item>i {
  color       : #efa25b;
  margin-right: 7px;
}

@media(max-width: 820px) {
  .user-support-page.wl-sesidf .features-list {
    padding-left   : 30px;
    padding-right  : 30px;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    width          : auto;
  }
}

/* .user-support-page.wl-sesidf footer {
  min-height: 70px;
  background-color: #195090;
  background-image: url(/assets/image/plansaude/background-bar.png);
  background-position: left top;
  background-size: cover;
}
.user-support-page.wl-sesidf footer > div {
  padding: 10px 220px 10px 150px;
}
.user-support-page.wl-sesidf footer .social_link {
  margin-left: 60px;
  margin-right: 60px;
}
.user-support-page.wl-sesidf footer .social_link a {
  background-color: #fff;
}
.user-support-page.wl-sesidf footer .social_link a > i {
  color: #246cb2;
}
.user-support-page.wl-sesidf footer a.contact {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-decoration: underline;
}
.user-support-page.wl-sesidf footer p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: right;
}
@media(max-width: 820px) {
  .user-support-page.wl-sesidf footer p {
    display: none;
  }
}
@media(max-width: 550px) {
  .user-support-page.wl-sesidf footer a.contact {
    display: none;
  }
}
@media(max-width: 430px) {
  .user-support-page.wl-sesidf footer > div {
    padding: 10px 20px 10px 60px;
  }
  .user-support-page.wl-sesidf footer .social_link {
    margin-left: 20px;
    margin-right: 20px;
  }
} */


.user-support-page.wl-sesidf .btn.btn-warning {
  background-color: #ea862b;
}

.user-support-page.wl-sesidf .btn.btn-primary {
  background-color: #006cb3;
}

.user-support-page.wl-sesidf .react-rater {
  width : 300px;
  height: 60px;
}

.user-support-page.wl-sesidf .react-rater .react-rater-star {
  color      : #0B9DD6;
  opacity    : 0.3;
  width      : 60px;
  height     : 60px;
  font-size  : 53px;
  line-height: 60px;
}

.user-support-page.wl-sesidf .react-rater .react-rater-star.is-active,
.user-support-page .react-rater .react-rater-star.will-be-active {
  opacity: 1;
}

.user-support-page.wl-sesidf .cpf-auth.container .row>h5 {
  margin-top   : 50px;
  margin-bottom: 50px;
}

.user-support-page.wl-sesidf .cpf-auth.container .row>p {
  margin-bottom: 41px;
}

.user-support-page.wl-sesidf .cpf-auth.container form {
  width           : 580px;
  border-radius   : 8px;
  background-color: #50235D;
  height          : 279px;
  display         : flex;
  flex-direction  : row;
  justify-content : center;
  align-items     : center;
  flex-wrap       : wrap;
  padding         : 59px 50px;
  margin-bottom   : 70px;
}

.user-support-page.wl-sesidf .cpf-auth.container form .input-container {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
}

.user-support-page.wl-sesidf .cpf-auth.container form .input-container:first-child {
  margin-right: 40px;
}

.user-support-page.wl-sesidf .cpf-auth.container form .input-container label {
  font-size  : 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color      : white;
}

.user-support-page.wl-sesidf .cpf-auth.container form .input-container input {
  width           : 220px;
  height          : 40px;
  padding         : 12px 20px;
  border-radius   : 10px;
  box-shadow      : 2px 2px 4px 0 rgba(18, 18, 18, 0.16);
  border          : solid 1px #eeeeee;
  background-color: #ffffff;
  font-family     : "Roboto", sans-serif;
  font-size       : 14px;
  color           : rgba(116, 115, 115, 1);
}

.user-support-page.wl-sesidf .cpf-auth.container form .input-container input::placeholder,
.user-support-page.wl-sesidf .cpf-auth.container form .input-container input::-moz-placeholder,
.user-support-page.wl-sesidf .cpf-auth.container form .input-container input::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size  : 14px;
  color      : rgba(116, 115, 115, 0.6);
}

.user-support-page.wl-sesidf .cpf-auth.container form .button-container {
  position       : relative;
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.user-support-page.wl-sesidf .cpf-auth.container form .button-container button {
  width           : 200px;
  height          : 40px;
  border-radius   : 6px;
  border          : solid 1px #fff;
  background-color: #fff;
  font-size       : 16px;
  cursor          : pointer;
  font-weight     : 500;
  font-family     : "Roboto", sans-serif;
  color           : #50235D;
  transition      : 0.3s ease-in;
}

.user-support-page.wl-sesidf .cpf-auth.container form .button-container button:hover {
  background-color: #4b005f;
  border-color    : #4b005f;
  color           : #fff;
}

.user-support-page.wl-sesidf .cpf-auth.container form .button-container button:disabled {
  background-color: #fff;
  color           : #50235D;
  border          : solid 1px #fff;
}

.user-support-page.wl-sesidf .cpf-auth.container form .button-container p {
  position   : absolute;
  font-size  : 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color      : #fff !important;
  margin     : 0;
  bottom     : -55px;
  left       : auto;
}

.homewrapper .homepage-image {
  position: absolute;
  top     : -8% !important;
  left    : 200px !important;
}

.homewrapper .homepage-image img {
  min-width: 280px;
  opacity  : 0.5;
}

.homewrapper {
  position: relative;
}

@media(max-width: 600px) {
  .user-support-page.wl-sesidf .cpf-auth.container form {
    background-color: #50235D;
    width           : 355px;
    height          : 320px;
    padding         : 28px;
  }

  .user-support-page.wl-sesidf .cpf-auth.container form .input-container {
    margin-bottom: 25px;
  }

  .user-support-page.wl-sesidf .cpf-auth.container form .input-container:first-child {
    margin-right: 0;
  }

  .user-support-page.wl-sesidf .cpf-auth.container form .button-container {
    margin-bottom: 20px;
  }

  .user-support-page.wl-sesidf .cpf-auth.container form .button-container p {
    bottom: -114px;
    color : #dc3545 !important;
  }
}

@media(max-width: 380px) {
  .user-support-page.wl-sesidf .cpf-auth.container form {
    width  : 320px;
    height : 288px;
    padding: 18px;
  }
}


/* IF IS SESIspSP CLIENT */

.sesisp-header-content {
  max-width: 1000px;
  width    : 100%;

}

.sesisp-header {
  background-color: #da291c;
  border          : 1px solid #da291c;
  color           : white;
  display         : flex;
  justify-content : center;
}

.sesisp-footer {
  background-color: #da291c;
  color           : white;
  padding         : 16px;
  border          : 1px solid black;
}

.sesisp-footer a {
  color: white;
}

.sesisp-logout {
  width     : 100%;
  text-align: right;
  padding   : 24px;
  font-size : 18px;
  cursor    : pointer;
}

.sesisp-logout img {
  margin-left: 8px;
}

@media(max-width: 820px) {
  .user-support-page.wl-sesisp footer p {
    display: none;
  }
}

@media(max-width: 550px) {
  .user-support-page.wl-sesisp footer a.contact {
    display: none;
  }
}

@media(max-width: 430px) {
  .user-support-page.wl-sesisp footer>div {
    padding: 10px 20px 10px 60px;
  }

  .user-support-page.wl-sesisp footer .social_link {
    margin-left : 20px;
    margin-right: 20px;
  }
}

.user-support-page.wl-sesisp>.container {
  min-height: calc(100vh - 151px);
}

.user-support-page.wl-sesisp>.height_changes {
  min-height    : calc(100vh - 151px);
  display       : flex;
  flex-direction: column;
}

.user-support-page.wl-sesisp>.welcome-content {
  position: relative;
}

.user-support-page.wl-sesisp>.welcome-content .signout-button {
  position     : absolute;
  top          : 30px;
  right        : 30px;
  cursor       : pointer;
  box-shadow   : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  border-radius: 50%;
}

.user-support-page.wl-sesisp .banner-container {
  flex: 1;
}

.user-support-page.wl-sesisp .banner-container .welcome-call {
  padding: 50px 75px;
}

.user-support-page.wl-sesisp .banner-container .welcome-call>h1 {
  font-size  : 28px;
  font-family: "Roboto", sans-serif;
  color      : #747373;
  text-align : right;
}

.user-support-page.wl-sesisp .banner-container .welcome-call>h1>span {
  font-weight: 500;
  color      : #202529
}

@media(max-width: 550px) {
  .user-support-page.wl-sesisp .banner-container>div:nth-of-type(2) {
    margin-right: 0;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-sesisp .banner-container .welcome-call>h1 {
    font-size: 22px;
  }

  .user-support-page.wl-sesisp .features-item {
    font-size: 12px;
  }
}

.user-support-page.wl-sesisp .banner-container .doctor-banner {
  max-width      : 770px;
  position       : relative;
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items    : flex-end;
  width          : 100%;
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble {
  position        : absolute;
  top             : 85px;
  left            : 0;
  padding         : 18px;
  background-color: #fff;
  border-radius   : 30px;
  border          : solid 1px #eeee;
  box-shadow      : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  display         : flex;
  flex-direction  : row;
  justify-content : flex-start;
  align-items     : center;
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble:after {
  content             : "";
  position            : absolute;
  top                 : 16px;
  right               : -25px;
  z-index             : 1;
  border              : solid 15px transparent;
  border-left-color   : #FFF;
  /* top              : 96px; */
  /* right            : 16px; */
  /* border-left-color: unset; */
  /* border-top-color : #FFF; */
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble>span {
  display         : block;
  background-color: #eeeeee;
  padding         : 13px;
  border-radius   : 50%;
  color           : #383a3d;
  font-size       : 24px;
  line-height     : 24px;
  margin-right    : 18px;
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble>div {
  flex           : 1;
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
  font-family    : "Roboto", sans-serif;
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble>div>h3 {
  font-size: 16px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble>div>a {
  font-size      : 16px;
  color          : #006cb3;
  text-decoration: underline;
  cursor         : pointer;
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast {
  font-size: 14px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>i,
.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>b {
  margin-right: 5px;
  font-size   : 14px;
  color       : #383a3d;
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-picture {
  max-width: 460px;
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-picture>img {
  border-radius: 30px;
}

.user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-picture>div {
  padding         : 15px 20px;
  border-radius   : 5px;
  background-color: rgba(64, 64, 64, 0.8);
  font-size       : 16px;
  font-family     : "Roboto", sans-serif;
  color           : #f6f6f6;
  position        : absolute;
  right           : 30px;
  bottom          : 5px;
}

@media(max-width: 820px) {
  .user-support-page.wl-sesisp .banner-container .welcome-call>h1 {
    text-align: center;
  }

  .user-support-page.wl-sesisp .banner-container .doctor-banner {
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    margin-bottom  : 50px;
  }

  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble {
    position     : relative;
    top          : unset;
    left         : unset;
    right        : unset;
    margin-bottom: 18px;
    z-index      : 3;
    box-shadow   : 4px 8px 10px 0 rgba(18, 18, 18, 0.16);
  }

  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble:after {
    content         : "";
    position        : absolute;
    top             : unset;
    right           : 16px;
    bottom          : -25px;
    z-index         : 1;
    border          : solid 15px transparent;
    border-top-color: #FFF;
  }

  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-picture {
    position: relative;
    z-index : 1;
  }

  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-picture>div {
    z-index: 2;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble>span {
    padding     : 9px;
    font-size   : 16px;
    line-height : 16px;
    margin-right: 11px;
  }

  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble>div>h3,
  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-talk-bubble>div>a {
    font-size: 14px;
  }

  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-picture {
    max-width: 320px;
  }

  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-picture>img {
    max-width    : 320px;
    border-radius: 30px;
  }
}

/* @media(max-width: 470px) {
  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-picture {
    max-width: calc(100vw-20px);
  }
  .user-support-page.wl-sesisp .banner-container .doctor-banner .doctor-picture > img {
    max-width: calc(100vw-20px);
  }
} */

.user-support-page.wl-sesisp .features-container {
  border-top: solid 1px #f6f6f6;
}

.user-support-page.wl-sesisp .features-list {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  flex-wrap      : wrap;
  padding-top    : 20px;
}

.user-support-page.wl-sesisp .features-item {
  padding-left : 7px;
  padding-right: 7px;
  margin-bottom: 20px;
  word-wrap    : none;
  font-weight  : 500;
}

.user-support-page.wl-sesisp .features-item>i {
  color       : #efa25b;
  margin-right: 7px;
}

@media(max-width: 820px) {
  .user-support-page.wl-sesisp .features-list {
    padding-left   : 30px;
    padding-right  : 30px;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    width          : auto;
  }
}

/* .user-support-page.wl-sesisp footer {
  min-height: 70px;
  background-color: #195090;
  background-image: url(/assets/image/plansaude/background-bar.png);
  background-position: left top;
  background-size: cover;
}
.user-support-page.wl-sesisp footer > div {
  padding: 10px 220px 10px 150px;
}
.user-support-page.wl-sesisp footer .social_link {
  margin-left: 60px;
  margin-right: 60px;
}
.user-support-page.wl-sesisp footer .social_link a {
  background-color: #fff;
}
.user-support-page.wl-sesisp footer .social_link a > i {
  color: #246cb2;
}
.user-support-page.wl-sesisp footer a.contact {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-decoration: underline;
}
.user-support-page.wl-sesisp footer p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: right;
}
@media(max-width: 820px) {
  .user-support-page.wl-sesisp footer p {
    display: none;
  }
}
@media(max-width: 550px) {
  .user-support-page.wl-sesisp footer a.contact {
    display: none;
  }
}
@media(max-width: 430px) {
  .user-support-page.wl-sesisp footer > div {
    padding: 10px 20px 10px 60px;
  }
  .user-support-page.wl-sesisp footer .social_link {
    margin-left: 20px;
    margin-right: 20px;
  }
} */


.user-support-page.wl-sesisp .btn.btn-warning {
  background-color: #ea862b;
}

.user-support-page.wl-sesisp .btn.btn-primary {
  background-color: #006cb3;
}

.user-support-page.wl-sesisp .react-rater {
  width : 300px;
  height: 60px;
}

.user-support-page.wl-sesisp .react-rater .react-rater-star {
  color      : #ea862b;
  opacity    : 0.3;
  width      : 60px;
  height     : 60px;
  font-size  : 53px;
  line-height: 60px;
}

.user-support-page.wl-sesisp .react-rater .react-rater-star.is-active,
.user-support-page .react-rater .react-rater-star.will-be-active {
  opacity: 1;
}

.user-support-page.wl-sesisp .cpf-auth.container .row>h5 {
  margin-top   : 50px;
  margin-bottom: 50px;
}

.user-support-page.wl-sesisp .cpf-auth.container .row>p {
  margin-bottom: 41px;
}

.user-support-page.wl-sesisp .cpf-auth.container form {
  width           : 580px;
  border-radius   : 8px;
  background-color: #da291c;
  height          : 279px;
  display         : flex;
  flex-direction  : row;
  justify-content : center;
  align-items     : center;
  flex-wrap       : wrap;
  padding         : 59px 50px;
  margin-bottom   : 70px;
}

.user-support-page.wl-sesisp .cpf-auth.container form .input-container {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
}

.user-support-page.wl-sesisp .cpf-auth.container form .input-container:first-child {
  margin-right: 40px;
}

.user-support-page.wl-sesisp .cpf-auth.container form .input-container label {
  font-size  : 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color      : white;
}

.user-support-page.wl-sesisp .cpf-auth.container form .input-container input {
  width           : 220px;
  height          : 40px;
  padding         : 12px 20px;
  border-radius   : 10px;
  box-shadow      : 2px 2px 4px 0 rgba(18, 18, 18, 0.16);
  border          : solid 1px #eeeeee;
  background-color: #ffffff;
  font-family     : "Roboto", sans-serif;
  font-size       : 14px;
  color           : rgba(116, 115, 115, 1);
}

.user-support-page.wl-sesisp .cpf-auth.container form .input-container input::placeholder,
.user-support-page.wl-sesisp .cpf-auth.container form .input-container input::-moz-placeholder,
.user-support-page.wl-sesisp .cpf-auth.container form .input-container input::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size  : 14px;
  color      : rgba(116, 115, 115, 0.6);
}

.user-support-page.wl-sesisp .cpf-auth.container form .button-container {
  position       : relative;
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.user-support-page.wl-sesisp .cpf-auth.container form .button-container button {
  width           : 200px;
  height          : 40px;
  border-radius   : 6px;
  border          : solid 1px #fff;
  background-color: #fff;
  font-size       : 16px;
  cursor          : pointer;
  font-weight     : 500;
  font-family     : "Roboto", sans-serif;
  color           : #da291c;
  transition      : 0.3s ease-in;
}

.user-support-page.wl-sesisp .cpf-auth.container form .button-container button:hover {
  background-color: #c5001a;
  border-color    : #c5001a;
  color           : #fff;
}

.user-support-page.wl-sesisp .cpf-auth.container form .button-container button:disabled {
  background-color: #fff;
  color           : #da291c;
  border          : solid 1px #fff;
}

.user-support-page.wl-sesisp .cpf-auth.container form .button-container p {
  position   : absolute;
  font-size  : 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color      : #fff !important;
  margin     : 0;
  bottom     : -55px;
  left       : auto;
}

@media(max-width: 600px) {
  .user-support-page.wl-sesisp .cpf-auth.container form {
    background-color: #da291c;
    width           : 355px;
    height          : 320px;
    padding         : 28px;
  }

  .user-support-page.wl-sesisp .cpf-auth.container form .input-container {
    margin-bottom: 25px;
  }

  .user-support-page.wl-sesisp .cpf-auth.container form .input-container:first-child {
    margin-right: 0;
  }

  .user-support-page.wl-sesisp .cpf-auth.container form .button-container {
    margin-bottom: 20px;
  }

  .user-support-page.wl-sesisp .cpf-auth.container form .button-container p {
    bottom: -114px;
    color : #dc3545 !important;
  }
}

@media(max-width: 380px) {
  .user-support-page.wl-sesisp .cpf-auth.container form {
    width  : 320px;
    height : 288px;
    padding: 18px;
  }
}




/* IF IS SIRIO LIBANES CORPORATION */

.siriolibanes-header-content {
  max-width: 1000px;
  width    : 100%;

}

.siriolibanes-header {
  background-color: #757575;
  color           : #757575;
  display         : flex;
  justify-content : center;
}

.siriolibanes-footer {
  background-color: white;
  color           : #757575;
  padding         : 16px;
}

.siriolibanes-footer a {
  color: white;
}

.siriolibanes-logout {
  width     : 100%;
  text-align: right;
  padding   : 24px;
  font-size : 18px;
  cursor    : pointer;
}

.siriolibanes-logout img {
  margin-left: 8px;
}

@media(max-width: 820px) {
  .user-support-page.wl-siriolibanes footer p {
    display: none;
  }
}

@media(max-width: 550px) {
  .user-support-page.wl-siriolibanes footer a.contact {
    display: none;
  }
}

@media(max-width: 430px) {
  .user-support-page.wl-siriolibanes footer>div {
    padding: 10px 20px 10px 60px;
  }

  .user-support-page.wl-siriolibanes footer .social_link {
    margin-left : 20px;
    margin-right: 20px;
  }
}

/* .user-support-page.wl-siriolibanes > .container {
  min-height: calc(100vh - 151px);
} */
.user-support-page.wl-siriolibanes>.height_changes {
  /* min-height: calc(100vh - 151px); */
  height         : 100vh;
  display        : flex;
  flex-direction : column;
  align-items    : center;
  justify-content: center;
}

.user-support-page.wl-siriolibanes>.welcome-content {
  position: relative;
}

.user-support-page.wl-siriolibanes>.welcome-content .signout-button {
  position     : absolute;
  top          : 30px;
  right        : 30px;
  cursor       : pointer;
  box-shadow   : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  border-radius: 50%;
}

.user-support-page.wl-siriolibanes .banner-container {
  flex: 1;
}

.user-support-page.wl-siriolibanes .banner-container .welcome-call {
  padding: 50px 75px;
}

.user-support-page.wl-siriolibanes .banner-container .welcome-call>h1 {
  font-size  : 28px;
  font-family: "Roboto", sans-serif;
  color      : #747373;
  text-align : right;
}

.user-support-page.wl-siriolibanes .banner-container .welcome-call>h1>span {
  font-weight: 500;
  color      : #202529
}

@media(max-width: 550px) {
  .user-support-page.wl-siriolibanes .banner-container>div:nth-of-type(2) {
    margin-right: 0;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-siriolibanes .banner-container .welcome-call>h1 {
    font-size: 22px;
  }

  .user-support-page.wl-siriolibanes .features-item {
    font-size: 12px;
  }
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner {
  max-width      : 770px;
  position       : relative;
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items    : flex-end;
  width          : 100%;
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble {
  position        : absolute;
  top             : 85px;
  left            : 0;
  padding         : 18px;
  background-color: #fff;
  border-radius   : 30px;
  border          : solid 1px #eeee;
  box-shadow      : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  display         : flex;
  flex-direction  : row;
  justify-content : flex-start;
  align-items     : center;
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble:after {
  content             : "";
  position            : absolute;
  top                 : 16px;
  right               : -25px;
  z-index             : 1;
  border              : solid 15px transparent;
  border-left-color   : #FFF;
  /* top              : 96px; */
  /* right            : 16px; */
  /* border-left-color: unset; */
  /* border-top-color : #FFF; */
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble>span {
  display         : block;
  background-color: #eeeeee;
  padding         : 13px;
  border-radius   : 50%;
  color           : #383a3d;
  font-size       : 24px;
  line-height     : 24px;
  margin-right    : 18px;
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble>div {
  flex           : 1;
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
  font-family    : "Roboto", sans-serif;
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble>div>h3 {
  font-size: 16px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble>div>a {
  font-size      : 16px;
  color          : #006cb3;
  text-decoration: underline;
  cursor         : pointer;
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast {
  font-size: 14px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>i,
.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>b {
  margin-right: 5px;
  font-size   : 14px;
  color       : #383a3d;
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-picture {
  max-width: 460px;
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-picture>img {
  border-radius: 30px;
}

.user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-picture>div {
  padding         : 15px 20px;
  border-radius   : 5px;
  background-color: rgba(64, 64, 64, 0.8);
  font-size       : 16px;
  font-family     : "Roboto", sans-serif;
  color           : #f6f6f6;
  position        : absolute;
  right           : 30px;
  bottom          : 5px;
}

@media(max-width: 820px) {
  .user-support-page.wl-siriolibanes .banner-container .welcome-call>h1 {
    text-align: center;
  }

  .user-support-page.wl-siriolibanes .banner-container .doctor-banner {
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    margin-bottom  : 50px;
  }

  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble {
    position     : relative;
    top          : unset;
    left         : unset;
    right        : unset;
    margin-bottom: 18px;
    z-index      : 3;
    box-shadow   : 4px 8px 10px 0 rgba(18, 18, 18, 0.16);
  }

  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble:after {
    content         : "";
    position        : absolute;
    top             : unset;
    right           : 16px;
    bottom          : -25px;
    z-index         : 1;
    border          : solid 15px transparent;
    border-top-color: #FFF;
  }

  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-picture {
    position: relative;
    z-index : 1;
  }

  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-picture>div {
    z-index: 2;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble>span {
    padding     : 9px;
    font-size   : 16px;
    line-height : 16px;
    margin-right: 11px;
  }

  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble>div>h3,
  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-talk-bubble>div>a {
    font-size: 14px;
  }

  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-picture {
    max-width: 320px;
  }

  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-picture>img {
    max-width    : 320px;
    border-radius: 30px;
  }
}

/* @media(max-width: 470px) {
  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-picture {
    max-width: calc(100vw-20px);
  }
  .user-support-page.wl-siriolibanes .banner-container .doctor-banner .doctor-picture > img {
    max-width: calc(100vw-20px);
  }
} */

.user-support-page.wl-siriolibanes .features-container {
  border-top: solid 1px #f6f6f6;
}

.user-support-page.wl-siriolibanes .features-list {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  flex-wrap      : wrap;
  padding-top    : 20px;
}

.user-support-page.wl-siriolibanes .features-item {
  padding-left : 7px;
  padding-right: 7px;
  margin-bottom: 20px;
  word-wrap    : none;
  font-weight  : 500;
}

.user-support-page.wl-siriolibanes .features-item>i {
  color       : #efa25b;
  margin-right: 7px;
}

@media(max-width: 820px) {
  .user-support-page.wl-siriolibanes .features-list {
    padding-left   : 30px;
    padding-right  : 30px;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    width          : auto;
  }
}

/* .user-support-page.wl-siriolibanes footer {
  min-height: 70px;
  background-color: #195090;
  background-image: url(/assets/image/plansaude/background-bar.png);
  background-position: left top;
  background-size: cover;
}
.user-support-page.wl-siriolibanes footer > div {
  padding: 10px 220px 10px 150px;
}
.user-support-page.wl-siriolibanes footer .social_link {
  margin-left: 60px;
  margin-right: 60px;
}
.user-support-page.wl-siriolibanes footer .social_link a {
  background-color: #fff;
}
.user-support-page.wl-siriolibanes footer .social_link a > i {
  color: #246cb2;
}
.user-support-page.wl-siriolibanes footer a.contact {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-decoration: underline;
}
.user-support-page.wl-siriolibanes footer p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: right;
}
@media(max-width: 820px) {
  .user-support-page.wl-siriolibanes footer p {
    display: none;
  }
}
@media(max-width: 550px) {
  .user-support-page.wl-siriolibanes footer a.contact {
    display: none;
  }
}
@media(max-width: 430px) {
  .user-support-page.wl-siriolibanes footer > div {
    padding: 10px 20px 10px 60px;
  }
  .user-support-page.wl-siriolibanes footer .social_link {
    margin-left: 20px;
    margin-right: 20px;
  }
} */


.user-support-page.wl-siriolibanes .btn.btn-warning {
  background-color: #ea862b;
}

.user-support-page.wl-siriolibanes .btn.btn-primary {
  background-color: #006cb3;
}

.user-support-page.wl-siriolibanes .react-rater {
  width : 300px;
  height: 60px;
}

.user-support-page.wl-siriolibanes .react-rater .react-rater-star {
  color      : #ea862b;
  opacity    : 0.3;
  width      : 60px;
  height     : 60px;
  font-size  : 53px;
  line-height: 60px;
}

.user-support-page.wl-siriolibanes .react-rater .react-rater-star.is-active,
.user-support-page .react-rater .react-rater-star.will-be-active {
  opacity: 1;
}

.user-support-page.wl-siriolibanes .cpf-auth.container .row>h5 {
  margin-top   : 50px;
  margin-bottom: 50px;
}

.user-support-page.wl-siriolibanes .cpf-auth.container .row>p {
  margin-bottom: 41px;
}

.user-support-page.wl-siriolibanes .cpf-auth.container form {
  width           : 580px;
  border-radius   : 8px;
  background-color: #8f9091;
  height          : 279px;
  display         : flex;
  flex-direction  : row;
  justify-content : center;
  align-items     : center;
  flex-wrap       : wrap;
  padding         : 59px 50px;
  margin-bottom   : 70px;
}

.user-support-page.wl-siriolibanes .cpf-auth.container form .input-container {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
}

.user-support-page.wl-siriolibanes .cpf-auth.container form .input-container:first-child {
  margin-right: 40px;
}

.user-support-page.wl-siriolibanes .cpf-auth.container form .input-container label {
  font-size  : 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color      : white;
}

.user-support-page.wl-siriolibanes .cpf-auth.container form .input-container input {
  width           : 220px;
  height          : 40px;
  padding         : 12px 20px;
  border-radius   : 10px;
  box-shadow      : 2px 2px 4px 0 rgba(18, 18, 18, 0.16);
  border          : solid 1px #eeeeee;
  background-color: #ffffff;
  font-family     : "Roboto", sans-serif;
  font-size       : 14px;
  color           : rgba(116, 115, 115, 1);
}

.user-support-page.wl-siriolibanes .cpf-auth.container form .input-container input::placeholder,
.user-support-page.wl-siriolibanes .cpf-auth.container form .input-container input::-moz-placeholder,
.user-support-page.wl-siriolibanes .cpf-auth.container form .input-container input::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size  : 14px;
  color      : rgba(116, 115, 115, 0.6);
}

.user-support-page.wl-siriolibanes .cpf-auth.container form .button-container {
  position       : relative;
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.user-support-page.wl-siriolibanes .cpf-auth.container form .button-container button {
  width           : 200px;
  height          : 40px;
  border-radius   : 6px;
  border          : solid 1px #fff;
  background-color: #fff;
  font-size       : 16px;
  cursor          : pointer;
  font-weight     : 500;
  font-family     : "Roboto", sans-serif;
  color           : #da291c;
  transition      : 0.3s ease-in;
}

.user-support-page.wl-siriolibanes .cpf-auth.container form .button-container button:hover {
  background-color: #48474c;
  border-color    : #48474c;
  color           : #fff;
}

.user-support-page.wl-siriolibanes .cpf-auth.container form .button-container button:disabled {
  background-color: #48474c;
  color           : #fff;
  border          : #48474c;
}

.user-support-page.wl-siriolibanes .cpf-auth.container form .button-container p {
  position   : absolute;
  font-size  : 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color      : #fff !important;
  margin     : 0;
  bottom     : -55px;
  left       : auto;
}

@media(max-width: 600px) {
  .user-support-page.wl-siriolibanes .cpf-auth.container form {
    background-color: #8f9091;
    width           : 355px;
    height          : 320px;
    padding         : 28px;
  }

  .user-support-page.wl-siriolibanes .cpf-auth.container form .input-container {
    margin-bottom: 25px;
  }

  .user-support-page.wl-siriolibanes .cpf-auth.container form .input-container:first-child {
    margin-right: 0;
  }

  .user-support-page.wl-siriolibanes .cpf-auth.container form .button-container {
    margin-bottom: 20px;
  }

  .user-support-page.wl-siriolibanes .cpf-auth.container form .button-container p {
    bottom: -100px;
    color : #fff;
  }
}

@media(max-width: 380px) {
  .user-support-page.wl-siriolibanes .cpf-auth.container form {
    width  : 320px;
    height : 288px;
    padding: 18px;
  }
}

/* IF IS IBRASA CORPORATION */

.ibrasa-header-content {
  max-width: 1000px;
  width    : 100%;

}

.ibrasa-header {
  background-color: #ff6f00;
  border          : 1px solid #ff6f00;
  color           : white;
  display         : flex;
  justify-content : center;
}

.ibrasa-footer {
  background-color: #ff6f00;
  color           : white;
  padding         : 16px;
  border          : 1px solid black;
}

.ibrasa-footer a {
  color: white;
}

.ibrasa-logout {
  width     : 100%;
  text-align: right;
  padding   : 24px;
  font-size : 18px;
  cursor    : pointer;
}

.ibrasa-logout img {
  margin-left: 8px;
}

.smalltitle {
  border-bottom : 1px solid #A8277F;
  margin        : 0;
  padding       : 0 0 8px 0;
  font-size     : 18px;
  /* line-height: 128.19%; */
  font-weight   : bold;
  font-family   : 'Source Sans Pro', sans-serif;
}

.timer .content {
  font-weight: 700;
  font-size  : 16px;
  line-height: 35px;
  width      : auto !important;
  margin-top : 0 !important;
  font-family: 'Source Sans Pro', sans-serif;
}

.videoIcon button .videoicon {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
}

.flex-column.text-right {
  text-align: right;
}

.video-timer {
  position  : relative;
  top       : -115px;
  left      : 80px;
  text-align: center;
}

.box-button {
  width           : 100% !important;
  background-color: #3585B8 !important;
  color           : #fff;
  height          : 42.5px;
  border-radius   : 4px;
  font-weight     : 600;
  margin          : 20px 0 0 0;
  font-size       : 19.83px;
}

.outerbutton {
  width           : 100% !important;
  background-color: #A212A0 !important;
  color           : #fff;
  height          : 42.5px;
  margin-top      : 0;
  border-radius   : 4px;
  font-size       : 19.83px;
  font-weight     : 600;
  margin          : 20px 0 30px 0;
}

@media(max-width: 820px) {
  .user-support-page.wl-ibrasa footer p {
    display: none;
  }
}

@media(max-width: 550px) {
  .user-support-page.wl-ibrasa footer a.contact {
    display: none;
  }
}

@media(max-width: 430px) {
  .user-support-page.wl-ibrasa footer>div {
    padding: 10px 20px 10px 60px;
  }

  .user-support-page.wl-ibrasa footer .social_link {
    margin-left : 20px;
    margin-right: 20px;
  }
}

.user-support-page.wl-ibrasa>.container {
  min-height: calc(100vh - 151px);
}

.user-support-page.wl-ibrasa>.height_changes {
  min-height    : calc(100vh - 151px);
  display       : flex;
  flex-direction: column;
}

.user-support-page.wl-ibrasa>.welcome-content {
  position: relative;
}

.user-support-page.wl-ibrasa>.welcome-content .signout-button {
  position     : absolute;
  top          : 30px;
  right        : 30px;
  cursor       : pointer;
  box-shadow   : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  border-radius: 50%;
}

.user-support-page.wl-ibrasa .banner-container {
  flex: 1;
}

.user-support-page.wl-ibrasa .banner-container .welcome-call {
  padding: 50px 75px;
}

.user-support-page.wl-ibrasa .banner-container .welcome-call>h1 {
  font-size  : 28px;
  font-family: "Roboto", sans-serif;
  color      : #747373;
  text-align : right;
}

.user-support-page.wl-ibrasa .banner-container .welcome-call>h1>span {
  font-weight: 500;
  color      : #202529
}

@media(max-width: 550px) {
  .user-support-page.wl-ibrasa .banner-container>div:nth-of-type(2) {
    margin-right: 0;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-ibrasa .banner-container .welcome-call>h1 {
    font-size: 22px;
  }

  .user-support-page.wl-ibrasa .features-item {
    font-size: 12px;
  }
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner {
  max-width      : 770px;
  position       : relative;
  display        : flex;
  flex-direction : row;
  justify-content: flex-end;
  align-items    : flex-end;
  width          : 100%;
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble {
  position        : absolute;
  top             : 85px;
  left            : 0;
  padding         : 18px;
  background-color: #fff;
  border-radius   : 30px;
  border          : solid 1px #eeee;
  box-shadow      : 2px 2px 5px 0 rgba(18, 18, 18, 0.16);
  display         : flex;
  flex-direction  : row;
  justify-content : flex-start;
  align-items     : center;
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble:after {
  content             : "";
  position            : absolute;
  top                 : 16px;
  right               : -25px;
  z-index             : 1;
  border              : solid 15px transparent;
  border-left-color   : #FFF;
  /* top              : 96px; */
  /* right            : 16px; */
  /* border-left-color: unset; */
  /* border-top-color : #FFF; */
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble>span {
  display         : block;
  background-color: #eeeeee;
  padding         : 13px;
  border-radius   : 50%;
  color           : #383a3d;
  font-size       : 24px;
  line-height     : 24px;
  margin-right    : 18px;
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble>div {
  flex           : 1;
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
  font-family    : "Roboto", sans-serif;
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble>div>h3 {
  font-size: 16px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble>div>a {
  font-size      : 16px;
  color          : #006cb3;
  text-decoration: underline;
  cursor         : pointer;
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast {
  font-size: 14px;
  color    : #747373;
  margin   : 0;
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>i,
.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble>div>p.support-forecast>b {
  margin-right: 5px;
  font-size   : 14px;
  color       : #383a3d;
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-picture {
  max-width: 460px;
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-picture>img {
  border-radius: 30px;
}

.user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-picture>div {
  padding         : 15px 20px;
  border-radius   : 5px;
  background-color: rgba(64, 64, 64, 0.8);
  font-size       : 16px;
  font-family     : "Roboto", sans-serif;
  color           : #f6f6f6;
  position        : absolute;
  right           : 30px;
  bottom          : 5px;
}

@media(max-width: 820px) {
  .user-support-page.wl-ibrasa .banner-container .welcome-call>h1 {
    text-align: center;
  }

  .user-support-page.wl-ibrasa .banner-container .doctor-banner {
    flex-direction : column;
    justify-content: flex-start;
    align-items    : center;
    width          : 100%;
    margin-bottom  : 50px;
  }

  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble {
    position     : relative;
    top          : unset;
    left         : unset;
    right        : unset;
    margin-bottom: 18px;
    z-index      : 3;
    box-shadow   : 4px 8px 10px 0 rgba(18, 18, 18, 0.16);
  }

  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble:after {
    content         : "";
    position        : absolute;
    top             : unset;
    right           : 16px;
    bottom          : -25px;
    z-index         : 1;
    border          : solid 15px transparent;
    border-top-color: #FFF;
  }

  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-picture {
    position: relative;
    z-index : 1;
  }

  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-picture>div {
    z-index: 2;
  }
}

@media(max-width: 500px) {
  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble>span {
    padding     : 9px;
    font-size   : 16px;
    line-height : 16px;
    margin-right: 11px;
  }

  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble>div>h3,
  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-talk-bubble>div>a {
    font-size: 14px;
  }

  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-picture {
    max-width: 320px;
  }

  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-picture>img {
    max-width    : 320px;
    border-radius: 30px;
  }
}

/* @media(max-width: 470px) {
  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-picture {
    max-width: calc(100vw-20px);
  }
  .user-support-page.wl-ibrasa .banner-container .doctor-banner .doctor-picture > img {
    max-width: calc(100vw-20px);
  }
} */

.user-support-page.wl-ibrasa .features-container {
  border-top: solid 1px #f6f6f6;
}

.user-support-page.wl-ibrasa .features-list {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  flex-wrap      : wrap;
  padding-top    : 20px;
}

.user-support-page.wl-ibrasa .features-item {
  padding-left : 7px;
  padding-right: 7px;
  margin-bottom: 20px;
  word-wrap    : none;
  font-weight  : 500;
}

.user-support-page.wl-ibrasa .features-item>i {
  color       : #efa25b;
  margin-right: 7px;
}

@media(max-width: 820px) {
  .user-support-page.wl-ibrasa .features-list {
    padding-left   : 30px;
    padding-right  : 30px;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
    width          : auto;
  }
}

/* .user-support-page.wl-ibrasa footer {
  height: 70px;
  background-color: #195090;
  background-image: url(/assets/image/plansaude/background-bar.png);
  background-position: left top;
  background-size: cover;
}
.user-support-page.wl-ibrasa footer > div {
  padding: 10px 220px 10px 150px;
}
.user-support-page.wl-ibrasa footer .social_link {
  margin-left: 60px;
  margin-right: 60px;
}
.user-support-page.wl-ibrasa footer .social_link a {
  background-color: #fff;
}
.user-support-page.wl-ibrasa footer .social_link a > i {
  color: #246cb2;
}
.user-support-page.wl-ibrasa footer a.contact {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-decoration: underline;
}
.user-support-page.wl-ibrasa footer p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: right;
}
@media(max-width: 820px) {
  .user-support-page.wl-ibrasa footer p {
    display: none;
  }
}
@media(max-width: 550px) {
  .user-support-page.wl-ibrasa footer a.contact {
    display: none;
  }
}
@media(max-width: 430px) {
  .user-support-page.wl-ibrasa footer > div {
    padding: 10px 20px 10px 60px;
  }
  .user-support-page.wl-ibrasa footer .social_link {
    margin-left: 20px;
    margin-right: 20px;
  }
} */


.user-support-page.wl-ibrasa .btn.btn-warning {
  background-color: #ea862b;
}

.user-support-page.wl-ibrasa .btn.btn-primary {
  background-color: #006cb3;
}

.user-support-page.wl-ibrasa .react-rater {
  width : 300px;
  height: 60px;
}

.user-support-page.wl-ibrasa .react-rater .react-rater-star {
  color      : #ea862b;
  opacity    : 0.3;
  width      : 60px;
  height     : 60px;
  font-size  : 53px;
  line-height: 60px;
}

.user-support-page.wl-ibrasa .react-rater .react-rater-star.is-active,
.user-support-page .react-rater .react-rater-star.will-be-active {
  opacity: 1;
}

.user-support-page.wl-ibrasa .cpf-auth.container .row>h5 {
  margin-top   : 0px;
  margin-bottom: 15px;
}

.user-support-page.wl-ibrasa .cpf-auth.container .row>p {
  margin-bottom: 41px;
}

.user-support-page.wl-ibrasa .cpf-auth.container form {
  width           : 580px;
  border-radius   : 8px;
  background-color: #ff6f00;
  height          : 279px;
  display         : flex;
  flex-direction  : row;
  justify-content : center;
  align-items     : center;
  flex-wrap       : wrap;
  padding         : 59px 50px;
  margin-bottom   : 70px;
}

.user-support-page.wl-ibrasa .cpf-auth.container form .input-container {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
}

.user-support-page.wl-ibrasa .cpf-auth.container form .input-container:first-child {
  margin-right: 40px;
}

.user-support-page.wl-ibrasa .cpf-auth.container form .input-container label {
  font-size  : 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color      : white;
}

.user-support-page.wl-ibrasa .cpf-auth.container form .input-container input {
  width           : 220px;
  height          : 40px;
  padding         : 12px 20px;
  border-radius   : 10px;
  box-shadow      : 2px 2px 4px 0 rgba(18, 18, 18, 0.16);
  border          : solid 1px #eeeeee;
  background-color: #ffffff;
  font-family     : "Roboto", sans-serif;
  font-size       : 14px;
  color           : rgba(116, 115, 115, 1);
}

.user-support-page.wl-ibrasa .cpf-auth.container form .input-container input::placeholder,
.user-support-page.wl-ibrasa .cpf-auth.container form .input-container input::-moz-placeholder,
.user-support-page.wl-ibrasa .cpf-auth.container form .input-container input::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size  : 14px;
  color      : rgba(116, 115, 115, 0.6);
}

.user-support-page.wl-ibrasa .cpf-auth.container form .button-container {
  position       : relative;
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.user-support-page.wl-ibrasa .cpf-auth.container form .button-container button {
  width           : 200px;
  height          : 40px;
  border-radius   : 6px;
  border          : solid 1px #fff;
  background-color: #fff;
  font-size       : 16px;
  cursor          : pointer;
  font-weight     : 500;
  font-family     : "Roboto", sans-serif;
  color           : #ff6f00;
  transition      : 0.3s ease-in;
}

.user-support-page.wl-ibrasa .cpf-auth.container form .button-container button:hover {
  background-color: #ff9a4d;
  border-color    : #ff9a4d;
  color           : #fff;
}

.user-support-page.wl-ibrasa .cpf-auth.container form .button-container button:disabled {
  background-color: #000;
  color           : #fff;
  border          : solid 1px #fff;
}

.user-support-page.wl-ibrasa .cpf-auth.container form .button-container p {
  position   : absolute;
  font-size  : 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  color      : #fff !important;
  margin     : 0;
  bottom     : -30px;
  left       : auto;
}

@media(max-width: 600px) {
  .user-support-page.wl-ibrasa .cpf-auth.container form {
    background-color: #ff6f00;
    width           : 355px;
    height          : 320px;
    padding         : 28px;
  }

  .user-support-page.wl-ibrasa .cpf-auth.container form .input-container {
    margin-bottom: 25px;
  }

  .user-support-page.wl-ibrasa .cpf-auth.container form .input-container:first-child {
    margin-right: 0;
  }

  .user-support-page.wl-ibrasa .cpf-auth.container form .button-container {
    margin-bottom: 20px;
  }

  .user-support-page.wl-ibrasa .cpf-auth.container form .button-container p {
    bottom: -100px;
    color : #fff;
  }
}

@media(max-width: 380px) {
  .user-support-page.wl-ibrasa .cpf-auth.container form {
    width  : 320px;
    height : 288px;
    padding: 18px;
  }
}

.welcome-text {
  margin-bottom: -35px;
}


/* .welcome-content .banner-container
{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
} */
.sub-content {
  max-width: inherit;
  position : relative;
}

.sub-content .homepage-image {
  position    : absolute;
  left        : -279px;
  top         : 0;
  /* max-width: 280px; */
  opacity     : 0.5;
}

.sub-content .homepage-image img {
  width: 280px;
}

.welcome-content .title {
  margin     : 0;
  font-size  : 25px;
  font-weight: bold;
  color      : #810083;
  text-align : left;
}

.welcome-content .other-text {
  /* background-color: red;
  background-image: linear-gradient(45deg, #A8277F, #6DCFF6); */
  font-size              : 40px;
  font-weight            : 700;
  /* background-size     : 100%;
  background-repeat      : repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip   : text;
  -moz-text-fill-color   : transparent; */
  line-height            : 46px;
  margin-top             : 1rem;
  text-align             : left;
  color                  : #38B4E4;
}

.welcome-content button {
  background-color: inherit;
}

.welcome-content button p {
  margin         : 0;
  font-size      : 18px;
  font-weight    : bold;
  color          : #8F216C;
  padding        : 0 20px;
  width          : 99%;
  text-decoration: underline;
  line-height    : 22px;
}

.middle-text {
  margin: 0 21px;
}

.specialityBtn {
  padding: 4px 10px;
}

.specialityBtn.active {
  background-color: #def5fc;
  color           : #ffffff;
  font-weight     : bold;
  display         : flex;
  justify-content : space-between;
  padding         : 4px 10px !important;
  border-radius   : 5px !important;
  align-items     : center;
}

.specialityBtn.active .web-btns .btn {
  display        : flex;
  justify-content: space-between;
  align-items    : center;
}

.specialityBtn .btn-check:focus+.btn,
.specialityBtn .btn:focus {
  outline   : 0;
  box-shadow: none;
}

/* .specialityBtn .active:hover
{
  color: #ccc;
} */
.specialityBtn button {
  border       : 0.928946px solid rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  /* color     : #8F216C; */
  font-weight  : 600;
  width        : 100%;
  font-family  : 'Source Sans Pro', sans-serif;
  font-size    : 15px;
  margin-bottom: 12px !important;

}

.specialityBtn {
  width: 100%;
}

.consultaBtn button {
  background-color: #8F216C;
  color           : #ccc;
  font-weight     : bold;
  font-family     : 'Source Sans Pro', sans-serif;
}

.specialityBtn button:hover {
  color: #8F216C;
}

.listdiv-multi {
  text-align  : center;
  margin-right: 40px;
  font-family : 'Source Sans Pro', sans-serif;
}

.speciality-title {
  /* color: #283084; */
  padding-bottom  : 8px;
  /* border-bottom: 1px solid; */
  margin-bottom   : 15px;
  font-size       : 18px;
  font-weight     : 400;
  color           : #000;
  display         : inline-block;
  /* font-weight  : bold; */
}

.timeDiv span {
  color      : #8F216C;
  font-size  : 40px;
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;

}

.timeDiv sub {
  font-size  : 18px;
  color      : #000;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  margin-left: 5px;
  bottom     : 0;
}

.middle-text {
  font-size  : 14px;
  font-weight: bold;
  color      : #8F216C;
  margin     : 0 21px;
  font-family: 'Source Sans Pro', sans-serif;
}

.consultaBtn button:hover {
  color: #fff;
}

.timeDiv {
  display        : flex;
  align-items    : center;
  justify-content: flex-end;
  line-height    : normal;
  padding-top    : 2rem;
  flex-direction : column;
  flex-direction : row;
}

.videoIcon {
  position: relative;
  top     : 50px;
  left    : 15px;
}



@media(max-width:1500px) {

  .sub-content .homepage-image img {
    width: 180px;
  }

  .sub-content .homepage-image {
    left   : auto;
    top    : 38px;
    opacity: 1;
    right  : 0;
    display: block;
  }
}


@media(max-width:1024px) {

  .mobile-view {
    display: flex;
  }

  .platform-call {
    text-align: left;
  }

  .homepage-image {
    display: none;
  }

  .video-timer {
    top : 0;
    left: 0;
  }

  .consultaBtn button {
    font-size: 14px;
    color    : #fff;
  }

  .specialityBtn {
    width: auto !important;
    flex : 1;

  }


  .flex-column {
    flex-direction: row;
    width         : auto !important;
    padding       : 0 10px;
    align-items   : center;
    margin-top    : 25px;
  }

  .listdiv-multi {
    justify-content: center;
    margin-bottom  : 0 !important;
    margin-top     : 25px;
    margin-right   : 0;
  }

  .box {
    width        : auto;
    /* height    : 180px; */
    background   : rgb(196 196 196 / 10%);
    color        : #fff;
    padding      : 19px 13px 13px 13px;
    position     : relative;
    margin       : 15px 0;
    float        : left;
    border-radius: 13px;
    border       : 1px solid #75BAE7;
    text-align   : center;
  }

  .timeDiv {
    padding-top: 0.5rem;
  }

  .welcome-content {
    padding: 0 10px;
  }

  .box.arrow-left:before {
    content      : " ";
    position     : absolute;
    width        : 0;
    height       : 0;
    border-right : 20px solid #75BAE7;
    border-top   : 10px solid transparent;
    border-bottom: 10px solid transparent;
    position     : absolute;
    top          : -21px;
    left         : 15px;
    /* right     : 0;
    margin       : auto; */
    transform    : rotate(90deg);
  }

  .middle-text {
    font-size  : 14px;
    font-weight: bold;
    color      : #8F216C;
    margin     : 0 21px;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .box.arrow-left:after {
    content      : " ";
    position     : absolute;
    width        : 0;
    height       : 0;
    border-right : 20px solid #ffffff;
    border-top   : 10px solid transparent;
    border-bottom: 10px solid transparent;
    position     : absolute;
    top          : -20px;
    left         : 15px;
    /* right     : 0;
    margin       : auto; */
    transform    : rotate(90deg);
  }

  .box p {
    font-size    : 16px;
    color        : #179ACE;
    font-weight  : 700;
    line-height  : normal;
    text-align   : left;
    margin-bottom: 7px;
    font-family  : 'Source Sans Pro', sans-serif;
  }

  .videoIcon {
    position: relative;
    z-index : 1;
    top     : 0;
    left    : 0;
  }

  .timeDiv {
    display    : flex;
    align-items: center;
  }

  .timeDiv span {
    color      : #8F216C;
    font-size  : 31px;
    font-weight: bold;
  }

  .timeDiv sub {
    font-size: 12px;
    color    : #A212A0 !important;
  }

  .bottom-text {
    font-size    : 14px !important;
    color        : #A212A0 !important;
    margin-bottom: 8px;
  }

  .link {
    color          : #8F216C !important;
    font-size      : 14px !important;
    text-decoration: underline;
  }
}

@media (max-width:767px) {
  .mobile-view {
    display       : flex;
    flex-direction: column;
  }

  .special-btn-mobile .btn {
    width        : auto !important;
    margin       : 0 !important;
    height       : 40px;
    min-width    : 210px;
    border       : 0.928946px solid rgba(0, 0, 0, 0.25);
    border-radius: 40px;
  }

  .flex-row {
    display       : flex !important;
    flex-direction: row !important;
  }

  .btn-text {
    text-align    : initial;
    margin        : 0 0 0 10px;
    font-size     : 13px;
    display       : flex;
    flex-direction: column;
  }

  .btn-text span {
    color      : #3585B8;
    font-weight: 600;
  }

  .flex-column {
    margin-top: 15px;
  }

  .box {
    border    : none;
    background: transparent;
    margin    : 0;
    padding   : 0;
    float     : none;
  }

  .mobile .row.justify-content-center,
  .mobile .video-timer {
    padding: 0;
  }

  .box p {
    font-size: 40px;
    margin   : 50px 0 20px 0;
  }

  .box .bottom-text {
    font-size: 18px !important;
    color    : #000 !important;
    width    : 100%;
    margin   : -10px 0 0 0;
  }

  .timeDiv sub {
    font-size: 15.83px;
    color    : #000 !important;
  }

  /* .timeDiv{
    padding: 0;
    flex-direction: column;
  } */
  /* .box-button , .outerbutton{
    display: none;
  } */
  .mobile-btn {
    color        : #A212A0;
    font-weight  : 700;
    font-size    : 16px;
    width        : 270px;
    border       : 2px solid;
    border-radius: 4px;
    padding      : 10px;
    display      : block;
    margin       : 50px auto;
  }

  .box.arrow-left:before,
  .box.arrow-left:after {
    border-right : 0;
    border-top   : 0;
    border-bottom: 0;
  }
}

@media (max-width: 360px) {

  /* .consultaBtn button{
    padding-left: 5px;
    font-size: 14px;
   
    max-width: 75px;
    padding-right: 5px;
  } */
  .middle-text {
    margin: 0 10px;
  }

  .mobile-view .specialityBtn {
    padding: 0 3px;
  }

  .specialityBtn button {
    font-size: 12px;
  }

  .user-support-page.wl-sesidf>.height_changes {
    padding: 0;
  }
}


/* review page style */
.review-header-content {
  display      : flex;
  align-items  : center;
  margin-bottom: 5px;
  margin-top   : 16px;
}

.review-header-title-txt {
  position   : static;
  font-family: Source Sans Pro;
  font-style : normal;
  font-weight: bold;
  font-size  : 25px;
  line-height: 31px;
  display    : flex;
  align-items: center;
  color      : #000000;
}

.review-title-sub-header-txt {
  margin-top   : 4px;
  margin-bottom: 24px;
}

.review-title-sub-view {
  position   : static;
  top        : 47px;
  font-family: Source Sans Pro;
  font-style : normal;
  font-weight: bold;
  font-size  : 36px;
  line-height: 45px;
  display    : flex;
  align-items: center;
  color      : #38B4E4;
}

.edit-grid-view {
  @media screen and (max-height: 568px) {
    width: calc(100vh - 260px);
  }

  @media screen and (max-height: 480px) {
    width: calc(100vh - 168px);
  }
}

@media only screen and (max-width: 767px) {
  .footer-btn-view {
    margin-bottom: 92px;
    margin-top   : 20px;
    text-align   : -webkit-center;
  }
}

.review-text-area-view {
  display         : block;
  width           : 100%;
  padding         : 6px;
  font-size       : 1rem;
  font-weight     : 400;
  line-height     : 1.5;
  color           : #212529;
  background-color: #fff;
  border          : 1px solid #ced4da;
  appearance      : none;
  border-radius   : .25rem;
  margin-top      : 26px;
  z-index         : -1;
}

.salvar-btn {
  display               : flex;
  flex-direction        : row;
  justify-content       : center;
  align-items           : center;
  height                : 40px;
  background            : #F7941D;
  border-radius         : 4px;
  font-family           : Inter;
  font-style            : normal;
  font-weight           : 500;
  font-size             : 14px;
  line-height           : 132%;
  display               : flex;
  align-items           : center;
  text-align            : center;
  color                 : #FFFFFF;
  text-transform        : none;
  --ion-color-base      : #F7941D !important;
  --background-activated: #F7941D !important;
  --background-focused  : #F7941D !important;
  --border-color        : transparent !important;
  --border-width        : 0;
  --color               : white;
  --background          : #F7941D;
  --box-shadow          : none;
  padding               : 0;
  margin                : 0;
  width                 : 176.5px;
  height                : 44px;
}

.cancelar-btn {
  display               : flex;
  flex-direction        : row;
  justify-content       : center;
  align-items           : center;
  border                : 1.5px solid #F7941D;
  box-sizing            : border-box;
  border-radius         : 4px;
  text-transform        : none;
  font-family           : Inter;
  font-style            : normal;
  font-weight           : 500;
  font-size             : 14px;
  line-height           : 132%;
  display               : flex;
  align-items           : center;
  text-align            : center;
  letter-spacing        : 0.02em;
  --ion-color-base      : #F7941D !important;
  --background-activated: #FFFFFF !important;
  --background-focused  : #F7941D !important;
  --border-color        : transparent !important;
  --border-width        : 0;
  --color               : #F7941D;
  --background          : #ffffff;
  --box-shadow          : none;
  padding               : 0;
  margin                : 0;
  width                 : 176.5px;
  height                : 44px;
}

@media(max-width:575px) {

  .cancelar-btn,
  .salvar-btn {
    width: auto;
  }
}

.content-txt-color {
  color: #38B4E4;
}

.content {
  color: #179ACE !important;
}

@media(max-width:767px) {

  .mobile .row.justify-content-center,
  .mobile .video-timer {
    display: block !important;
  }

  /* .awaiting_staff .row.justify-content-center
  {
    display: flex !important;
  } */
  .flex-column.text-right {
    text-align: center;
  }

  .welcome-content button {
    background-color: inherit;
    width           : auto;
  }

  .bottom-border::after {
    left: 0 !important;
  }

  .sub-title-hide {
    display: none;
  }

  .user-support-page {
    padding: 0 !important;
  }

  .mobile-subtitle {
    text-align : center;
    font-size  : 30px !important;
    line-height: 24px !important;
  }

  .multi-btn-mobile {
    max-width: 100% !important;
  }

  .mobile-btn-option {
    max-width : 100%;
    margin    : 15px auto;
    text-align: center;
  }

  .mobile-web-btns {
    margin: 0 !important;
  }

  .home-img-hide {
    display: none !important;
  }

  .mobile-time-sec {
    flex-direction: column !important;
  }

  .mobile-btn {
    display: none;
  }

  .mobile-text-display {
    display   : block;
    border    : 0;
    margin    : 20px auto !important;
    max-width : 230px;
    text-align: center;
  }

  .video-iocn-mobile {
    text-align: center !important;
    margin    : 20px auto !important;
    font-size : 30px !important;
  }

  .video-sec-mobile {
    position: relative;
  }

  .video-sec-mobile::after {
    position        : absolute;
    content         : '';
    height          : 1px;
    width           : 70%;
    right           : 0;
    left            : 0;
    display         : block;
    background-color: #A212A0;
    margin          : auto;
    bottom          : -10px;
  }

  .mobile-modal-timeDiv {
    justify-content: flex-start !important;
  }
}

.img-space {
  margin: 0 5px;
}

.sub-content {
  width: 100%;
}

.container,
.container-sm {
  max-width: 100%;
}

.welcome-content button p {
  width : 70%;
  margin: auto;
}

.modal-timeDiv {
  display           : flex !important;
  flex-direction    : row !important;
  /* justify-content: flex-end !important; */
}

.timeDiv .mobile-span {
  font-size: 40px;
}

.timeDiv .mobile-sub {
  margin   : 0 0 0 5px;
  color    : #000 !important;
  font-size: 18px;
}

.bottom-border::after {
  position     : absolute;
  content      : '';
  border-bottom: 1px solid #A212A0;
  right        : 0;
  left         : auto;
  width        : 55%;
  display      : block;
  margin       : 5px auto 0 auto;
}

.smalltitle {
  border-bottom: 0px;
}

.welcome-content .content {
  padding: 10px 0;
}

.logout-modal .timeDiv {
  display        : flex;
  justify-content: flex-start;
}

.logout-modal .modal-timeDiv span,
.color-time-div span {
  font-size: 44.55px;
  color    : #A212A0;
}

.logout-modal .modal-timeDiv img,
.color-time-div img {
  height: 73px;
  width : 73px;
}

.logout-modal .box-button.disabled {
  background-color: #C6C6C6 !important;
}

.logout-modal .modal-card.active {
  background: rgba(162, 18, 160, 0.1);
}

.logout-modal .modal-timeDiv sub,
.color-time-div sub {
  margin   : 0;
  font-size: 20.5px;
  color    : #A212A0 !important
}

.mobile-consulta {
  width           : 100% !important;
  background-color: #A212A0 !important;
  color           : #fff;
  height          : 30px;
  margin-top      : 0;
  border-radius   : 4px;
}

.title-mobile {
  font-size  : 25px;
  font-weight: 700;
  margin     : 25px 0 0 0;
}

.btn-modal .modal-head {
  color      : #38B4E4;
  font-size  : 23px;
  line-height: 26.92px;
  font-weight: 700;
  text-align : left;
  margin     : 0 0 10px 0;
}

.btn-modal .bottom-text {
  color      : #A212A0 !important;
  font-size  : 20.5px !important;
  line-height: 25.7px;
  font-weight: 700;
  margin     : 0;
}

.logout-modal .bottom-text2 {
  font-size  : 19.83px;
  line-height: 26.92px;
  margin     : 5px 0 0 0;
}

.logout-modal .modal-card {
  border       : 1px solid #B5B5B5;
  border-radius: 8px;
  margin       : 15px 0 0 0;
  padding      : 5px 10px;
}

.logout-modal .modal-card-text {
  margin: 0 0 0 10px;
}

.logout-modal .client-card-head {
  margin     : 0;
  color      : #646464 !important;
  font-size  : 14.84px;
  line-height: 21.24px;
  font-weight: 700;
}

.logout-modal .client-card-text {
  margin     : 0;
  font-size  : 12.93px;
  color      : #646464;
  font-weight: 500;
}

.btn-modal .btn-bg {
  background-color: transparent;
}

/* .btn-modal .outerbutton{
  margin: 16px 0 0 0;
} */
.logout-modal .modal-wrapper {
  width : 369px !important;
  height: auto;
}

/* .logout-modal .ion-page::after{
  position: absolute;
  content: '';
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 50px solid red;
  right: 0;
  left: 0;
  top:0px;
  z-index:99;
  height: 0;
  width: 0;
} */
.modal-card-img img {
  max-height   : 50px;
  border-radius: 100%;
}

/* .increse-width{
  min-width:1500px !important;
} */
/* .welcome-text{
  position: absolute;
    right: 0;
    top: 10%;
    left: 65%;
} */
.btn-text-web {
  padding    : 0;
  font-size  : 16px;
  color      : #38B4E4;
  font-weight: 700;
  line-height: 25px;
  margin     : 0 0 0 25px;
}

.web-btns {
  display       : -webkit-box;
  display       : -moz-box;
  display       : -ms-flexbox;
  display       : -webkit-flex;
  display       : flex;
  flex-direction: column;
  align-items   : center;
  margin        : 0 0 5px 0;
}

.odd {
  color: #6d6d6d !important;
}

.btn-option {
  font-weight: 300;
  max-width  : 250px;
}

/* .web-btns .btn{
  width:auto;
  min-width: 150px;
} */
.list-div-multispeciality .mobile-view {
  max-width: 196px;
}

.list-div-multispeciality .title h1 {
  margin-top   : 0;
  margin-bottom: 23px;
  color        : #231F20;
  font-weight  : 700;
  font-size    : 28px;
  line-height  : 1;
}

.btn-option span {
  font-weight: 700;
  color      : #3585b8;
  display    : block;
}

.multi-btns {
  max-width: 250px;
}

.video-corner-icon {
  margin: 0 8px 0 0;
  width : 16px;
}

.pronto-atend-header-txt {
  font-family: Source Sans Pro;
  font-style : normal;
  font-weight: bold;
  font-size  : 28px;
  line-height: 20px;
  color      : #231F20;
}

.pronto-atend-sub-txt {
  font-family: Source Sans Pro;
  font-style : normal;
  font-weight: normal;
  font-size  : 18px;
  line-height: 28px;
  color      : #231F20;
}

.pronto-atend-bold-sub-txt {
  font-family: Source Sans Pro;
  font-style : normal;
  font-weight: bold;
  font-size  : 18px;
  line-height: 28px;
  color      : #F7941D;
  text-align : left;
  margin     : 0;
}

.pronto-clocl-view {
  display    : flex;
  align-items: center;
  margin-top : -23px;
}

.pronto-clock-icon {
  width           : 65.44px;
  height          : 65.44px;
  /* justify-items: right;
  align-items     : end;
  margin-top      : -62px; */
}

.pronto-btn-view {
  text-align: left;
}

.pronto-atend-btn {
  --ion-color-base      : #F7941D !important;
  --background-activated: #F7941D !important;
  --background-focused  : #F7941D !important;
  --border-color        : transparent !important;
  --border-width        : 0;
  --color               : white;
  --background          : #F7941D;
  --box-shadow          : none;
  --border-radius       : 37px;
  justify-items         : center;
  align-items           : center;
  text-align            : -webkit-center;
  height                : 44px;
  width                 : 250px;
  text-transform        : none;
  /* font-family           : Inter; */
  font-style            : normal;
  font-weight           : 400;
  font-size             : 16px;
  line-height           : 20px;
  display               : flex;
  align-items           : center;
  text-align            : center;
  color                 : #FFFFFF;
}

.homewrapper .half-top {
  top: 12% !important;
}

/* .videoIcon p{
  display: none;
}
.video-details{
  display: flex;
  flex-direction: column-reverse;
  align-items: baseline;
  justify-content: center;
}
.vc-iocon img{
  position: relative;
}
.bottom-border-left::after{
  position: absolute;
  content: '';
  border-bottom: 1px solid #A212A0;
  right: 0;
  left: auto;
  width: 75%;
  display: block;
  margin: 5px auto 0 auto;
}
.bottom-border-left{
  margin: -10px 0 0 0;
}
.content-width{
  max-width: 230px;
  line-height:25px !important;
}
.video-btn{
  position: relative;
  top: -20px;
  left: -30px;
}
.banner-container .title{
  display: none;
} */

.sub-content-multi-speciality {
  max-width       : 1320px;
  margin          : auto;
  width           : 100%;
  height          : 100%;
  background-color: #FFFFFF;
  background      : #FFFFFF;
  padding         : 0 20px;
}

.list-div-multispeciality {
  text-align: left;
  margin    : 0;
  width     : 40% !important;
}

.videoIcon-multispeciality {
  position: relative;
  top     : 50px;
  left    : 15px;
}

.videoIcon-multispecialityUnique {
  position: relative;
  top     : 200px;
  left    : 15px;
}

.content-multispeciality {
  line-height: 20px;
  max-width  : 280px;
}

.video-timer-multispeciality {
  position: absolute;
  left    : auto;
  right   : 23%;
  top     : 0;
}

.between-image {
  position : absolute;
  top      : -15% !important;
  left     : 35% !important;
  min-width: 280px;
  opacity  : 0.5;
}

/* .user-support-multispeciality{
  margin: -150px 0 0 0 !important;
} */

.btn-modal-multispeciality {
  position     : absolute;
  width        : max-content;
  top          : 125px;
  left         : -50px;
  background   : #fbfcf9;
  padding      : 25px;
  border-radius: 15px;
  border       : #C4C4C4 solid 1px;
}

.doctorList-multispeciality {
  max-height: 156px;
  overflow-y: auto;
}

.doctorList-multispeciality::-webkit-scrollbar {
  width        : 3px;
  border-radius: 10px;
}

.doctorList-multispeciality::-webkit-scrollbar-track {
  background   : #C4C4C4;
  border-radius: 10px;
}

.doctorList-multispeciality::-webkit-scrollbar-thumb {
  background   : #B5B5B5;
  border-radius: 10px;
}

.doctorList-multispeciality::-webkit-scrollbar-thumb:hover {
  background   : #B5B5B5;
  border-radius: 10px;
}




.btn-modal-multispeciality.Nutricionista:after,
.btn-modal-multispeciality.Nutricionista:before {
  top: 110px;
}

.btn-modal-multispeciality.Nutricionista {
  top: 200px;
}

.btn-modal-multispeciality.Psicólogo:after,
.btn-modal-multispeciality.Psicólogo:before {
  top: 47%;
}

.btn-modal-multispeciality.Psicólogo {
  top: 303px;
}


.btn-modal-multispeciality:after,
.btn-modal-multispeciality:before {
  right         : 100%;
  top           : 18%;
  border        : solid transparent;
  content       : " ";
  height        : 0;
  width         : 0;
  position      : absolute;
  pointer-events: none;
}

.btn-modal-multispeciality:after {
  border-color      : rgba(241, 241, 241, 0);
  border-right-color: #fbfcf9;
  border-width      : 20px;
  margin-top        : -20px;
}

.btn-modal-multispeciality:before {
  border-color      : rgba(221, 221, 221, 0);
  border-right-color: #C4C4C4;
  border-width      : 22px;
  margin-top        : -23px;
}




.btn-modal-multispeciality .text-head {
  color      : #38B4E4;
  font-size  : 14px;
  font-weight: 700;
  line-height: 19px;
  text-align : left;
  margin     : 0 0 5px 0;
}

.popup-timediv {
  flex-direction: column !important;
  padding       : 0;

}

.popup-timediv sub {
  margin: 0;
  color : #A212A0;
}

.text-popup {
  text-align : left;
  margin     : 0;
  font-weight: 700;
  font-size  : 14.15px;
  line-height: 19px;
}

.text1-popup {
  color: #A212A0;
}

.text2-popup {
  color: #38B4E4;
}

.pop-up-head {
  font-size  : 15px;
  line-height: 20px;
  font-weight: 700;
  color      : #646464;
  margin     : 0;
  text-align : left;
}

.pop-up-text {
  font-size  : 12px;
  line-height: 25px;
  font-weight: 400;
  color      : #646464;
  margin     : 0;
  text-align : left;
}

.modal-text {
  margin: 0 0 0 15px
}

.btn-modal-multispeciality .modal-card {
  border       : 1px solid #B5B5B5;
  border-radius: 8px;
  margin       : 15px 0 0 0;
  padding      : 5px 10px;
}

.popup-timediv span {
  color: #A212A0;
}

.popup-btn {
  font-size  : 14px;
  line-height: 19px;
}

.popup-btn img {
  margin: 0 6px;
  height: 13px;
}

.btn-modal-multispeciality .box-button.disabled {
  background-color: #C6C6C6 !important;
}

.btn-modal-multispeciality .modal-card.active {
  background: rgba(162, 18, 160, 0.1);
}

.livevideo-ampla-saude {
    padding: 1.2rem 0;
  height : calc(100vh - 220px);
}

.livevideo-ampla-saude .user_two {
  right        : 20px;
  bottom       : 20px;
  width        : 126px;
  height       : 140px;
  border       : #fff solid 3px;
  border-radius: 10px !important;
  overflow     : hidden;

  z-index: 999;
}

.livevideo-ampla-saude .suppott_btn {
  background : rgb(255 255 255 / 85%);
  margin     : 0 !important;
  z-index    : 1 !important;
  padding    : 12px 15px;
  text-align : left !important;
  display    : flex;
  align-items: center;
}
.user-support-page.wl-ampla-saude button.btn{
  background-color: #FFAD00; border-color: #FFAD00; outline: none; box-shadow: none;
}
.livevideo-ampla-saude .live_support .profile-info .staff-avatar{
  border-radius: 50px;      object-fit: cover;}
.livevideo-ampla-saude .suppott_btn button.btn-link {
  background   : #FE5000;
  padding      : 0;
  width        : 44px;
  height       : 44px;
  border-radius: 8px;
}

.livevideo-ampla-saude .live_support {
  border       : rgba(0, 0, 0, 0.25) solid 1px;
  border-radius: 12px !important;
  height       : calc(100vh - 220px);
  background   : none;
}

.livevideo-ampla-saude .live_support #remote-video-element {
  border: 0;
}
.livevideo-ampla-saude .live_support .remote-video .media-controls-container .media-controls .play-pause{
  display: none !important; 
}
.livevideo-ampla-saude .user_two .remote-video .media-controls-container .media-controls .play-pause{
  display: none !important;
}



.pronto-atend-view {
  width     : 376px !important;
  flex      : 0 0 auto;
  /* width  : 30%; */
  /* padding: 0 40px; */
}

.icon-inner {
  display    : flex;
  align-items: center;
}

.icon-inner .ionicon {
  height: 29px;
}

.cancel-icon {
  color           : white;
  width           : 44px;
  height          : 44px;
  /* font-size       : 30px; */
  border-radius   : 8px !important;
  padding         : 0;
  background-color: #FF4D34 !important;
  z-index: 99999;
}

.cancel-icon img {
  width: 13px !important;
}

.profile-info img {
  width         : 22px;
  height        : 22px;
  margin-right  : 13px;
  margin-left   : 19px;
  vertical-align: middle;
}

.profile-info span {
  color      : #868282;
  font-size  : 12px;
  font-weight: 700;

}

@media(max-width:767px) {
  .mobile-view-style {
    flex-wrap      : wrap;
    justify-content: center !important;
    align-items    : center !important;

  }

  .mobile-view-style .list-div-multispeciality {
    text-align   : center;
    margin-bottom: 50px;
  }

  .list-div-multispeciality .mobile-view {
    margin: auto;
  }

  .pronto-btn-view {
    text-align: center;
  }

  .pronto-atend-view {
    width  : auto !important;
    padding: 0 20px;
  }

  .back-arrow {
    margin-top   : 44px;
    margin-bottom: 20px;
    padding-left : 19px;
  }
}

@media(max-width:360px) {
  .review-text-area-view {
    max-width: 280px;
  }

  .rateDiv {
    padding: 0px;
  }

  .sidenav .row-item {
    height          : auto !important;
    background-color: #FFF;
  }

  .back-arrow {
    margin-top   : 14px;
    margin-bottom: 20px;
    padding-left : 19px;
  }
}

@media(max-width:341px) {
  .pronto-atend-bold-sub-txt {
    font-size: 16px;
  }
}

.search-icon {
  width   : 24px !important;
  position: absolute;
  top     : 6px;
  left    : 17px;
}

.custom-search-select {
  position        : relative;
  border          : 1px solid #999998;
  border-top: 0;
  width           : 302px;
  margin          : 0 12px;
  padding         : 4px;
  border-radius   : 0 0 10px 10px;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

}

@media only screen and (max-width: 1024px) {
  .livevideo-ampla-saude .suppott_btn button.btn-link{
    border-radius: 50px;
  }
  .live_video_mobile .modal-wrapper, .chatlistdiv .modal-wrapper{
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .livevideo-ampla-saude{
    padding: 0;
    height: 100%;
  }
  .livevideo-ampla-saude .live_support{
    border: 0;
    border-radius: 0 !important;
    height: 100%;
    background: none;
  }
  .livevideo-ampla-saude .live_support .cancel-icon{
    position: absolute;
    right: 20px;
    top: 20px;
    border-radius: 50px !important;
    background: #D70714 !important;
    cursor       : pointer;
    z-index      : 9 !important;
  }
  .livevideo-ampla-saude .live_support .remote-media-class video{
    height: 100% !important;
  }
  .livevideo-ampla-saude .user_two{
            width: 126px!important;
        height: 140px;
        border: #fff solid 4px;
        border-radius: 10px!important;
        overflow: hidden;
        z-index: 999;
        padding: 0 !important;
  }
  .livevideo-ampla-saude .user_two video{
    height: 100%;
  }
  .livevideo-ampla-saude .suppott_btn .profile-info{
    display: flex;
    align-items: center;    justify-content: start;margin-bottom: 1rem;
        margin-left: 7px;
  }
  .livevideo-ampla-saude .suppott_btn .profile-info .namediv{
    font-family: Roboto;    font-size: 13px;
  }
  .livevideo-ampla-saude .suppott_btn .profile-info .namediv span{
    color: #FE5000; font-weight: 700;font-family: Roboto;
  }
  .livevideo-ampla-saude .live_support .profile-info .staff-avatar{
    margin: 0 15px 0 0; width: 31px; height: 31px;
  } 
  .custom-search-select {
    margin: 0 auto;
  }

  .search-icon {
    top : 8px;
    left: 37px;
  }
}


.custom-search-select input {

  padding         : 0 30px;
  border          : none;
  width           : 100%;
  background-color: transparent;
  font-size       : 16px;
  color: #999998;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.custom-search-select input::placeholder {
  color: #ABA1A1;
}

.custom-search-select input:focus {
  outline   : none;
  box-shadow: none;
}

.dropDownbtn {
  margin-bottom: 0;
}

.custom-search-select__select {
  height  : 240px;
  overflow: auto;
}



.custom-search-select__select::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }

  /* Track */
.custom-search-select__select::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  /* Handle */
.custom-search-select__select::-webkit-scrollbar-thumb {
  background: #BDBDBD;
    border-radius: 10px;
  }

  /* Handle on hover */
.custom-search-select__select::-webkit-scrollbar-thumb:hover {
  background: #BDBDBD;
    border-radius: 10px;
  }





.custom-search-select__select .custom-search-select__options {
  padding: 0 10px;
}

.custom-search-select__select .custom-search-select__options li {
  list-style-type: none;
}

.custom-search-select__select .custom-search-select__options li button {
  background-color: inherit;
  font-size       : 16px;
  color           : #392A24;
  padding         : 10px;
  border-bottom   : 1px solid #BDBDBD;
  width           : 100%;
  text-align      : left;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

@media only screen and (min-width: 768px) and (max-width: 1024px)  {

  .appointment-wapper.app-wrapper .listdiv-multi.multi-btn-mobile
  {
    margin-bottom: 4rem !important;
  }

}



@media screen and (max-width:1024px) {
  .search-icon{
    left:6px;
  }
.mainboxdropdown{
  width: 302px;
  margin: 0 auto;
}
}

@media screen and (max-width:320px) {
  .mainboxdropdown{
    width: 95%;  
  }
  .custom-search-select{
    width: 95%;
  }
  .search-icon{
    left:10px;
  }

}