.main-container{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .auth-content{
        max-width: 200px;
        margin-top: 30px;    
    }
}